import React, { useState, useEffect } from "react";
import {
  createAgent,
  getOrganizations,
  getRoles,
  getSubscriptionPackages,
  getReferredBy,
  checkDuplicate,

} from "../services/api";
import '../css/AgentForm.css'; // Import the CSS

const AgentForm = () => {
    const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    city: "",
    role: "",
    companyLogo: null,
    watermarkLogo: null,
    ownerId: localStorage.getItem("userId"), // Initialize with logged-in user
    subscriptionPackage: "",
    referredBy: "",
  });

  const [organizations, setOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const cities = ["Ahmedabad", "Bengaluru", "Bhopal", "Bhubaneswar", "Chandigarh", "Coimbatore", "Hyderabad", "Indore", "Jaipur", "Kanpur", "Lucknow", "Nagpur", "Pune", "Mumbai", "Delhi", "Surat", "Others", "Gurugram", "Chennai", "Kolkata"];
  const [referredByOptions, setReferredByOptions] = useState([]); // Good practice to use a different name


  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgResponse = await getOrganizations();
        setOrganizations(orgResponse.data);

        const rolesResponse = await getRoles();
        setRoles(rolesResponse.data);

        const packagesResponse = await getSubscriptionPackages();
        setSubscriptionPackages(packagesResponse.data);

        const referredbyResponse = await getReferredBy();
        setReferredByOptions(referredbyResponse.data); // Use a separate variable


      } catch (err) {
        console.error("Failed to fetch data", err);
        setReferredByOptions([]); // Set to empty array on error

      }
    };

    fetchData();
  }, []);

    
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const handleDuplicateCheck = async (email, phone) => {
    try {
      console.log("Checking duplicate with:", { email, phone });
      const response = await checkDuplicate(email, phone);
      console.log("Duplicate check response:", response.data);
      return response.data; // Should return { exists: true/false }  
      }
       catch (error) 
       {
      console.error("Error checking duplicate:", error);
      return  false ; // Fallback   
       }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(formData.phone)) {
      alert("Please enter a valid phone number.");
      return;
    }
    const duplicateCheck = await handleDuplicateCheck(formData.email, formData.phone);
    if (duplicateCheck.exists) {
      alert("Agent with this email or phone already exists!");
      return;
    }
    const data = new FormData();

    // Append agent data as JSON
    const agentData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      organization: formData.organization,
      city: formData.city,
      role: formData.role,
      owner: { id: formData.ownerId }, // Owner ID from dropdown selection
      subscriptionPackage: formData.subscriptionPackage
        ? { id: formData.subscriptionPackage }
        : null,
      referredBy: formData.referredBy
      ? { id: formData.referredBy }
      : null,
    };
   
   
    data.append("agent", JSON.stringify(agentData));

    // Append files if they exist
    if (formData.companyLogo && formData.companyLogo[0]) {
      data.append("file", formData.companyLogo[0]);
    }

    if (formData.watermarkLogo && formData.watermarkLogo[0]) {
      data.append("file", formData.watermarkLogo[0]);
    }

    try {
      await createAgent(data);
      alert("Agent registered successfully!");
      resetForm();
    } catch (err) {
      console.error(err);
      alert("Failed to register agent. Please try again.");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      organization: "",
      city: "",
      role: "",
      companyLogo: null,
      watermarkLogo: null,
      ownerId: localStorage.getItem("userId"), // Reset to logged-in user
      subscriptionPackage: "",
      referredBy: "",
      
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prev) => ({ ...prev, [name]: files }));
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  

  return (
    <div className="agent-form-container"> {/* Container for centering */}
     <div className="agent-form"> {/* Form styles */}
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
        <input type="text" name="phone" placeholder="Phone" value={formData.phone} onChange={handleInputChange} required />

        <select name="organization" value={formData.organization} onChange={handleInputChange} required>
          <option value="">Select Organization</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.organizationName}
            </option>
          ))}
        </select>

        <select name="role" value={formData.role} onChange={handleInputChange} required>
          <option value="">Select Role</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.roleName}
            </option>
          ))}
        </select>

        <select name="city" value={formData.city} onChange={handleInputChange} required>
                        <option value="">Select City</option>
                        {cities.map((city) => (
                            <option key={city} value={city}>
                                {city}
                            </option>
                        ))}
                    </select>
        <label>
          Choose Company Logo:
          <input type="file" name="companyLogo" onChange={handleFileChange} accept="image/*" />
        </label>

        <label>
          Choose Watermark Logo:
          <input type="file" name="watermarkLogo" onChange={handleFileChange} accept="image/*" />
        </label>

        <select name="subscriptionPackage" value={formData.subscriptionPackage} onChange={handleInputChange} required>
          <option value="">Select Subscription Package</option>
          {subscriptionPackages.map((pkg) => (
            <option key={pkg.id} value={pkg.id}>
              {pkg.subscriptionPackageName} ({pkg.subscriptionPackageValue})
            </option>
          ))}
        </select>

         {/* Referred By Dropdown (For Display Only) */}
         <select name="referredBy" value={formData.referredBy} onChange={handleDropdownChange} required>
         <option value="">Select Referrer</option>
                    {referredByOptions.map((referby) => ( // Use referredByOptions here
                        <option key={referby.id} value={referby.id}>
                            {referby.name}
                        </option>
                    ))}
                </select>

        <button type="submit">Register Agent</button>
      </form>
    </div>
    </div>
  );
};

export default AgentForm;
// In this AgentForm component, we have a form to register a new agent. The form includes input fields for agent details like name, email, phone, and city, as well as dropdowns for organization, role, subscription package, and referred by. The form also includes file inputs for uploading company and watermark logos.

