import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { getLastLogin } from '../services/api'; // Replace with your actual path
import "../css/navbar.css";

const Navbar = ({ userRole, onLogout }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const [lastLogin, setLastLogin] = useState(null);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);   
  const [storedUserId, setStoredUserId] = useState(null); // Add state for storedUserId



  useEffect(() => {
    const fetchLastLogin = async () => {
      const storedUserId = localStorage.getItem("userId");
      setStoredUserId(storedUserId); // Store userId in state

      if (!userRole || !storedUserId) {
        setLastLogin(null);
        setError(null);
        return;
      }
      try {
        const response = await getLastLogin(storedUserId);
        if (response.status === 200) {
          setLastLogin(response.data.lastLogin);
          setError(null);
        } else {
          console.error("Failed to fetch last login:", response.status, response.data);
          setLastLogin(null);
          setError("Failed to fetch last login. Please try again later.");
        }
      } catch (err) {
        console.error("Error fetching last login:", err);
        setLastLogin(null);
        setError("An error occurred. Please try again later.");
      }
    };
    fetchLastLogin();
  }, [userRole]);

  useEffect(() => {
    const adjustNavbarPosition = () => {
      const translateBar = document.querySelector(".goog-te-banner-frame");
      document.querySelector(".navbar").style.top = translateBar ? "80px" : "40px";
    };
    setTimeout(adjustNavbarPosition, 1000);
    const observer = new MutationObserver(adjustNavbarPosition);
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".navbar")) {
        setMenuOpen(false);
      }
    };
    if (menuOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const renderLastLogin = () => {
    if (lastLogin) {
      return <span className="last-login">Last Login: {lastLogin}</span>;
    }
    return error ? <span className="error-message">{error}</span> : null;
  };

  return (
    <nav className="navbar">
      <div className={`menu-toggle ${menuOpen ? "open" : ""}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${menuOpen ? "active" : ""}`} onClick={() => setMenuOpen(false)}>
        {isLoginPage ? (
          <li><Link to="/">Home</Link></li>
        ) : (
          <>
            {!userRole && <li><Link to="/login">Login</Link></li>}
            {!userRole && <li><Link to="/">Home</Link></li>}
            {userRole === "PRIMARY" && (
              <>
                <li><Link to="/change-password">Change Password</Link></li>
                <li><Link to="/ownerdashboard">Dashboard</Link></li>
                <li><Link to="/AgentForm">Register</Link></li>
                <li><Link to="/BulkAgentForm">Bulk Register</Link></li>
              </>
            )}
            {userRole === "SECONDARY" && (
              <>
                <li><Link to="/change-password">Change Password</Link></li>
                <li><Link to="/ownerdashboard">Dashboard</Link></li>
              </>
            )}
            {userRole === "AGENT" && (
              <>
                <li><Link to="/change-password">Change Password</Link></li>
                <li><Link to="/agentdashboard">Dashboard</Link></li>
                <li><Link to="/FileDownload">Generate PDF</Link></li>


              </>
            )}
            {storedUserId === "125" && (
              <>
               <li><Link to="/GenerateLink">Generate Link</Link></li>

              </>
            )}
            
            {userRole && (
              <li>
                <Link to="/" onClick={onLogout}>Logout</Link>
              </li>
            )}
            {userRole && <li>{renderLastLogin()}</li>}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
// In this example, we have created a Navbar component that displays navigation links based on the user's role. The component fetches the last login time of the user and displays it in the navigation bar. The component also handles menu toggling, outside click detection, and logout functionality. The Navbar component can be used in various parts of the application to provide a consistent navigation experience for users with different
