import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, getAgentByEmail } from "../services/api"; // Import API functions
import "../css/Login.css"; // Import CSS

function Login({ setUserRole, setUserId, setPasswordChangeRequired }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [agentId, setAgentId] = useState("");

  const navigate = useNavigate();

  // 🔹 Set Page Title When Component Mounts
  useEffect(() => {
    document.title = "AdvisorMitra.com - Login";
    // Clear user-related details when the page is loaded or refreshed
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("userRole");
    localStorage.removeItem("agentId");

    // Clear the state for username and password
    setUsername("");
    setPassword("");
  }, []);

  // Retrieve agentId from localStorage (if available)
  useEffect(() => {
    const storedAgentId = localStorage.getItem("agentId");
    if (storedAgentId) {
      setAgentId(storedAgentId);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(username, password);

      if (response.status === 200 && response.data) {
        alert("Login successful!");

        const { token, role, user } = response.data;
        const { username, password_change_required, id } = user;

        // Store user data in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("userId", id);
        localStorage.setItem("username", username);
        localStorage.setItem("userRole", role);

        setUserRole(role);
        setUserId(id);
        setPasswordChangeRequired(password_change_required);

        console.log("User Role:", role);
        console.log("User ID:", id);
        console.log("Username:", username);
        console.log("Password Change Required:", password_change_required);

        // Redirect to change password page if required
        if (password_change_required) {
          navigate("/change-password");
          return;
        }

        // Role-based redirection
        if (role === "PRIMARY" || role === "SECONDARY") {
          navigate("/ownerdashboard");
        } else if (role === "AGENT") {
          try {
            const agentResponse = await getAgentByEmail(username);
            if (agentResponse.status === 200) {
              const agentId = agentResponse.data.id;
              localStorage.setItem("agentId", agentId);
              console.log("Agent ID:", agentId);
            } else {
              console.warn("Failed to fetch agent details. Status:", agentResponse.status);
            }
          } catch (agentError) {
            console.error("Error fetching agent details:", agentError);
          }
          navigate("/agentdashboard");
        } else {
          console.warn("Unhandled user role:", role);
        }
      }
    } catch (error) {
      console.error("Login error:", error);

      if (error.response) {
        console.log("Error Response Data:", error.response.data);
        console.log("Error Response Status:", error.response.status);
        console.log("Error Response Headers:", error.response.headers);
        alert(error.response.data.message || "Login failed!");
      } else if (error.request) {
        console.log("Error Request:", error.request);
        alert("No response from server. Please check your network connection.");
      } else {
        console.log("Error:", error.message);
        alert("An error occurred during login.");
      }
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Login</h2>

        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            placeholder="Enter Username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            placeholder="Enter Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
// In this example, we have created a login form component that allows users to log in to the application. The component handles user authentication by making an API call to the server with the provided username and password. Upon successful login, the user's role and ID are stored in local storage for future use. The component also redirects the user based on their role after successful login. If the user is required to change their password, they are redirected to the change password page. If an error occurs during login, an appropriate error message is displayed to the user.

