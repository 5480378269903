const FamilyInvolvementTranslations  = {
    en: {
        section_title: "Describe your current estate planning status, including wills, trusts, and power of attorney.",
     
        questions: {
            decision_maker: "55. Who is the primary decision-maker for financial matters in your household?",
            spouse_involvement: "56. How involved is your spouse/partner in the financial planning process?",
            children_involvement: "57. Are your children involved in discussions about financial planning?",
            discussion_frequency: "58. How often do you discuss financial goals and plans with your family?",
            family_influence: "59. To what extent does your family’s input influence your financial decisions?",
            expense_sharing: "60. If both you and your spouse/partner are earning, how are household expenses shared?",
            family_savings: "61. How do you and your spouse/partner manage savings for family goals (e.g., children’s education, vacations)?"
        },
        errors: {
          decision_maker: "Please select the primary decision-maker for financial matters in your household.",
          spouse_involvement: "Please select how involved your spouse/partner is in the financial planning process.",
          children_involvement: "Please select whether your children are involved in discussions about financial planning.",
          discussion_frequency: "Please select how often you discuss financial goals and plans with your family.",
          family_influence: "Please select the extent to which your family’s input influences your financial decisions.",
          expense_sharing: "Please select how household expenses are shared if both you and your spouse/partner are earning.",
          family_savings: "Please select how you and your spouse/partner manage savings for family goals (e.g., children’s education, vacations)."
        },
        

      decision_maker: {
        "Select an option": "Select an option",
        "Self": "Self",
        "Spouse/Partner": "Spouse/Partner",
        "Both Self and Spouse/Partner": "Both Self and Spouse/Partner",
        "Other family member(s)": "Other family member(s)",
        "Joint decision with the entire family": "Joint decision with the entire family"
      },
      
      spouse_involvement: {
        "Select an option": "Select an option",
        "Not involved at all": "Not involved at all",
        "Slightly involved (occasionally consulted)": "Slightly involved (occasionally consulted)",
        "Moderately involved (regularly consulted)": "Moderately involved (regularly consulted)",
        "Highly involved (joint decisions on most matters)": "Highly involved (joint decisions on most matters)",
        "Completely involved (equal say in all financial matters)": "Completely involved (equal say in all financial matters)"
      },
      
      children_involvement: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Yes, they are actively involved": "Yes, they are actively involved",
        "Yes, they are occasionally involved": "Yes, they are occasionally involved",
        "Yes, they are involved in matters related to them (e.g., Buying a Bicycle)": "Yes, they are involved in matters related to them (e.g., Buying a Bicycle)",
        "No, but I plan to involve them in the future": "No, but I plan to involve them in the future",
        "No, they are not involved at all": "No, they are not involved at all"
      },
      
      discussion_frequency: {
        "Select an option": "Select an option",
        "Never": "Never",
        "Rarely (once a year or less)": "Rarely (once a year or less)",
        "Occasionally (2-3 times a year)": "Occasionally (2-3 times a year)",
        "Regularly (quarterly)": "Regularly (quarterly)",
        "Very Often (monthly or more frequently)": "Very Often (monthly or more frequently)"
      },
      
      family_influence: {
        "Select an option": "Select an option",
        "Not at all": "Not at all",
        "Slightly (some influence, but final decision is mine)": "Slightly (some influence, but final decision is mine)",
        "Moderately (input is considered in some decisions)": "Moderately (input is considered in some decisions)",
        "Strongly (input is heavily considered in most decisions)": "Strongly (input is heavily considered in most decisions)",
        "Completely (decisions are made jointly)": "Completely (decisions are made jointly)"
      },
      
      expense_sharing: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "One person handles all expenses": "One person handles all expenses",
        "Expenses are shared equally": "Expenses are shared equally",
        "Expenses are divided based on income proportion": "Expenses are divided based on income proportion",
        "One person handles specific expenses (e.g., rent), while the other handles others (e.g., groceries)": "One person handles specific expenses (e.g., rent), while the other handles others (e.g., groceries)"
      },
      
      family_savings: {
        "Select an option": "Select an option",
        "Not applicable": "Not applicable",
        "We save together in a joint account": "We save together in a joint account",
        "Each person saves individually, and we pool resources when needed": "Each person saves individually, and we pool resources when needed",
        "One person is responsible for saving for specific goals": "One person is responsible for saving for specific goals",
        "We do not have a specific savings plan for family goals": "We do not have a specific savings plan for family goals"
      },
    },  
    hi: {
      section_title: "अपनी वर्तमान एस्टेट प्लानिंग स्थिति का वर्णन करें, जिसमें वसीयत, ट्रस्ट और पावर ऑफ अटॉर्नी शामिल हैं।",
      questions: {
          decision_maker: "55. आपके घर में वित्तीय मामलों के लिए प्राथमिक निर्णयकर्ता कौन है?",
          spouse_involvement: "56. वित्तीय योजना प्रक्रिया में आपके पति/पत्नी/साथी की कितनी भागीदारी है?",
          children_involvement: "57. क्या आपके बच्चे वित्तीय योजना के बारे में चर्चाओं में शामिल हैं?",
          discussion_frequency: "58. आप अपने परिवार के साथ वित्तीय लक्ष्यों और योजनाओं पर कितनी बार चर्चा करते हैं?",
          family_influence: "59. आपके परिवार के इनपुट का आपके वित्तीय निर्णयों पर कितना प्रभाव पड़ता है?",
          expense_sharing: "60. यदि आप और आपके पति/पत्नी/साथी दोनों कमाई कर रहे हैं, तो घरेलू खर्चों को कैसे साझा किया जाता है?",
          family_savings: "61. आप और आपके पति/पत्नी/साथी परिवार के लक्ष्यों (जैसे, बच्चों की शिक्षा, छुट्टियां) के लिए बचत कैसे प्रबंधित करते हैं?"
      },
      errors: {
        decision_maker: "कृपया अपने घर में वित्तीय मामलों के लिए मुख्य निर्णयकर्ता को चुनें।",
        spouse_involvement: "कृपया चुनें कि आपकी पत्नी/साथी वित्तीय योजना प्रक्रिया में कितनी शामिल हैं।",
        children_involvement: "कृपया चुनें कि क्या आपके बच्चे वित्तीय योजना पर चर्चा में शामिल हैं।",
        discussion_frequency: "कृपया चुनें कि आप अपने परिवार के साथ वित्तीय लक्ष्य और योजनाओं पर कितनी बार चर्चा करते हैं।",
        family_influence: "कृपया चुनें कि आपकी वित्तीय निर्णयों पर आपके परिवार का कितना प्रभाव पड़ता है।",
        expense_sharing: "कृपया चुनें कि यदि आप और आपकी पत्नी/साथी दोनों कमा रहे हैं, तो घरेलू खर्च कैसे साझा किए जाते हैं।",
        family_savings: "कृपया चुनें कि आप और आपकी पत्नी/साथी परिवार के लक्ष्यों (जैसे बच्चों की शिक्षा, छुट्टियां) के लिए बचत कैसे प्रबंधित करते हैं।"
      },
      
      decision_maker: {
          "Select an option": "एक विकल्प चुनें",
          "Self": "स्वयं",
          "Spouse/Partner": "पति/पत्नी/साथी",
          "Both Self and Spouse/Partner": "स्वयं और पति/पत्नी/साथी दोनों",
          "Other family member(s)": "अन्य परिवार सदस्य",
          "Joint decision with the entire family": "पूरे परिवार के साथ संयुक्त निर्णय"
      },
      spouse_involvement: {
          "Select an option": "एक विकल्प चुनें",
          "Not involved at all": "बिल्कुल शामिल नहीं",
          "Slightly involved (occasionally consulted)": "थोड़ा शामिल (कभी-कभी परामर्श किया जाता है)",
          "Moderately involved (regularly consulted)": "मध्यम रूप से शामिल (नियमित रूप से परामर्श किया जाता है)",
          "Highly involved (joint decisions on most matters)": "अत्यधिक शामिल (अधिकांश मामलों में संयुक्त निर्णय)",
          "Completely involved (equal say in all financial matters)": "पूरी तरह से शामिल (सभी वित्तीय मामलों में समान राय)"
      },
      children_involvement: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Yes, they are actively involved": "हां, वे सक्रिय रूप से शामिल हैं",
          "Yes, they are occasionally involved": "हां, वे कभी-कभी शामिल होते हैं",
          "Yes, they are involved in matters related to them (e.g., Buying a Bicycle)": "हां, वे उनसे संबंधित मामलों में शामिल होते हैं (जैसे, साइकिल खरीदना)",
          "No, but I plan to involve them in the future": "नहीं, लेकिन मैं उन्हें भविष्य में शामिल करने की योजना बना रहा हूं",
          "No, they are not involved at all": "नहीं, वे बिल्कुल शामिल नहीं हैं"
      },
      discussion_frequency: {
          "Select an option": "एक विकल्प चुनें",
          "Never": "कभी नहीं",
          "Rarely (once a year or less)": "शायद ही कभी (साल में एक बार या उससे कम)",
          "Occasionally (2-3 times a year)": "कभी-कभी (साल में 2-3 बार)",
          "Regularly (quarterly)": "नियमित रूप से (तिमाही)",
          "Very Often (monthly or more frequently)": "बहुत बार (मासिक या उससे अधिक बार)"
      },
      family_influence: {
          "Select an option": "एक विकल्प चुनें",
          "Not at all": "बिल्कुल नहीं",
          "Slightly (some influence, but final decision is mine)": "थोड़ा (कुछ प्रभाव, लेकिन अंतिम निर्णय मेरा है)",
          "Moderately (input is considered in some decisions)": "मध्यम रूप से (कुछ निर्णयों में इनपुट पर विचार किया जाता है)",
          "Strongly (input is heavily considered in most decisions)": "मजबूती से (अधिकांश निर्णयों में इनपुट पर गंभीरता से विचार किया जाता है)",
          "Completely (decisions are made jointly)": "पूरी तरह से (निर्णय संयुक्त रूप से किए जाते हैं)"
      },
      expense_sharing: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "One person handles all expenses": "एक व्यक्ति सभी खर्चों को संभालता है",
          "Expenses are shared equally": "खर्चों को समान रूप से साझा किया जाता है",
          "Expenses are divided based on income proportion": "खर्चों को आय के अनुपात में विभाजित किया जाता है",
          "One person handles specific expenses (e.g., rent), while the other handles others (e.g., groceries)": "एक व्यक्ति विशिष्ट खर्चों (जैसे, किराया) को संभालता है, जबकि दूसरा अन्य खर्चों (जैसे, किराने का सामान) को संभालता है"
      },
      family_savings: {
          "Select an option": "एक विकल्प चुनें",
          "Not applicable": "लागू नहीं",
          "We save together in a joint account": "हम एक संयुक्त खाते में एक साथ बचत करते हैं",
          "Each person saves individually, and we pool resources when needed": "प्रत्येक व्यक्ति अलग-अलग बचत करता है, और जरूरत पड़ने पर हम संसाधनों को जोड़ते हैं",
          "One person is responsible for saving for specific goals": "एक व्यक्ति विशिष्ट लक्ष्यों के लिए बचत के लिए जिम्मेदार है",
          "We do not have a specific savings plan for family goals": "हमारे पास परिवार के लक्ष्यों के लिए कोई विशिष्ट बचत योजना नहीं है"
      }
  }, 

      
};

export default FamilyInvolvementTranslations ;