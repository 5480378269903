import React, { useState, useEffect } from 'react';
import BasicInformationTranslations from '../sections/BasicInformationTranslations';

const BasicInformation = ({ formData, handleInputChange, language, validateSection }) => { // Remove onNext, add validateSection
    const t = BasicInformationTranslations[language] || BasicInformationTranslations.en;
    const [errors, setErrors] = useState({});

    const validateSection1 = () => {
        
        setErrors({}); // clear errors
        const newErrors = {};
    
        if (!formData.email) newErrors.email = t.errors.emailRequired;
        if (!formData.form_language) newErrors.form_language = t.errors.languageRequired;
        if (!formData.name) newErrors.name = t.errors.nameRequired;
        if (!formData.mobile) newErrors.mobile = t.errors.mobileRequired;
        if (!formData.gender) newErrors.gender = t.errors.genderRequired;
        if (!formData.dob) newErrors.dob = t.errors.dobRequired;
        if (!formData.city_of_residence) newErrors.city_of_residence = t.errors.cityRequired;
        if (!formData.number_of_dependents) newErrors.number_of_dependents = t.errors.dependentsRequired;
        if (!formData.sole_earning_member) newErrors.sole_earning_member = t.errors.soleEarnerRequired;

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Corrected email pattern
        if (formData.email && !emailPattern.test(formData.email)) newErrors.email = t.errors.emailInvalid;

        const namePattern = /^[^\d]{4,}$/; // Corrected name pattern
        if (formData.name && !namePattern.test(formData.name)) newErrors.name = t.errors.nameInvalid;

        const mobilePattern = /^(?!.*(\d)\1{3})[6-9]\d{9}$/;
        if (formData.mobile && !mobilePattern.test(formData.mobile)) newErrors.mobile = t.errors.mobileInvalid;

        if (formData.city_of_residence === 'others' && (!formData.otherCity || formData.otherCity.length < 3)) {
            newErrors.otherCity = t.errors.otherCityInvalid;
        }

        console.log("Validation Errors:", newErrors);

        setErrors(newErrors);
        const hasErrors = Object.keys(newErrors).length > 0;
    console.log("Has Errors:", hasErrors); // Add this line
    //return !hasErrors; // Return true if no errors, false if errors
    return true;

        //return Object.keys(newErrors).length === 0;
    };

  // Call validateSection directly
  validateSection(validateSection1);



   
    return (
        <div className="form-section" id="section-1">
            <label id="labelTimestamp" htmlFor="form_timestamp">
                {t.labels.form_timestamp}
            </label>
            <input
                type="text"
                id="form_timestamp"
                name="form_timestamp"
                value={formData.form_timestamp}
                readOnly
                required
                onChange={handleInputChange}
            />

            <label id="labelEmail" htmlFor="email">
                {t.labels.email}
            </label>
            <input
                type="email"
                id="email"
                name="email"
                placeholder={t.placeholders.email}
                required
                value={formData.email}
                onChange={handleInputChange}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}

            <label id="labelLanguage" htmlFor="form_language">
                {t.labels.form_language}
            </label>
            <select
                id="form_language"
                name="form_language"
                required
                value={formData.form_language}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'भाषा चुनें' : 'Select Language'}</option>
                <option value="en">English</option>
                <option value="hi">Hindi</option>
            </select>
            {errors.form_language && <p className="error-message">{errors.form_language}</p>}

            <label id="labelFullName" htmlFor="name">
                {t.labels.name}
            </label>
            <input
                type="text"
                id="name"
                name="name"
                placeholder={t.placeholders.name}
                required
                value={formData.name}
                onChange={handleInputChange}
            />
            {errors.name && <p className="error-message">{errors.name}</p>}

            <label id="labelMobile" htmlFor="mobile">
                {t.labels.mobile}
            </label>
            <input
                type="tel"
                id="mobile"
                name="mobile"
                placeholder={t.placeholders.mobile}
                required
                value={formData.mobile}
                onChange={handleInputChange}
            />
            {errors.mobile && <p className="error-message">{errors.mobile}</p>}

            <label id="labelGender" htmlFor="gender">
                {t.labels.gender}
            </label>
            <select id="gender" name="gender" required value={formData.gender} onChange={handleInputChange}>
                <option value="">{language === 'hi' ? 'लिंग चुनें' : 'Select Gender'}</option>
                <option value="male">{language === 'hi' ? 'पुरुष' : 'Male'}</option>
                <option value="female">{language === 'hi' ? 'महिला' : 'Female'}</option>
                <option value="other">{language === 'hi' ? 'अन्य' : 'Other'}</option>
            </select>
            {errors.gender && <p className="error-message">{errors.gender}</p>}

            <label id="labelDob" htmlFor="dob">
                {t.labels.dob}
            </label>
            <input
                type="date"
                id="dob"
                name="dob"
                required
                value={formData.dob}
                onChange={handleInputChange}
            />
            {errors.dob && <p className="error-message">{errors.dob}</p>}

            <label id="labelAge" htmlFor="current_age">
                {t.labels.current_age}
            </label>
            <input
                type="number"
                id="current_age"
                name="current_age"
                placeholder={t.placeholders.current_age}
                readOnly
                value={formData.current_age}
                onChange={handleInputChange}
            />

            <label id="labelCity" htmlFor="city_of_residence">
                {t.labels.city_of_residence}
            </label>
            <select
                id="city_of_residence"
                name="city_of_residence"
                required
                value={formData.city_of_residence}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'शहर चुनें' : 'Select City'}</option>
                {language === 'hi'
                    ? ['अहमदाबाद', 'बेंगलुरु', 'भोपाल', 'भुवनेश्वर', 'चंडीगढ़', 'चेन्नई', 'कोयंबटूर', 'देहरादून', 'दिल्ली', 'फरीदाबाद', 'गाजियाबाद', 'गोवा', 'गुवाहाटी', 'हैदराबाद', 'इंदौर', 'जयपुर', 'जमशेदपुर', 'कानपुर', 'कोच्चि', 'कोलकाता', 'लखनऊ', 'लुधियाना', 'मदुरै', 'मंगलुरु', 'मुंबई', 'नागपुर', 'नासिक', 'पटना', 'पुणे', 'सूरत', 'विशाखापत्तनम', 'अन्य'].map((city) => (
                        <option key={city} value={city.toLowerCase()}>{city}</option>
                    ))
                    : ['Ahmedabad', 'Bengaluru', 'Bhopal', 'Bhubaneswar', 'Chandigarh', 'Chennai', 'Coimbatore', 'Dehradun', 'Delhi', 'Faridabad', 'Ghaziabad', 'Goa', 'Guwahati', 'Hyderabad', 'Indore', 'Jaipur', 'Jamshedpur', 'Kanpur', 'Kochi', 'Kolkata', 'Lucknow', 'Ludhiana', 'Madurai', 'Mangalore', 'Mumbai', 'Nagpur', 'Nashik', 'Patna', 'Pune', 'Surat', 'Visakhapatnam', 'Others'].map((city) => (
                        <option key={city} value={city.toLowerCase()}>{city}</option>
                    ))}
            </select>
            {errors.city_of_residence && <p className="error-message">{errors.city_of_residence}</p>}

            <div id="otherCityContainer" style={{ display: formData.city_of_residence === 'others' ? 'block' : 'none' }}>
                <label id="labelOtherCity" htmlFor="otherCity">
                    {t.labels.otherCity}
                </label>
                <input
                    type="text"
                    id="otherCity"
                    name="otherCity"
                    placeholder={t.placeholders.otherCity}
                    value={formData.otherCity}
                    onChange={handleInputChange}
                />
                {errors.otherCity && <p className="error-message">{errors.otherCity}</p>}
            </div>

            <label id="labelDependents" htmlFor="number_of_dependents">
                {t.labels.number_of_dependents}
            </label>
            <select
                id="number_of_dependents"
                name="number_of_dependents"
                required
                value={formData.number_of_dependents}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'निर्भर व्यक्तियों की संख्या चुनें' : 'Select Number of Dependents'}</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
            {errors.number_of_dependents && <p className="error-message">{errors.number_of_dependents}</p>}

            <label id="labelSoleEarner" htmlFor="sole_earning_member">
                {t.labels.sole_earning_member}
            </label>
            <select
                id="sole_earning_member"
                name="sole_earning_member"
                required
                value={formData.sole_earning_member}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'एक विकल्प चुनें' : 'Select an option'}</option>
                <option value="yes">{language === 'hi' ? 'हाँ' : 'Yes'}</option>
                <option value="no">{language === 'hi' ? 'नहीं' : 'No'}</option>
            </select>
            {errors.sole_earning_member && <p className="error-message">{errors.sole_earning_member}</p>}

        </div>
  );
};

export default BasicInformation;