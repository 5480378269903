import React, { useState, useEffect } from 'react';
import { downloaddbPdf, getCustomerDetailAgentId } from '../services/api';
import '../css/FileDownload.css';

function FileDownload() {
    const [agentId, setAgentId] = useState('');
    const [language, setLanguage] = useState('english');
    const [fundType, setFundType] = useState('mutual');
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState('Financial Horoscope');
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(''); // Initialize as string

    useEffect(() => {
        const storedAgentId = localStorage.getItem("agentId");
        if (storedAgentId) {
            setAgentId(storedAgentId);
        }
        fetchCustomers()
            .then(data => setCustomers(data))
            .catch(error => console.error("Error fetching customers:", error));
    }, []);

    const fetchCustomers = async () => {
        try {
            const agentId = localStorage.getItem("agentId");
            if (!agentId) {
                console.error("Agent ID not found in localStorage.");
                return [];
            }
            console.log("Agent ID in localStorage.", agentId);
            const response = await getCustomerDetailAgentId(agentId);
            console.log("API Response:", response.data);
            if (response.data && Array.isArray(response.data)) {
                return response.data;
            } else {
                console.error("Invalid customer data received from API.");
                return [];
            }
        } catch (error) {
            console.error("Error fetching customers:", error);
            return [];
        }
    };

    const handleReportTypeChange = (e) => {
        setReportType(e.target.value);
    };

    const handleCustomerChange = (e) => {
        setSelectedCustomerId(e.target.value);
        console.log("Selected Customer ID changed to:", e.target.value);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const handleFundTypeChange = (e) => {
        setFundType(e.target.value);
    };

    const handleFileDownload = async () => {
        if (!agentId) {
            alert('Agent ID not found.');
            return;
        }
        console.log("Selected Customer ID:", selectedCustomerId);
        console.log("Customers Array:", customers);

        const formData = new FormData();
        formData.append('agentId', agentId);
        formData.append('language', language);
        formData.append('fundType', fundType);
        formData.append('reportType', reportType);
        formData.append('customer', selectedCustomerId);

        const selectedCustomer = customers.find(cust => cust.id.toString() === selectedCustomerId); // Force string comparison

        if (!selectedCustomer) {
            alert('Selected customer not found. Please select a valid customer.');
            return;
        }

        formData.append('customerName', selectedCustomer.name);

        try {
            setLoading(true);
            setDownloadProgress(0);

            const response = await downloaddbPdf(formData, (progressEvent) => {
                if (progressEvent.total) {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setDownloadProgress(progress);
                }
            });

            if (response.status !== 200) {
                throw new Error("Failed to generate PDF.");
            }

            console.log("Response Headers:", response.headers);

            const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'];
            let filename = "report.pdf";
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?([^"]+)"?/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }

            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

            setDownloadProgress(100);
        } catch (error) {
            console.error("Error downloading PDF:", error);
            alert("Failed to generate the PDF. Please try again.");
        } finally {
            setTimeout(() => {
                setLoading(false);
                setDownloadProgress(0);
            }, 1000);
        }
    };

    return (
        <div className="file-download-container">
            {/* ... (other parts of your component) ... */}
            <div className="language-selection">
                <label>Language:</label>
                <select value={language} onChange={handleLanguageChange}>
                    <option value="english">English</option>
                    <option value="hindi">Hindi</option>
                </select>
            </div>

            <div className="language-selection">
                <label>Fund Type:</label>
                <select value={fundType} onChange={handleFundTypeChange}>
                    <option value="mutual">Mutual</option>
                    <option value="non-mutual">Non-Mutual</option>
                </select>
            </div>

            <div className="language-selection">
                <label>Report Type:</label>
                <select value={reportType} onChange={handleReportTypeChange}>
                    <option value="Financial Horoscope">Financial Horoscope</option>
                </select>
            </div>

            <div className="language-selection">
                <label>Select Customer:</label>
                <select value={selectedCustomerId} onChange={handleCustomerChange}>
                    <option value="">Select a customer</option>
                    {customers.map(cust => (
                        <option key={cust.id} value={cust.id.toString()}>{cust.name}</option> // Force string value
                    ))}
                </select>
            </div>
            {console.log("Current Selected Customer ID:", selectedCustomerId)}
            <button onClick={handleFileDownload} disabled={loading}>
                {loading ? 'Generating...' : 'Download PDF'}
            </button>

            {loading && (
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${downloadProgress}%` }}>
                        {downloadProgress}%
                    </div>
                </div>
            )}
            {console.log("Current Selected Customer ID:", selectedCustomerId)}
        </div>
    );
}

export default FileDownload;