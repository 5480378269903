import React, { useState, useEffect } from "react";
import "../css/HomePage.css";
import homePageImage from "../assets/images/home-page.png";

// Import images directly from `src/assets/images/`
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";
import image5 from "../assets/images/image5.png";
import image6 from "../assets/images/image6.png";
import image7 from "../assets/images/LandingPage.png";

// Store images in an array for the carousel
const imageList = [image1, image2, image3, image4, image5, image6, image7];

const HomePage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    document.title = "AdvisorMitra.com - Home"; // Set the page title

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, 2000); // Auto change every 2 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="home-page">
      {/* Hero Section */}
      <div className="hero-section">
        <p></p>
        <p></p>
        <h1>Welcome to AdvisorMitra.com !!!</h1>
        
        {/* Added Image Below Heading */}
        
        <div className="hero-image-container">
        <img
          src={homePageImage}
          alt="Advisor Mitra"
          className="hero-image"
        />
          
        </div>

        <h2>Helping Financial Professionals Succeed</h2>
        <p>
          In the world of financial services, success isn’t just about numbers—it’s about strategy, trust, and efficiency. 
          We help professionals like you convert more leads into loyal clients, increase the value of every transaction, 
          and optimize your workflow for maximum productivity.
        </p>
        <h2>Close More Deals, Win Bigger, Work Smarter!</h2>
        <p><b>Increase Client Conversion Rates –</b> Turn prospects into long-term clients with proven strategies and insights.</p>
        <p><b>Boost Ticket Size –</b> Discover methods to maximize the value of every deal and grow your revenue.</p>
        <p><b>Enhance Productivity –</b> Work smarter, not harder, with tools and resources designed for efficiency.</p>

        <p><b>Your clients trust you to make the best financial decisions—let us help you do the same for your business. Explore our solutions and start achieving more!! </b></p>

        <h2><b>Start transforming your financial practice today 🚀🚀🚀</b></h2>
      </div>

      {/* Image Carousel (Auto change every 2 seconds) */}
      <div className="visual-section">
        <img
          src={imageList[currentIndex]}
          alt="Financial Services Visualization"
          className="carousel-image"
        />
      </div>
    </div>
  );
};

export default HomePage;
