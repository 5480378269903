// FormTranslations.js
const FormTranslations = {
    en: {
        title: 'Customer Form',
        description: 'Please fill out the form.',
        sectionIndicator: 'Section {current} of {total} - {title}',
        buttons: {
            prev: "< Previous Section",
            next: "Next Section >",
            submit: "Submit",
            modal: {
                successTitle: 'Submission Successful!',
                successMessage: 'Thank you for completing the form. Your information has been securely submitted.',
                closeButton: 'Close'
              }
          }
          
    },
    hi: {
        title: 'ग्राहक फॉर्म',
        description: 'कृपया फॉर्म भरें।',
        sectionIndicator: 'अनुभाग {current} का {total} - {title}',
        buttons: {
            prev: "< पिछला अनुभाग",
            next: "अगला अनुभाग >",
            submit: "सबमिट करें",
            modal: {
                successTitle: 'फॉर्म सफलतापूर्वक जमा हुआ!',
                successMessage: 'फॉर्म पूरा करने के लिए धन्यवाद। आपकी जानकारी सुरक्षित रूप से जमा कर दी गई है।',
                closeButton: 'बंद करें'
              }
          }
    },
    
};

export default FormTranslations;