import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getSubscriptionsByAgentId, getReportByAgentId } from '../services/api';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import "../css/AgentDashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const AgentDashboard = ({ setMenuOpen }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const navigate = useNavigate();

    const [chartData, setChartData] = useState({
        labels: ['Remaining Usage', 'Current Usage'],
        datasets: [
            {
                data: [],
                backgroundColor: ['#66b3ff', '#ff9999'],
            },
        ],
    });

    useEffect(() => {
        const fetchSubscriptions = async () => {
            setLoading(true);
            try {
                const agentId = localStorage.getItem("agentId");

                if (!agentId) {
                    alert("Session expired or agentId missing. Redirecting to login.");
                    navigate('/login');
                    return;
                }

                const response = await getSubscriptionsByAgentId(`${agentId}?nocache=${Date.now()}`);
                
                if (response.data && typeof response.data === 'object' && Object.keys(response.data).length > 0) {
                    setSubscriptions([response.data]);
                    setChartData({
                        labels: ['Remaining Usage', 'Current Usage'],
                        datasets: [{
                            data: [response.data.remainingUsage, response.data.currentUsage],
                            backgroundColor: ['#66b3ff', '#ff9999'],
                        }],
                    });
                } else {
                    setSubscriptions([]);
                }
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
                setSubscriptions([]);
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptions();
    }, [navigate]);

    const handleButtonClick = async () => {
        const agentId = localStorage.getItem("agentId");
        try {
            const response = await getReportByAgentId(agentId);
            setReports(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    const sortReports = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedReports = [...reports].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setReports(sortedReports);
        setSortConfig({ key, direction });
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(reports);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
        XLSX.writeFile(workbook, "Agent_Reports.xlsx");
    };

    return (
        <div className="agents-dashboard-container" onClick={() => setMenuOpen(false)}>
            <h2>Subscription Details</h2>

            {loading ? (
                <p>Loading subscriptions...</p>
            ) : subscriptions.length > 0 ? (
                <>
                    <div className="chart-container">
                        <Pie
                            data={chartData}
                            options={{
                                plugins: {
                                    datalabels: {
                                        formatter: (value, context) => {
                                            return context.chart.data.labels[context.dataIndex] + ': ' + value;
                                        },
                                        color: '#fff',
                                        font: { weight: 'bold' },
                                    },
                                },
                            }}
                        />
                    </div>
                    <table className="agents-table">
                        <thead>
                            <tr>
                                <th>Total Limit</th>
                                <th>Threshold Limit</th>
                                <th>Current Usage</th>
                                <th>Remaining Usage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions.map((subscription, index) => (
                                <tr key={subscription.id || index}>
                                    <td>{subscription.totalLimit}</td>
                                    <td>{subscription.thresholdLimit}</td>
                                    <td>{subscription.currentUsage}</td>
                                    <td>{subscription.remainingUsage}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No subscriptions found.</p>
            )}

            <button onClick={handleButtonClick}>List of Generated Reports</button>

            {reports.length > 0 && (
                <>
                    <div className="download-button-container">
                        <button className="download-button" onClick={downloadExcel}>Download Excel</button>
                    </div>

                    <table className="agents-table">
                        <thead>
                            <tr>
                                <th onClick={() => sortReports('reportName')}>Report Name ⬍</th>
                                <th onClick={() => sortReports('createdAt')}>Created Date ⬍</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((report) => (
                                <tr key={report.id}>
                                    <td>{report.reportName}</td>
                                    <td>{report.createdAt ? new Date(report.createdAt).toLocaleString() : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default AgentDashboard;
// In this AgentDashboard component, we fetch the subscriptions and reports for the agent using the getSubscriptionsByAgentId and getReportByAgentId functions. The subscriptions are displayed in a table, and the reports are displayed in a table with sorting functionality. The agent can download the reports in Excel format by clicking the Download Excel button.
