import React, { useState, useEffect } from "react";
import "../css/Pricing.css";
import { pricing } from "../services/api";

const Pricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  const [submissionMessage, setSubmissionMessage] = useState(null);

  useEffect(() => {
    document.title = "AdvisorMitra.com - Pricing"; // Set the page title

    // Reattach event listeners when Google Translate modifies the page
    const observer = new MutationObserver(() => {
      const link = document.querySelector(".pricing a");
      if (link) {
        link.addEventListener("click", handleMoreDetailsClick);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const handleMoreDetailsClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({ name: "", mobile: "", email: "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const phonePattern = /^[6-9]\d{9}$/;

    if (formData.name.length < 4) {
      alert("Name must be at least 4 characters long (including spaces).");
      return false;
    }
    if (!phonePattern.test(formData.mobile)) {
      alert("Enter a valid 10-digit mobile number starting with 6-9.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("mobile", formData.mobile);
    formDataToSend.append("email", formData.email);

    console.log("Name:", formData.name);
    console.log("Email:", formData.email);
    console.log("Mobile:", formData.mobile);

    try {
      const response = await pricing(formDataToSend);
      console.log("API Response:", response.data);
      setSubmissionMessage("Message sent successfully!");
      setFormData({ name: "", mobile: "", email: "" });
      setTimeout(handleCloseModal, 3000);
      alert(
        "Your query has been submitted successfully. Our support team will reach out to you within 24 business hours. Thank You."
      );
    } catch (error) {
      alert(
        "Looks like your query is not submitted successfully, please write to " +
          "support@advisormitra.com with subject IMPORTANT, your name and mobile number, " +
          "brief description of the issue for quick support."
      );
      console.error("API Error:", error);
      setSubmissionMessage("Error sending message. Please try again.");
    }
  };

  return (
    <div className="pricing-container">
      <div className="pricing">
        <h1>Pricing That Works for You</h1>
        <h2>Why Choose AdvisorMitra?</h2>
        <p>✔️ Transparent Pricing – No hidden fees</p>
        <p>✔️ Flexible Plans – Scale as your business grows</p>
        <p>✔️ Dedicated Support – Priority assistance</p>
        <p>✔️ New Features – Exciting new features coming soon !!</p>
        <p>
          We offer flexible pricing to fit every stage of your financial
          advisory business. Whether you're an individual advisor, a small
          team, or a large firm, we have a plan tailored for you.{" "}
          <b>Get Started Today by Clicking</b>{" "}
          <a href="#" onClick={handleMoreDetailsClick}>
            here
          </a>{" "}
        </p>
        <p>
          Our sales team will get back to you with detailed information about
          our pricing options and how our platform can benefit your business.
        </p>

        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="modal-close" onClick={handleCloseModal}>
                &times;
              </span>
              <h3>Pricing Inquiry</h3>
              <form>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="mobile">Mobile Number:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />

                <button type="button" onClick={handleSubmit}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
