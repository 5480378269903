import React, { useEffect } from "react";
import "../css/AboutUs.css";

// Import images from assets
import SantoshImage from "../assets/images/Santosh_Gare_Image.jpg";
import KapilImage from "../assets/images/Kapil_Chopra_Image.png";

const AboutUs = () => {
  useEffect(() => {
    document.title = "AdvisorMitra.com - About Us";
  }, []);

  return (
    <div className="about-us">
      <div className="about-us-content">
        <h1>About Us</h1>

        <h2>Our Vision</h2>
        <p>
          In the next five years, we strive to become the premier platform for Insurance Advisors, Mutual Fund Distributors, LIC Professionals, and Certified Financial Planners. Our mission is to empower these professionals by providing innovative tools and solutions to streamline client acquisition, management, and service delivery. By joining our platform, financial experts will gain a competitive edge and set new benchmarks in excellence and efficiency.
        </p>

        <h2>Our Mission</h2>
        <p>
          Our mission is to provide Insurance Advisors, Mutual Fund Distributors, LIC Professionals, and Certified Financial Planners with cutting-edge tools and resources that simplify their daily operations. By addressing challenges like long sales cycles, inconsistent lead quality, and complex data management, we empower professionals to enhance client engagement and efficiency. Our platform is designed to optimize workflows, generate actionable insights, and enable seamless delivery of personalized financial solutions. Through innovation and relentless support, we strive to help advisors achieve measurable growth and stay ahead in a dynamic marketplace.
        </p>

        <h2>Our Story</h2>
        <div className="author-section">
          <img src={SantoshImage} alt="Santosh Gare" className="author-image" loading="lazy" />
          <p>
            <strong className="h1-text">Santosh Gare</strong>, a distinguished Life Insurance Trainer and NLP Coach, is celebrated for his practical and transformative training methods. With over 24 years of experience in sales and training—of which 5 years as a top sales trainer at HDFC Life Insurance—he has profoundly influenced the financial planning landscape in India.
          </p>
        </div>

        <p>
          An engineering graduate and certified by the Insurance Institute of India (Licentiate & Associate), Santosh has trained over 150,000 LIC Professionals, 1,000 development officers, and conducted sessions in 550 LIC branches nationwide. His innovative programs, including <strong className="h1-text">"Value Marketing" & "ChatGPT & AI Tools Mastery"</strong> and user-friendly sales tools are redefining client engagement and efficiency in the insurance sector. As an NLP Practitioner, Santosh advocates for a seamless transition from physical to digital working methods, empowering financial planners to thrive in a competitive, tech-driven market.
        </p>

        <div className="author-section">
          <img src={KapilImage} alt="Kapil Chopra" className="author-image" loading="lazy" />
          <p>
            <strong className="h1-text">Kapil Chopra</strong>, an experienced IT professional with over 20 years in Risk Management, Automation, and Platform Integrations, <strong className="h1-text">combined his passion for personal financial planning</strong> with his technical expertise to solve a pressing problem in the industry. While pursuing financial advisory as a part-time interest, he encountered challenges in client acquisition and data management. Collecting, organizing, and presenting client data through scattered sources like emails and handwritten notes was inefficient and lacked professionalism.
          </p>
        </div>

        <p>
          Determined to find a better way, Kapil developed a system to automate data collection, streamline insights, and deliver polished Financial Wellness Reports in professional PDF formats. His innovation reduced the <strong className="h1-text">average process time from 10 hours to just 30 minutes</strong>, enabling faster client acquisition, improved follow-ups, and stronger client relationships. Inspired by the transformative impact on his own practice, Kapil knew this system had the potential to revolutionize the financial advisory landscape. <strong className="h1-text">This concept was designed by Kapil, using his technical skills, vision, and deep understanding of financial planning challenges to build a simple and effective solution.</strong>  
        </p>
        
        <p>
          Kapil had attended Santosh Gare’s Value Marketing workshop; he saw an opportunity to amplify the impact of his system. Recognizing the need to take this powerful solution to the masses, he brought Santosh on board, leveraging his vast experience in sales and training to drive adoption among financial professionals across India. This collaboration aims to equip professionals with the tools and knowledge needed to save time, increase efficiency, and scale their businesses in today’s competitive landscape.
        </p>
        
      </div>
    </div>
  );
};

export default AboutUs;
