import React, { useState, useEffect } from 'react';
import SavingsInvestmentsTranslations from './SavingsInvestmentsTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px', backgroundColor: disabled ? '#e0e0e0' : 'white' }}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options)
        .filter(([key]) => key !== 'Select an option' && (name !== 'premium_range' || key !== 'Not Applicable' || disabled))
        .map(([key, optionLabel]) => (
          <option key={key} value={key}>{optionLabel}</option>
        ))}
    </select>
  </div>
);

const SavingsInvestments = ({ formData, handleInputChange, language, validateSection }) => {
  const t = SavingsInvestmentsTranslations[language] || SavingsInvestmentsTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSection4 = () => {
    const newErrors = ['savings_investments', 'equity_investment', 'fixed_income_allocation', 'num_policies', 'premium_range', 'ppf_contribution', 'nps_contribution']
      .reduce((acc, field) => {
        if (!formData[field] || formData[field] === 'Select an option') {
          acc[field] = t.errors[`${field}Required`] || `Please select an option for ${field.replace(/_/g, ' ')}.`;
        }
        return acc;
      }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (formData.num_policies === "I DO NOT invest in Endowment or ULIP Plans") {
      handleInputChange({ target: { name: "premium_range", value: "Not Applicable" } });
    } else if (formData.premium_range === "Not Applicable") {
      handleInputChange({ target: { name: "premium_range", value: "" } });
    }
  }, [formData.num_policies]);

  useEffect(() => {
    console.log("useEffect formData:", formData);
    validateSection(validateSection4);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-4">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.description}</p>
      <img src="/images/section4_savings.jpg" alt="Investments" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      {['savings_investments', 'equity_investment', 'fixed_income_allocation', 'num_policies', 'premium_range', 'ppf_contribution', 'nps_contribution'].map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[field]} 
            options={t[`${field}_options`]}
            value={formData[field]}
            handleChange={handleInputChange}
            disabled={field === 'premium_range' && formData.num_policies === "I DO NOT invest in Endowment or ULIP Plans"}
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default SavingsInvestments;
