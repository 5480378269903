import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api.js'; // Import your API function
import "../css/CustomerForm.css"; // Import your CSS file
import FormHeader from '../sections/FormHeader.js'; // Import the header component
import BasicInformation from '../sections/BasicInformation.js';
import HouseholdIncome from '../sections/HouseholdIncome.js';
import HouseholdExpenses from '../sections/HouseholdExpenses.js';
import SavingsInvestments from '../sections/SavingsInvestments.js';
import FinancialGoals from '../sections/FinancialGoals.js';
import RetirementPlanning from '../sections/RetirementPlanning.js';
import FixedAndLiquidAssets from '../sections/FixedAndLiquidAssets.js';
import Liabilities from '../sections/Liabilities.js';
import RiskProfile from '../sections/RiskProfile.js';
import InsuranceCoverage from '../sections/InsuranceCoverage.js';
import CreditBehavior from '../sections/CreditBehavior.js';
import ElderlyDependents from '../sections/ElderlyDependents.js';
import FamilyInvolvement from '../sections/FamilyInvolvement.js';
import FeedbackSuggestions from '../sections/FeedbackSuggestions.js';
import FormTranslations from '../sections/FormTranslations';


function CustomerForm() {
    const { token } = useParams();
    const [formData, setFormData] = useState(
        
            {
                form_timestamp: new Date().toLocaleString(), name: '', email: '',  mobile: '', dob: '',
                current_age: '', gender: '', city_of_residence: '', number_of_dependents: '', sole_earning_member: '', household_income: '',
                income_sources: '', income_level: '', monthly_expenses: '', mandatory_expenses: '', discretionary_expenses: '',emergency_fund: '',  emergency_fund_coverage: '', 
                rent_response: '', education_expenses: '', car_loan_emi: '', savings_investments: '', equity_investment: '', fixed_income_allocation: '',
                num_policies: '', premium_range: '', ppf_contribution: '', nps_contribution: '', financial_goals: '', home_accumulation: '',
                education_savings: '', wedding_savings: '', car_savings_response: '', world_tour_response: '', business_savings_response: '', wealth_goal_response: '',
                num_goals: '', retirement_age: '', estimated_corpus: '', estimated_retirement_need: '', desired_retirement_age: '', estimated_monthly_expenses: '', fixed_assets_value: '',
                liquid_assets_value: '', home_loan_outstanding: '', car_loan_outstanding: '', long_term_liabilities: '', long_term_management: '', new_liabilities: '',
                additional_payments: '', home_loan_tenure: '', car_loan_tenure: '', other_long_term_liabilities: '', other_loan_tenure: '', personal_loans_response: '',
                comfort_level_response: '', short_term_liabilities_response: '', health_insurance: '', term_insurance: '', disability_insurance: '', portfolio_reaction: '',
                risk_tolerance: '', holding_period: '', new_investment_products: '', investment_priority: '', high_risk_allocation: '', liquidity_importance: '', decision_maker: '',
                spouse_involvement: '', children_involvement: '', discussion_frequency: '', family_influence: '', expense_sharing: '', family_savings: '',
                credit_card_usage: '', credit_balance_management: '', credit_spending_approach: '', credit_card_attitude: '', credit_statement_review: '', spending_behavior: '',
                purchase_regret_frequency: '', budget_following: '', spending_prioritization: '', non_essential_decisions: '', budget_exceed_frequency: '', windfall_usage: '',
                online_shopping_frequency: '', income_increase_spending: '', elderly_dependents: '', ailments: '',  health_insurance: '', independent_income: '', healthcare_plan: '',
                q81: '', q82: '', q83: '', q86: ''
               
               
            
    }
);

    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1); // Initialize step to 1
    const [language, setLanguage] = useState('en');
    const [currentSection, setCurrentSection] = useState(1); // Initialize currentSection
    const totalSections = 14; // Define totalSections (or get it dynamically)
    const [validateBasicInfo, setValidateBasicInfo] = useState(() => () => true); // Default validation function
    const validateBasicInfoRef = useRef(() => true); // Default validation function

    const handleValidateBasicInfo = useCallback(
        (validateFn) => {
            validateBasicInfoRef.current = validateFn;
        },
        []
    );

    useEffect(() => {
        console.log("CustomerForm Token: ", token);
        const now = new Date();
        const formattedTimestamp = now.toISOString().slice(0, 19).replace('T', ' ');
        setFormData(prevFormData => ({ ...prevFormData, form_timestamp: formattedTimestamp }));
        updateSectionIndicator();
    }, [token, step, language]); // Add language to dependency array


   
    const handleInputChange = (e) => {
        const { name, value } = e.target;
       // console.log("handleInputChange called:", { name, value });
       //console.log("Input changed:", name, "to", value);

        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };
            console.log("Input changed:", name, "to", value);
            console.log("Updated formData:", updatedFormData);
                //start
                if (name === 'form_language') {
                    console.log("Setting language to:", value); // Log language update
    
                    setLanguage(value); // Update language state directly
                    updateSectionIndicator(); // Call updateSectionIndicator here
    
                }
                if (name === 'dob' && value) {
                    // Calculate age and keep the date in YYYY-MM-DD
                    const dobDate = new Date(value);
                    const today = new Date();
                    let age = today.getFullYear() - dobDate.getFullYear();
                    const monthDiff = today.getMonth() - dobDate.getMonth();
                    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
                        age--;
                    }
                    updatedFormData.current_age = age;
                } else if (name === 'dob' && !value) {
                    updatedFormData.current_age = '';
                }
        
         
            console.log("Input changed:", name, "to", value);
            console.log("Updated formData:", { ...formData, [name]: value });
            console.log("formData updated:", updatedFormData);

            return updatedFormData;
        });
    };


    const handleFinancialGoalsChange = (e) => {
        const { value, checked } = e.target;
        let currentGoals = formData.financial_goals ? formData.financial_goals.split(',') : [];
    
        if (checked) {
            currentGoals = [...currentGoals, value];
        } else {
            currentGoals = currentGoals.filter((goal) => goal !== value);
        }
    
        setFormData({ ...formData, financial_goals: currentGoals.join(',') });
    };
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevFormData) => {
            let updatedIncomeSources = prevFormData.income_sources ? prevFormData.income_sources.split(',') : [];
    
            if (checked) {
                updatedIncomeSources.push(value);
            } else {
                updatedIncomeSources = updatedIncomeSources.filter((item) => item !== value);
            }
    
            return { ...prevFormData, income_sources: updatedIncomeSources.join(',') };
        });
    };
    const renderSection = () => {
        console.log("Rendering section, language:", language); // Check if rendering

        switch (step) {
            case 1:return (<BasicInformation formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} /> );
            case 2: return <HouseholdIncome formData={formData} handleInputChange={handleInputChange} handleCheckboxChange={handleCheckboxChange} language={language} validateSection={handleValidateBasicInfo} />;
            case 3: return <HouseholdExpenses key={JSON.stringify(formData)} formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />;
            case 4: return <SavingsInvestments formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo}/>;
            case 5:     console.log("CustomerForm: validateSection prop:", handleValidateBasicInfo);
            return <FinancialGoals formData={formData} handleInputChange={handleInputChange} handleFinancialGoalsChange={handleFinancialGoalsChange} language={language} validateSection={handleValidateBasicInfo} />
            case 6: return <RetirementPlanning formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />
            case 7: return <FixedAndLiquidAssets formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo}/>;
            case 8: return <Liabilities formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />; // Section 8
            case 9: return <InsuranceCoverage formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo}/>; // Section 9
            case 10: return <RiskProfile formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo}/>; // Section 10
            case 11: return <FamilyInvolvement formData={formData} handleInputChange={handleInputChange} language={language}validateSection={handleValidateBasicInfo} />; // Section 11
            case 12: return <CreditBehavior formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />; // Section 12
            case 13: return <ElderlyDependents formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />; // Section 13
            case 14: return <FeedbackSuggestions formData={formData} handleInputChange={handleInputChange} language={language} validateSection={handleValidateBasicInfo} />; // Section 14
            // Add cases for other sections
            default: return null;
        }
    };
 
    const updateSectionIndicator = () => {
        const sectionIndicator = document.getElementById('sectionIndicator');
        if (sectionIndicator) {
            const englishText = `Section ${step} of 14 - ${getSectionTitle()}`;
            const hindiText = `अनुभाग ${step} में से 14 - ${getSectionTitleHindi()}`;
    
            sectionIndicator.textContent = language === 'en' ? englishText : hindiText;
        }
    };

    const getSectionTitle = () => {
        switch (step) {
            case 1: return "Basic Information";
            case 2: return "Household Income";
            case 3: return "Household Expenses";
            case 4: return "Savings and Investments";
            case 5: return "Financial Goals";
            case 6: return "Retirement Planning"
            case 7: return "Fixed & Liquid Assets"
            case 8: return "Liabilities (Loans, Credit, Debt)";
            case 9: return "Insurance Coverage";
            case 10: return "Risk Profile";
            case 11: return "Family's Involvement in Financial Planning";
            case 12: return "Credit Behavior & Spending Habits";
            case 13: return "Elderly Dependents";
            case 14: return "Feedback & Suggestions";    
            default: return "";
        }
    };
    const getSectionTitleHindi = () => {
        switch (step) {
            case 1: return "मूल जानकारी";
            case 2: return "घरेलू आय";
            case 3: return "घरेलू खर्च";
            case 4: return "बचत और निवेश";
            case 5: return "आर्थिक लक्ष्य ";
            case 6: return "सेवानिवृत्ति योजना";
            case 7: return "स्थायी एवं तरल संपत्तियाँ";
            case 8: return "देनदारियाँ (लोन, क्रेडिट, ऋण)";
            case 9: return "बीमा कवरेज";
            case 10: return  "जोखिम प्रोफ़ाइल";
            case 11: return  "परिवार की वित्तीय योजना में भागीदारी";
            case 12: return  "क्रेडिट व्यवहार और खर्च की आदतें";
            case 13: return "बुजुर्ग निर्भरता";
            case 14: return "प्रतिक्रिया और सुझाव";
              

            // Add cases for other sections
            default: return "";
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            await submitFormData({ ...formData, token });
            setSubmissionStatus('success');
            setFormData(
                {
                    form_timestamp: '', name: '', email: '', form_language: '', mobile: '', dob: '',
                    current_age: '', gender: '', city_of_residence: '', number_of_dependents: '', sole_earning_member: '', household_income: '',
                    income_sources: '', income_level: '', monthly_expenses: '', mandatory_expenses: '', discretionary_expenses: '', emergency_fund: '', emergency_fund_coverage: '',
                    rent_response: '', education_expenses: '', car_loan_emi: '', savings_investments: '', equity_investment: '', fixed_income_allocation: '',
                    num_policies: '', premium_range: '', ppf_contribution: '', nps_contribution: '', financial_goals: '', home_accumulation: '',
                    education_savings: '', wedding_savings: '', car_savings_response: '', world_tour_response: '', business_savings_response: '', wealth_goal_response: '',
                    num_goals: '', retirement_age: '', estimated_corpus: '', estimated_retirement_need: '', desired_retirement_age: '', estimated_monthly_expenses: '', fixed_assets_value: '',
                    liquid_assets_value: '', home_loan_outstanding: '', car_loan_outstanding: '', long_term_liabilities: '', long_term_management: '', new_liabilities: '',
                    additional_payments: '', home_loan_tenure: '', car_loan_tenure: '', other_long_term_liabilities: '', other_loan_tenure: '', personal_loans_response: '',
                    comfort_level_response: '', short_term_liabilities_response: '', health_insurance: '', term_insurance: '', disability_insurance: '', portfolio_reaction: '',
                    risk_tolerance: '',holding_period: '', new_investment_products: '', investment_priority: '', high_risk_allocation: '', liquidity_importance: '', decision_maker: '',
                    spouse_involvement: '', children_involvement: '', discussion_frequency: '', family_influence: '', expense_sharing: '', family_savings: '',
                    credit_card_usage: '', credit_balance_management: '', credit_spending_approach: '', credit_card_attitude: '', credit_statement_review: '', spending_behavior: '',
                    purchase_regret_frequency: '', budget_following: '', spending_prioritization: '', non_essential_decisions: '', budget_exceed_frequency: '', windfall_usage: '',
                    online_shopping_frequency: '', income_increase_spending: '', elderly_dependents: '', ailments: '',  health_insurance: '', independent_income: '', healthcare_plan: '',
                    retirement_monthly_income: '', 
                    q81: '', q82: '', q83: '', q86: '',
                    agent_id: ''
                }
                
            );
        } catch (err) {
            setSubmissionStatus('error');
            if (err.response && err.response.data) {
                setError(err.response.data);
            } else {
                setError('Form submission failed. Please try again.');
            }
            console.error('Form submission error:', err);
        }
    };
    const nextSection = () => {
        if (currentSection < totalSections) {
            setCurrentSection(currentSection + 1); // Use setCurrentSection here
        } else {
            handleSubmit();
        }
    };

    const handleNext = () => {
        console.log("validateBasicInfo:", validateBasicInfoRef.current);
        const validationFunction = validateBasicInfoRef.current;
        const isValid = validationFunction();
        console.log("Validation Function:", validationFunction);
        console.log("Is Valid:", isValid);
        if (isValid) {
            setStep(step + 1);
            updateSectionIndicator();
        } else {
            console.log("Validation failed for section", step);
        }
    };

    const showSection = (sectionNumber) => {
        setCurrentSection(sectionNumber); // Use setCurrentSection here
    };


 // Get button translations based on selected language
 const buttonTranslations = FormTranslations[language].buttons;
    return (

    <div className="customer-form-container">
        <div className="customer-form">
        <FormHeader
            step={step}
            getSectionTitle={getSectionTitle}
            getSectionTitleHindi={getSectionTitleHindi}
            language={language}
            formTranslations={FormTranslations}
        />
            <form onSubmit={handleSubmit}>
            {renderSection()}
            <div className="navigation-buttons">
    {step > 1 && <button type="button" onClick={() => {setStep(step - 1); updateSectionIndicator();}}>    {buttonTranslations.prev}</button>}
   {/* {step < 14 && <button type="button" onClick={() => {setStep(step + 1); updateSectionIndicator();}}>{buttonTranslations.next}</button>} */}
   {step < 14 && <button type="button" onClick={handleNext}>{buttonTranslations.next}</button>}

    {step === 14 && <button type="button" onClick={handleSubmit}> {buttonTranslations.submit}</button>}
</div>
        {submissionStatus === 'success' && (
            <p className="success-message">Form submitted successfully!</p>
        )}
        {submissionStatus === 'error' && (
            <p className="error-message">{error}</p>
        )}

            </form>
        </div>
    </div>
    );
}

export default CustomerForm;