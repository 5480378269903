const FinancialGoalsTranslations = {
    en: {
      description: "Quantify your financial objectives, such as saving for a home, children’s education, retirement, starting a business, or other wealth accumulation goals.",
      questions: {
        financial_goals: "18. What are your top financial goals? (Select all that apply)",
        home_accumulation: "19. How much money do you plan to accumulate for buying / constructing a home?",
        education_savings: "20. How much money do you plan to save for your children's higher education?",
        wedding_savings: "21. How much money do you plan to save for your children's wedding?",
        car_savings_response: "22. How much money do you plan to save for buying / replacing a Car?",
        world_tour_response: "23. How much money do you plan to save for a World Tour?",
        business_savings_response: "24. How much money do you plan to save for Starting a Business?",
        wealth_goal_response: "25. How much wealth do you aim to accumulate in the next 20 years?",
      },
      errors: {
        financial_goals: "Please select your top financial goals.",
        home_accumulation: "Please enter how much money you plan to accumulate for buying or constructing a home.",
        education_savings: "Please enter how much money you plan to save for your children's higher education.",
        wedding_savings: "Please enter how much money you plan to save for your children's wedding.",
        car_savings_response: "Please enter how much money you plan to save for buying or replacing a car.",
        world_tour_response: "Please enter how much money you plan to save for a world tour.",
        business_savings_response: "Please enter how much money you plan to save for starting a business.",
        wealth_goal_response: "Please enter how much wealth you aim to accumulate in the next 20 years."
      },
      
      financial_goals: {
      "Buying a home": "Buying a home",
      "Funding children's education": "Funding children's education",
      "Funding children's wedding": "Funding children's wedding",
      "Buying a Car": "Buying a Car",
      "World Tour": "World Tour",
      "Starting a business": "Starting a business",
      "Wealth accumulation": "Wealth accumulation",
      },
      home_accumulation_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹25,00,000": "Less than ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "More than ₹2,00,00,000": "More than ₹2,00,00,000",
      },
      education_savings_options: {
       "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "More than ₹1,00,00,000",
      },
      wedding_savings_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "More than ₹1,00,00,000",
      },
      car_savings_response_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹300,000": "Less than ₹300,000",
        "300000to500000": "₹300,000 - ₹500,000",
        "500000to1000000": "₹500,000 - ₹10,00,000",
        "1000000to1500000": "₹10,00,000 - ₹15,00,000",
        "More than ₹15,00,000": "More than ₹15,00,000",
      },
      world_tour_response_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "More than ₹1,00,00,000",
      },
      
      business_savings_response_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
        "₹20,00,000 - ₹30,00,000": "₹20,00,000 - ₹30,00,000",
        "₹30,00,000 - ₹50,00,000": "₹30,00,000 - ₹50,00,000",
        "More than ₹50,00,000": "More than ₹50,00,000",
      },
      wealth_goal_response_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "5000000to10000000": "₹50,00,000 - ₹1,00,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹1,00,00,000 - ₹5,00,00,000",
        "₹5,00,00,000 - ₹10,00,00,000": "₹5,00,00,000 - ₹10,00,00,000",
        "More than ₹10,00,00,000": "More than ₹10,00,00,000",
      },
    },
    hi: {
      description:"अपने वित्तीय उद्देश्यों को संख्यात्मक रूप से निर्धारित करें, जैसे कि घर खरीदने, बच्चों की शिक्षा, सेवानिवृत्ति, व्यवसाय शुरू करने या अन्य संपत्ति संचय लक्ष्यों के लिए बचत करना।",
      questions: {
        financial_goals: "18. आपके मुख्य वित्तीय लक्ष्य क्या हैं? (सभी लागू विकल्प चुनें)",
        home_accumulation: "19. घर खरीदने / बनाने के लिए आप कितनी धनराशि इकट्ठा करना चाहते हैं?",
        education_savings: "20. अपने बच्चों की उच्च शिक्षा के लिए आप कितनी धनराशि बचाना चाहते हैं?",
        wedding_savings: "21. अपने बच्चों की शादी के लिए आप कितनी धनराशि बचाना चाहते हैं?",
        car_savings_response: "22. कार खरीदने / बदलने के लिए आप कितनी धनराशि बचाना चाहते हैं?",
        world_tour_response: "23. वर्ल्ड टूर के लिए आप कितनी धनराशि बचाना चाहते हैं?",
        business_savings_response: "24. व्यवसाय शुरू करने के लिए आप कितनी धनराशि बचाना चाहते हैं?",
        wealth_goal_response: "25. अगले 20 वर्षों में आप कितनी संपत्ति अर्जित करना चाहते हैं?",
      },
      errors: {
        financial_goals: "कृपया अपने मुख्य वित्तीय लक्ष्यों का चयन करें।",
        home_accumulation: "कृपया यह दर्ज करें कि आप घर खरीदने या बनाने के लिए कितनी राशि इकट्ठा करना चाहते हैं।",
        education_savings: "कृपया यह दर्ज करें कि आप अपने बच्चों की उच्च शिक्षा के लिए कितनी राशि बचाना चाहते हैं।",
        wedding_savings: "कृपया यह दर्ज करें कि आप अपने बच्चों की शादी के लिए कितनी राशि बचाना चाहते हैं।",
        car_savings_response: "कृपया यह दर्ज करें कि आप कार खरीदने या बदलने के लिए कितनी राशि बचाना चाहते हैं।",
        world_tour_response: "कृपया यह दर्ज करें कि आप विश्व भ्रमण के लिए कितनी राशि बचाना चाहते हैं।",
        business_savings_response: "कृपया यह दर्ज करें कि आप व्यवसाय शुरू करने के लिए कितनी राशि बचाना चाहते हैं।",
        wealth_goal_response: "कृपया यह दर्ज करें कि आप अगले 20 वर्षों में कितनी संपत्ति इकट्ठा करना चाहते हैं।"
      },
      
      financial_goals: {
        "Buying a home": "घर खरीदना",
        "Funding children's education": "बच्चों की शिक्षा के लिए धन जुटाना",
        "Funding children's wedding": "बच्चों की शादी के लिए धन जुटाना",
        "Buying a Car": "कार खरीदना",
        "World Tour": "वर्ल्ड टूर",
        "Starting a business": "व्यवसाय शुरू करना",
        "Wealth accumulation": "संपत्ति अर्जित करना",
      },
      home_accumulation_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "Less than ₹25,00,000": "₹25,00,000 से कम",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "More than ₹2,00,00,000": "₹2,00,00,000 से अधिक",
      },
      education_savings_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "Less than ₹10,00,000": "₹10,00,000 से कम",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक",
      },
      wedding_savings_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "Less than ₹10,00,000": "₹10,00,000 से कम",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक",
      },
      car_savings_response_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "₹3,00,000 से कम": "₹3,00,000 से कम",
        "300000to500000": "₹3,00,000 - ₹5,00,000",
        "500000to1000000": "₹5,00,000 - ₹10,00,000",
        "1000000to1500000": "₹10,00,000 - ₹15,00,000",
        "₹15,00,000 से अधिक": "₹15,00,000 से अधिक",
      },

      world_tour_response_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "Less than ₹10,00,000": "₹10,00,000 से कम",
        "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
        "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक",
      },
      
      business_savings_response_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "Less than ₹10,00,000": "₹10,00,000 से कम",
        "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
        "₹20,00,000 - ₹30,00,000": "₹20,00,000 - ₹30,00,000",
        "₹30,00,000 - ₹50,00,000": "₹30,00,000 - ₹50,00,000",
        "More than ₹50,00,000": "₹50,00,000 से अधिक",
      },
      wealth_goal_response_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹1,00,00,000 - ₹5,00,00,000",
        "₹5,00,00,000 - ₹10,00,00,000": "₹5,00,00,000 - ₹10,00,00,000",
        "More than ₹10,00,00,000": "₹10,00,00,000 से अधिक",
      },

      hi: {
        questions: {
            financial_goals: "18. आपके मुख्य वित्तीय लक्ष्य क्या हैं? (सभी लागू विकल्प चुनें)",
            home_accumulation: "19. घर खरीदने / बनाने के लिए आप कितनी धनराशि इकट्ठा करना चाहते हैं?",
            education_savings: "20. अपने बच्चों की उच्च शिक्षा के लिए आप कितनी धनराशि बचाना चाहते हैं?",
            wedding_savings: "21. अपने बच्चों की शादी के लिए आप कितनी धनराशि बचाना चाहते हैं?",
            car_savings_response: "22. कार खरीदने / बदलने के लिए आप कितनी धनराशि बचाना चाहते हैं?",
            world_tour_response: "23. वर्ल्ड टूर के लिए आप कितनी धनराशि बचाना चाहते हैं?",
            business_savings_response: "24. व्यवसाय शुरू करने के लिए आप कितनी धनराशि बचाना चाहते हैं?",
            wealth_goal_response: "25. अगले 20 वर्षों में आप कितनी संपत्ति अर्जित करना चाहते हैं?",
        },
        financial_goals: {
            "Buying a home": "घर खरीदना",
            "Funding children's education": "बच्चों की शिक्षा के लिए धन जुटाना",
            "Funding children's wedding": "बच्चों की शादी के लिए धन जुटाना",
            "Buying a Car": "कार खरीदना",
            "World Tour": "वर्ल्ड टूर",
            "Starting a business": "व्यवसाय शुरू करना",
            "Wealth accumulation": "संपत्ति अर्जित करना",
        },
        home_accumulation_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹25,00,000": "₹25,00,000 से कम",
            "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
            "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
            "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
            "More than ₹2,00,00,000": "₹2,00,00,000 से अधिक",
        },
        education_savings_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹10,00,000": "₹10,00,000 से कम",
            "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
            "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
            "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
            "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक",
        },
        wedding_savings_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹10,00,000": "₹10,00,000 से कम",
            "₹10,00,000 - ₹25,00,000": "₹10,00,000 - ₹25,00,000",
            "₹25,00,000 - ₹50,00,000": "₹25,00,000 - ₹50,00,000",
            "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
            "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक",
        },
        car_savings_response_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹300,000": "₹3,00,000 से कम",
            "300000to500000": "₹3,00,000 - ₹5,00,000",
            "500000to1000000": "₹5,00,000 - ₹10,00,000",
            "1000000to1500000": "₹10,00,000 - ₹15,00,000",
            "More than ₹15,00,000": "₹15,00,000 से अधिक",
        },
        world_tour_response_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹300,000": "₹3,00,000 से कम",
            "₹300,000 - ₹500,000": "₹3,00,000 - ₹5,00,000",
            "₹500,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
            "₹10,00,000 - ₹15,00,000": "₹10,00,000 - ₹15,00,000",
            "More than ₹15,00,000": "₹15,00,000 से अधिक",
        },
        business_savings_response_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "Less than ₹10,00,000": "₹10,00,000 से कम",
            "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
            "₹20,00,000 - ₹30,00,000": "₹20,00,000 - ₹30,00,000",
            "₹30,00,000 - ₹50,00,000": "₹30,00,000 - ₹50,00,000",
            "More than ₹50,00,000": "₹50,00,000 से अधिक",
        },
        wealth_goal_response_options: {
            "Select an option": "एक विकल्प चुनें",
            "Not Applicable": "लागू नहीं",
            "5000000to10000000": "₹50,00,000 - ₹1,00,00,000",
            "₹50,00,000 - ₹1,00,00,000": "₹1,00,00,000 - ₹5,00,00,000",
            "₹5,00,00,000 - ₹10,00,00,000": "₹5,00,00,000 - ₹10,00,00,000",
            "More than ₹10,00,00,000": "₹10,00,00,000 से अधिक",
        },
    },
    } ,
    
      };
      export default FinancialGoalsTranslations ; 