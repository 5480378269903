const FeedbackSuggestionsTranslations = {
  en: {
    sectionDescription: "Please take a moment to share your feedback on the questionnaire. Your responses will be valuable in refining the process for future users. Thank you for your time and insights!",
    q81_label: "81. How would you rate the overall clarity and ease of understanding of the questionnaire?",
    q82_label: "82. How comprehensive did you find the questions in covering your financial situation?",
    q83_label: "83. Were there any sections or questions that you found particularly helpful?",
    q84_label: "84. Were there any sections or questions that you found confusing or unnecessary? Please Specify",
    q85_label: "85. Do you feel that any important topics were missing from the questionnaire? Please Specify" ,
    q86_label: "86. How likely are you to recommend this financial planning process to others based on your experience with the questionnaire?",

    q81_options: {
      "Select an option": "Select an option",
      excellent: "Excellent",
      veryGood: "Very Good",
      good: "Good",
      fair: "Fair",
      poor: "Poor",
    },
    q82_options: {
      extremelyComprehensive: "Extremely comprehensive",
      mostlyComprehensive: "Mostly comprehensive",
      somewhatComprehensive: "Somewhat comprehensive",
      notComprehensive: "Not comprehensive at all",
    },
    q86_options: {
      veryLikely: "Very Likely",
      likely: "Likely",
      neutral: "Neutral",
      unlikely: "Unlikely",
      veryUnlikely: "Very Unlikely",
    },

    incomeSection: "Yes, the Income section",
    householdExpensesSection: "Yes, the Household Expenses section",
    savingsInvestmentsSection: "Yes, the Savings and Investments section",
    liabilitiesSection: "Yes, the Long Term & Short Term Liabilities section",
    riskAppetiteSection: "Yes, the Risk Appetite section",
    familyInvolvementSection: "Yes, the Family Involvement section",
    other: "Other: Specify",

    errors: {
      q81: "Please select an option for question 81.",
      q82: "Please select an option for question 82.",
      q83: "Please select at least one option for question 83.",
      q86: "Please select an option for question 86."
    }
  },

  hi: {
    sectionDescription: "कृपया प्रश्नावली पर अपनी प्रतिक्रिया साझा करने के लिए कुछ समय निकालें। आपकी प्रतिक्रियाएँ भविष्य के उपयोगकर्ताओं के लिए प्रक्रिया को बेहतर बनाने में सहायक होंगी। आपके समय और विचारों के लिए धन्यवाद!",
    
    q81_label: "81. आप प्रश्नावली की समग्र स्पष्टता और समझने में आसानी को कैसे रेट करेंगे?",
    q82_label: "82. प्रश्नों ने आपकी वित्तीय स्थिति को कवर करने में आपको कितनी व्यापकता महसूस हुई?",
    q83_label: "83. क्या कोई अनुभाग या प्रश्न था जो आपको विशेष रूप से सहायक लगा?",
    q84_label: "84. क्या कोई अनुभाग या प्रश्न था जो आपको भ्रमित करने वाला या अनावश्यक लगा? कृपया निर्दिष्ट करें",
    q85_label: "85. क्या आपको लगता है कि प्रश्नावली में कोई महत्वपूर्ण विषय छूट गया है? कृपया निर्दिष्ट करें",
    q86_label: "86. प्रश्नावली के साथ अपने अनुभव के आधार पर, आप इस वित्तीय योजना प्रक्रिया की सिफारिश दूसरों को कितनी संभावना से करेंगे?",
  
    q81_options: {
      "Select an option": "कृपया एक विकल्प चुनें",
      excellent: "उत्कृष्ट",
      veryGood: "बहुत अच्छा",
      good: "अच्छा",
      fair: "सामान्य",
      poor: "खराब",
    },
    q82_options: {
      extremelyComprehensive: "बेहद व्यापक",
      mostlyComprehensive: "अधिकतर व्यापक",
      somewhatComprehensive: "कुछ हद तक व्यापक",
      notComprehensive: "बिल्कुल भी व्यापक नहीं",
    },
    q86_options: {
      veryLikely: "बहुत अधिक संभावना",
      likely: "संभावना है",
      neutral: "निष्पक्ष",
      unlikely: "कम संभावना",
      veryUnlikely: "बिल्कुल भी संभावना नहीं",
    },
  
    incomeSection: "हाँ, आय अनुभाग",
    householdExpensesSection: "हाँ, घरेलू खर्च अनुभाग",
    savingsInvestmentsSection: "हाँ, बचत और निवेश अनुभाग",
    liabilitiesSection: "हाँ, दीर्घकालिक और अल्पकालिक देनदारियां अनुभाग",
    riskAppetiteSection: "हाँ, जोखिम सहिष्णुता अनुभाग",
    familyInvolvementSection: "हाँ, पारिवारिक सहभागिता अनुभाग",
    other: "अन्य: निर्दिष्ट करें",
  
    errors: {
      q81: "कृपया प्रश्न 81 के लिए एक विकल्प चुनें।",
      q82: "कृपया प्रश्न 82 के लिए एक विकल्प चुनें।",
      q83: "कृपया प्रश्न 83 के लिए कम से कम एक विकल्प चुनें।",
      q86: "कृपया प्रश्न 86 के लिए एक विकल्प चुनें।"
    }
  },
  
};

export default FeedbackSuggestionsTranslations;
