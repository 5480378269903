import React, { useState, useEffect } from 'react';
import '../css/CustomerForm.css';
import RetirementPlanningTranslations from '../sections/RetirementPlanningTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled, error }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options).filter(([key]) => key !== 'Select an option').map(([key, optionLabel]) => (
        <option key={key} value={key}>{optionLabel}</option>
      ))}
    </select>
    {error && <p className="error-message">{error}</p>}
  </div>
);

const RetirementPlanning = ({ formData, handleInputChange, language, validateSection }) => {
  const t = RetirementPlanningTranslations[language] || RetirementPlanningTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSection6 = () => {
    const newErrors = ['retirement_age', 'estimated_corpus', 'estimated_retirement_need', 'estimated_monthly_expenses']
      .reduce((acc, field) => {
        if (!formData[field]) acc[field] = t.errors?.[`${field}Required`] || `Please select an option for ${field.replace(/_/g, ' ')}.`;
        return acc;
      }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
   //return true;
  };

  useEffect(() => {
    console.log("useEffect formData:", formData);
    validateSection(validateSection6);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-6">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_description}</p>
      <img src="/images/section6_retirement.jpg" alt="Retirement Goals" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      
      <SelectField
        name="retirement_age"
        label={t.questions.retirement_age}
        options={t.retirement_age_options}
        value={formData.retirement_age}
        handleChange={handleInputChange}
        error={errors.retirement_age}
      />

      <SelectField
        name="estimated_corpus"
        label={t.questions.estimated_corpus}
        options={t.estimated_corpus_options}
        value={formData.estimated_corpus}
        handleChange={handleInputChange}
        error={errors.estimated_corpus}
      />

      <SelectField
        name="estimated_retirement_need"
        label={t.questions.estimated_retirement_need}
        options={t.estimated_retirement_need_options}
        value={formData.estimated_retirement_need}
        handleChange={handleInputChange}
        error={errors.estimated_retirement_need}
      />

      <SelectField
        name="estimated_monthly_expenses"
        label={t.questions.estimated_monthly_expenses}
        options={t.estimated_monthly_expenses_options}
        value={formData.estimated_monthly_expenses}
        handleChange={handleInputChange}
        error={errors.estimated_monthly_expenses}
      />
    </div>
  );
};

export default RetirementPlanning;
