import React, { useState, useEffect } from 'react';
import { submitFormData } from '../services/api';
import '../css/CustomerForm.css';
import HouseholdExpensesTranslations from '../sections/HouseholdExpensesTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled }) => {
  const displayOptions = options || {};

  return (
    <div className="question">
      <label style={{ fontWeight: 'bold' }}>{label}</label>
      <select
        name={name}
        required
        style={{ width: '100%', marginLeft: '20px' }}
        value={value || ""}
        onChange={handleChange}
        disabled={disabled}
      >
        <option value="" disabled>
          {displayOptions["Select an option"]}
        </option>
        {Object.entries(displayOptions)
          .filter(([key, optionLabel]) => key !== "Select an option")
          .map(([key, optionLabel]) => (
            <option key={key} value={key}>
              {optionLabel}
            </option>
          ))}
      </select>
    </div>
  );
};

const HouseholdExpenses = ({ formData, handleInputChange, language, validateSection }) => {
  const t = HouseholdExpensesTranslations[language] || HouseholdExpensesTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSection3 = () => {
    const newErrors = [
      'monthly_expenses',
      'mandatory_expenses',
      'discretionary_expenses',
      'rent_response',
      'education_expenses',
      'car_loan_emi',
      'emergency_fund',
      'emergency_fund_coverage',
    ].reduce((acc, field) => {
      if (!formData[field])
        acc[field] = t.errors[`${field}Required`] || `Please select an option for ${field.replace(/_/g, ' ')}.`;
      return acc;
    }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (formData.emergency_fund === 'No') {
      if (formData.emergency_fund_coverage !== t.emergency_fund_coverage_options["I DON'T have Emergency Fund"]) {
        handleInputChange({
          target: {
            name: 'emergency_fund_coverage',
            value: t.emergency_fund_coverage_options["I DON'T have Emergency Fund"],
          },
        });
      }
    } else if (formData.emergency_fund === 'Yes' && !formData.emergency_fund_coverage) {
      handleInputChange({
        target: {
          name: 'emergency_fund_coverage',
          value: '',
        },
      });
    }
  }, [formData.emergency_fund, language, handleInputChange, t.emergency_fund_coverage_options]);

  useEffect(() => {
    validateSection(validateSection3);
  }, [validateSection, formData]);

  const emergencyFundOptions = formData.emergency_fund === 'Yes'
    ? t.emergency_fund_coverage_options
    : {
      "Select an option": t.emergency_fund_coverage_options["Select an option"],
      [t.emergency_fund_coverage_options["I DON'T have Emergency Fund"]]: t.emergency_fund_coverage_options["I DON'T have Emergency Fund"],
    };

  const filteredEmergencyFundOptions = formData.emergency_fund === 'Yes'
    ? Object.keys(emergencyFundOptions)
      .filter((key) => key !== t.emergency_fund_coverage_options["I DON'T have Emergency Fund"])
      .reduce((obj, key) => {
        obj[key] = emergencyFundOptions[key];
        return obj;
      }, {})
    : emergencyFundOptions;

  return (
    <div className="form-page" id="section-3">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.description}</p>
      <img
        src="/images/section3_expenses.jpg"
        alt="Expenses"
        style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }}
      />
      {[
        'monthly_expenses',
        'mandatory_expenses',
        'discretionary_expenses',
        'rent_response',
        'education_expenses',
        'car_loan_emi',
        'emergency_fund',
      ].map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[`${field}_label`]}
            options={t[`${field}_options`]}
            value={formData[field]}
            handleChange={handleInputChange}
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}

      {/* Emergency Fund Coverage Logic */}
      <SelectField
        key={formData.emergency_fund_coverage}
        name="emergency_fund_coverage"
        label={t.questions["emergency_fund_coverage_label"]}
        options={filteredEmergencyFundOptions}
        value={formData.emergency_fund_coverage}
        handleChange={handleInputChange}
        disabled={formData.emergency_fund === 'No'}
      />

      {errors.emergency_fund_coverage && <p className="error-message">{errors.emergency_fund_coverage}</p>}
    </div>
  );
};

export default HouseholdExpenses;