import axios from 'axios';

const API = axios.create({
 // baseURL: 'http://103.102.234.55:8080/api' // Replace with your IP
 baseURL: 'https://advisormitra.com/api'
});
export const getCustomerDetailAgentId = (agentId) => {
  return API.get('/pdf/customerdetails', {
    params: {
      agentId: agentId,
    },
  });
};
export function submitFormData(formData, token) {
  return API.post('/form-submissions', formData, {
      headers: {
          'Content-Type': 'application/json',
      },
      params: {
        token: token,
      }
  });
};
export const generateFormLink = (agentId) => {
  return API.post('/form-links/generate', null, {
    params: { agentId: agentId },
  });
};
export const contactUs = (formData) => {
  return API.post('/contact', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const pricing = (formData) => {
  return API.post('/pricing', formData) ;
};

export const checkDuplicate = (email, phone) => {
  return API.get(`/agents/checkduplicate?email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}`);
};

export const createAgent = (agentData) =>
  API.post('/agents/register', agentData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const createBulkAgents = (agentData) =>
  API.post('/agents/bulkregister', agentData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const getAgentsByOwnerId = (userId) => {
  return API.get(`/agents/owner/${userId}`);
};
export const getAgentByAgentId = (agentId) => {
  return API.get(`/agents/agent/${agentId}`);
};
export const getAgentByAgentIdDownload = (agentId) => {
  return API.get(`/agents/agent/${agentId}`);
};
export const findAgentsWithDetailsByOwnerId = (userId) => {
  return API.get(`/agents/owners/${userId}`);
};

export const getAgentDetailsWithReportCount = () => {
  return API.get('/agents/agent-details-with-report-count'); // Added /api
};

export const getOrganizations = () => {
  return API.get('/organizations');
};

export const getSubscriptionPackages = () => {
  return API.get('/SubscriptionPackage');
};
export const getReferredBy = () => {
  return API.get('/ReferredBy');
};

export const getRoles = () => {
  return API.get('/roles');
};

export const getSubscriptionsByAgentId = (agentId) => {
  return API.get(`/subscriptions/agent/${agentId}`);
};

export const uploadPdf = (formData) => {
  return API.post('/pdf/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
export const downloadPdf = (formData, onProgress) => {
  return API.post('/pdf/download', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      if (onProgress && progressEvent.total) {
        onProgress(progressEvent);
      }
    }
  });
};

export const downloaddbPdf = (formData, onProgress) => {
  return API.post('/pdf/downloaddb', formData, {
    responseType: 'blob', // Keep responseType: 'blob' for PDF download
    onDownloadProgress: (progressEvent) => {
      if (onProgress && progressEvent.total) {
        onProgress(progressEvent);
      }
    }
  });
};

export const loginUser = (username, password) => {
  return API.post('/users/login', { username, password });
  
};

export const registerUser = (user) => {
  return API.post('/auth/register', user);
};

export const changePassword = (userId, oldPassword, newPassword) => {
  return API.post('/users/change-password', {
    id: userId,
    oldPassword,
    newPassword,
  });
};

// Moved to top level
export const getAgentByEmail = (email) => {
  return API.get(`/agents/email/${encodeURIComponent(email)}`);
};
// Fetch last login timestamp for a user by username
export const getLastLogin = (userId) => {
  return API.get(`/users/last-login/${userId}`);
};  
// Fetch report based on agent id 
export const getReportByAgentId = (agentId) => {
  return API.get(`reports/reportdetails/${agentId}`);
};