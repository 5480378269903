import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api'; // Import your API function
import '../css/CustomerForm.css'; // Import your CSS file

const CreditBehaviorTranslations = {
    en: {
        section_title: "Examine your use of credit, including credit card usage, personal loans, and your approach to managing debt. Delve into your spending behavior, including how you prioritize spending, handle non essential purchases, and manage unexpected windfalls.",
        questions: {
          credit_card_usage: "62. How often do you use a credit card for your expenses?",
          credit_balance_management: "63. How do you typically manage your credit card balance?",
          credit_spending_approach: "64. How do you approach spending when using credit?",
          credit_card_attitude: "65. What is your attitude towards credit card debt?",
          credit_statement_review: "66. How often do you review your credit card statements for accuracy and budgeting?",
          spending_behavior: "67. How would you describe your overall spending behavior?",
          purchase_regret_frequency: "68. How often do you make purchases that you later regret?",
          budget_following: "69. Do you have a budget or spending plan that you follow regularly?",
          spending_prioritization: "70. How do you prioritize your spending?",
          non_essential_decisions: "71. How do you decide on non-essential purchases (e.g., luxury items, entertainment)?",
          budget_exceed_frequency: "72. How often do you exceed your monthly budget or spending plan?",
          windfall_usage: "73. When you receive an unexpected windfall (e.g., bonus, gift), how do you usually spend it?",
          online_shopping_frequency: "74. How often do you shop online for non-essential items?",
          income_increase_spending: "75. How do you manage your discretionary spending when your income increases (e.g., raise, promotion)?",
        },

        credit_card_usage: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Always (for most of my expenses)": "Always (for most of my expenses)",
            "Frequently (for major purchases)": "Frequently (for major purchases)",
            "Occasionally (for specific needs)": "Occasionally (for specific needs)",
            "Rarely (only in emergencies)": "Rarely (only in emergencies)",
            "Never": "Never"
          },
          credit_balance_management: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Pay off the full balance every month": "Pay off the full balance every month",
            "Pay more than the minimum amount due but not the full balance": "Pay more than the minimum amount due but not the full balance",
            "Pay only the minimum amount due": "Pay only the minimum amount due",
            "Carry a balance and pay as much as I can": "Carry a balance and pay as much as I can",
            "Often miss payments": "Often miss payments"
          },
          credit_spending_approach: {
            "Select an option": "Select an option",
            "I spend only what I can afford to pay off immediately": "I spend only what I can afford to pay off immediately",
            "I make purchases with a plan to pay them off over time": "I make purchases with a plan to pay them off over time",
            "I sometimes spend beyond my means and worry about payments later": "I sometimes spend beyond my means and worry about payments later",
            "I avoid using credit for purchases": "I avoid using credit for purchases"
          },
          credit_card_attitude: {
            "Not Applicable": "Not Applicable",
            "Select an option": "Select an option",
            "I am very cautious and avoid it whenever possible": "I am very cautious and avoid it whenever possible",
            "I use it strategically to manage cash flow": "I use it strategically to manage cash flow",
            "I consider it a necessary tool for managing finances": "I consider it a necessary tool for managing finances",
            "I often accumulate debt and struggle with payments": "I often accumulate debt and struggle with payments",
            "I am indifferent as long as I can manage the payments": "I am indifferent as long as I can manage the payments"
          },
          credit_statement_review: {
            "Not Applicable": "Not Applicable",
            "Select an option": "Select an option",
            "Every month": "Every month",
            "Every few months": "Every few months",
            "Once or twice a year": "Once or twice a year",
            "Rarely": "Rarely",
            "Never": "Never"
          },
          spending_behavior: {
            "Select an option": "Select an option",
            "Very thoughtful and budget-conscious": "Very thoughtful and budget-conscious",
            "Generally cautious but sometimes make impulsive purchases": "Generally cautious but sometimes make impulsive purchases",
            "A mix of thoughtful and impulsive spending": "A mix of thoughtful and impulsive spending",
            "Frequently impulsive, with occasional regret": "Frequently impulsive, with occasional regret",
            "Reckless, often leading to financial stress": "Reckless, often leading to financial stress"
          },
                
          purchase_regret_frequency: {
            "Select an option": "Select an option",
            "Never": "Never",
            "Rarely": "Rarely",
            "Occasionally": "Occasionally",
            "Frequently": "Frequently",
            "Almost Always": "Almost Always"
          },
          budget_following: {
            "Select an option": "Select an option",
            "Yes, and I follow it strictly": "Yes, and I follow it strictly",
            "Yes, but I follow it loosely": "Yes, but I follow it loosely",
            "No, but I keep track of my spending informally": "No, but I keep track of my spending informally",
            "No, I do not have any spending plan": "No, I do not have any spending plan"
          },
          spending_prioritization: {
            "Select an option": "Select an option",
            "Essential needs first, followed by savings, then discretionary spending": "Essential needs first, followed by savings, then discretionary spending",
            "A balance between needs and wants, with savings as a priority": "A balance between needs and wants, with savings as a priority",
            "I often prioritize wants over needs": "I often prioritize wants over needs",
            "I spend first and save what’s left": "I spend first and save what’s left"
          },
          
          non_essential_decisions: {
            "Select an option": "Select an option",
            "I research and plan before making a purchase": "I research and plan before making a purchase",
            "I consider my current financial situation and then decide": "I consider my current financial situation and then decide",
            "I often make spontaneous decisions": "I often make spontaneous decisions",
            "I rarely think about it and buy on impulse": "I rarely think about it and buy on impulse",
            "I avoid non-essential purchases": "I avoid non-essential purchases"
          },
        
          budget_exceed_frequency: {
            "Select an option": "Select an option",
            "Never": "Never",
            "Rarely (once or twice a year)": "Rarely (once or twice a year)",
            "Occasionally (every few months)": "Occasionally (every few months)",
            "Frequently (most months)": "Frequently (most months)",
            "Always": "Always"
          },
          windfall_usage: {
            "Select an option": "Select an option",
            "Save or invest the entire amount": "Save or invest the entire amount",
            "Save a portion and spend the rest": "Save a portion and spend the rest",
            "Spend most of it and save a little": "Spend most of it and save a little",
            "Spend the entire amount on something I want": "Spend the entire amount on something I want",
            "Use it to pay off debt": "Use it to pay off debt"
          },

          online_shopping_frequency: {
            "Select an option": "Select an option",
            "Never": "Never",
            "Rarely (once or twice a year)": "Rarely (once or twice a year)",
            "Occasionally (every few months)": "Occasionally (every few months)",
            "Frequently (most months)": "Frequently (most months)",
            "Very often (almost every week)": "Very often (almost every week)"
          },
          income_increase_spending: {
            "Select an option": "Select an option",
            "I maintain the same level of spending and save the extra": "I maintain the same level of spending and save the extra",
            "I slightly increase my spending but mostly save the extra": "I slightly increase my spending but mostly save the extra",
            "I increase my spending in line with my new income": "I increase my spending in line with my new income",
            "I significantly increase my spending and enjoy the extra income": "I significantly increase my spending and enjoy the extra income",
            "I tend to spend more than the increase in income": "I tend to spend more than the increase in income"
          },
          errors: {
            credit_card_usage: "Please select an option for credit card usage.",
            credit_balance_management: "Please select an option for credit balance management.",
            credit_spending_approach: "Please select an option for credit spending approach.",
            credit_card_attitude: "Please select an option for your attitude towards credit card debt.",
            credit_statement_review: "Please select how often you review your credit card statements.",
            spending_behavior: "Please select an option to describe your spending behavior.",
            purchase_regret_frequency: "Please select how often you regret purchases.",
            budget_following: "Please indicate if you follow a budget.",
            spending_prioritization: "Please select how you prioritize your spending.",
            non_essential_decisions: "Please select how you decide on non-essential purchases.",
            budget_exceed_frequency: "Please select how often you exceed your budget.",
            windfall_usage: "Please select how you spend unexpected windfalls.",
            online_shopping_frequency: "Please select how often you shop online for non-essential items.",
            income_increase_spending: "Please select how you manage spending when your income increases."
          },
          
     
          
    },

   
  
  hi: {
      section_title: "अपने क्रेडिट के उपयोग की जांच करें, जिसमें क्रेडिट कार्ड का उपयोग, व्यक्तिगत ऋण और ऋण प्रबंधन का तरीका शामिल है। अपने खर्च करने के व्यवहार का विश्लेषण करें, जिसमें यह शामिल है कि आप खर्च को कैसे प्राथमिकता देते हैं, गैर-आवश्यक खरीदारी को कैसे संभालते हैं, और अप्रत्याशित आय को कैसे प्रबंधित करते हैं।",
      questions: {
          credit_card_usage: "62. आप अपने खर्चों के लिए क्रेडिट कार्ड का उपयोग कितनी बार करते हैं?",
          credit_balance_management: "63. आप आमतौर पर अपने क्रेडिट कार्ड बैलेंस को कैसे प्रबंधित करते हैं?",
          credit_spending_approach: "64. क्रेडिट का उपयोग करते समय आप खर्च करने के लिए कैसे दृष्टिकोण अपनाते हैं?",
          credit_card_attitude: "65. क्रेडिट कार्ड ऋण के प्रति आपका दृष्टिकोण क्या है?",
          credit_statement_review: "66. आप अपने क्रेडिट कार्ड स्टेटमेंट की सटीकता और बजट के लिए कितनी बार समीक्षा करते हैं?",
          spending_behavior: "67. आप अपने समग्र खर्च करने के व्यवहार को कैसे वर्णित करेंगे?",
          purchase_regret_frequency: "68. आप कितनी बार ऐसी खरीदारी करते हैं जिसका आपको बाद में पछतावा होता है?",
          budget_following: "69. क्या आपके पास एक बजट या खर्च योजना है जिसका आप नियमित रूप से पालन करते हैं?",
          spending_prioritization: "70. आप अपने खर्च को कैसे प्राथमिकता देते हैं?",
          non_essential_decisions: "71. आप गैर-आवश्यक खरीदारी (जैसे, लक्जरी आइटम, मनोरंजन) के बारे में कैसे निर्णय लेते हैं?",
          budget_exceed_frequency: "72. आप कितनी बार अपने मासिक बजट या खर्च योजना को पार कर जाते हैं?",
          windfall_usage: "73. जब आपको अप्रत्याशित आय (जैसे, बोनस, उपहार) प्राप्त होती है, तो आप आमतौर पर इसे कैसे खर्च करते हैं?",
          online_shopping_frequency: "74. आप गैर-आवश्यक वस्तुओं के लिए ऑनलाइन कितनी बार खरीदारी करते हैं?",
          income_increase_spending: "75. जब आपकी आय बढ़ती है (जैसे, वेतन वृद्धि, पदोन्नति), तो आप अपने विवेकाधीन खर्च को कैसे प्रबंधित करते हैं?",
      },
  
      credit_card_usage: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Always (for most of my expenses)": "हमेशा (अधिकांश खर्चों के लिए)",
          "Frequently (for major purchases)": "अक्सर (बड़ी खरीदारी के लिए)",
          "Occasionally (for specific needs)": "कभी-कभी (विशिष्ट आवश्यकताओं के लिए)",
          "Rarely (only in emergencies)": "शायद ही कभी (केवल आपात स्थितियों में)",
          "Never": "कभी नहीं"
      },
      credit_balance_management: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Pay off the full balance every month": "हर महीने पूरा बैलेंस चुकाएं",
          "Pay more than the minimum amount due but not the full balance": "न्यूनतम देय राशि से अधिक भुगतान करें लेकिन पूरा बैलेंस नहीं",
          "Pay only the minimum amount due": "केवल न्यूनतम देय राशि का भुगतान करें",
          "Carry a balance and pay as much as I can": "बैलेंस रखें और जितना हो सके उतना भुगतान करें",
          "Often miss payments": "अक्सर भुगतान छूट जाता है"
      },
      credit_spending_approach: {
          "Select an option": "एक विकल्प चुनें",
          "I spend only what I can afford to pay off immediately": "मैं केवल उतना खर्च करता हूं जितना मैं तुरंत चुका सकता हूं",
          "I make purchases with a plan to pay them off over time": "मैं समय के साथ उन्हें चुकाने की योजना के साथ खरीदारी करता हूं",
          "I sometimes spend beyond my means and worry about payments later": "मैं कभी-कभी अपने साधनों से अधिक खर्च करता हूं और बाद में भुगतान के बारे में चिंता करता हूं",
          "I avoid using credit for purchases": "मैं खरीदारी के लिए क्रेडिट का उपयोग करने से बचता हूं"
      },
      credit_card_attitude: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "I am very cautious and avoid it whenever possible": "मैं बहुत सतर्क हूं और जब भी संभव हो इससे बचता हूं",
          "I use it strategically to manage cash flow": "मैं इसे नकदी प्रवाह को प्रबंधित करने के लिए रणनीतिक रूप से उपयोग करता हूं",
          "I consider it a necessary tool for managing finances": "मैं इसे वित्त प्रबंधन के लिए एक आवश्यक उपकरण मानता हूं",
          "I often accumulate debt and struggle with payments": "मैं अक्सर ऋण जमा करता हूं और भुगतान के साथ संघर्ष करता हूं",
          "I am indifferent as long as I can manage the payments": "जब तक मैं भुगतान प्रबंधित कर सकता हूं, मैं उदासीन हूं"
      },
      credit_statement_review: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Every month": "हर महीने",
          "Every few months": "हर कुछ महीनों में",
          "Once or twice a year": "साल में एक या दो बार",
          "Rarely": "शायद ही कभी",
          "Never": "कभी नहीं"
      },
      spending_behavior: {
          "Select an option": "एक विकल्प चुनें",
          "Very thoughtful and budget-conscious": "बहुत विचारशील और बजट-जागरूक",
          "Generally cautious but sometimes make impulsive purchases": "आम तौर पर सतर्क लेकिन कभी-कभी आवेगी खरीदारी करते हैं",
          "A mix of thoughtful and impulsive spending": "विचारशील और आवेगी खर्च का मिश्रण",
          "Frequently impulsive, with occasional regret": "अक्सर आवेगी, कभी-कभी पछतावा होता है",
          "Reckless, often leading to financial stress": "लापरवाह, अक्सर वित्तीय तनाव का कारण बनता है"
      },
      purchase_regret_frequency: {
          "Select an option": "एक विकल्प चुनें",
          "Never": "कभी नहीं",
          "Rarely": "शायद ही कभी",
          "Occasionally": "कभी-कभी",
          "Frequently": "अक्सर",
          "Almost Always": "लगभग हमेशा"
      },
      budget_following: {
          "Select an option": "एक विकल्प चुनें",
          "Yes, and I follow it strictly": "हां, और मैं इसका सख्ती से पालन करता हूं",
          "Yes, but I follow it loosely": "हां, लेकिन मैं इसे ढीले ढंग से पालन करता हूं",
          "No, but I keep track of my spending informally": "नहीं, लेकिन मैं अपने खर्च को अनौपचारिक रूप से ट्रैक करता हूं",
          "No, I do not have any spending plan": "नहीं, मेरे पास कोई खर्च योजना नहीं है"
      },
      spending_prioritization: {
          "Select an option": "एक विकल्प चुनें",
          "Essential needs first, followed by savings, then discretionary spending": "पहले आवश्यक जरूरतें, फिर बचत, उसके बाद विवेकाधीन खर्च",
          "A balance between needs and wants, with savings as a priority": "जरूरतों और इच्छाओं के बीच संतुलन, बचत को प्राथमिकता देना",
          "I often prioritize wants over needs": "मैं अक्सर जरूरतों से अधिक इच्छाओं को प्राथमिकता देता हूं",
          "I spend first and save what’s left": "मैं पहले खर्च करता हूं और जो बचता है उसे बचाता हूं"
      },
      non_essential_decisions: {
          "Select an option": "एक विकल्प चुनें",
          "I research and plan before making a purchase": "खरीदारी करने से पहले मैं शोध और योजना बनाता हूं",
          "I consider my current financial situation and then decide": "मैं अपनी वर्तमान वित्तीय स्थिति पर विचार करता हूं और फिर निर्णय लेता हूं",
          "I often make spontaneous decisions": "मैं अक्सर सहज निर्णय लेता हूं",
          "I rarely think about it and buy on impulse": "मैं शायद ही इसके बारे में सोचता हूं और आवेग में खरीदता हूं",
          "I avoid non-essential purchases": "मैं गैर-आवश्यक खरीदारी से बचता हूं"
      },
      budget_exceed_frequency: {
          "Select an option": "एक विकल्प चुनें",
          "Never": "कभी नहीं",
          "Rarely (once or twice a year)": "शायद ही कभी (साल में एक या दो बार)",
          "Occasionally (every few months)": "कभी-कभी (हर कुछ महीनों में)",
          "Frequently (most months)": "अक्सर (अधिकांश महीनों में)",
          "Always": "हमेशा"
      },
      windfall_usage: {
          "Select an option": "एक विकल्प चुनें",
          "Save or invest the entire amount": "पूरी राशि को बचाएं या निवेश करें",
          "Save a portion and spend the rest": "एक हिस्सा बचाएं और बाकी खर्च करें",
          "Spend most of it and save a little": "अधिकांश खर्च करें और थोड़ा बचाएं",
          "Spend the entire amount on something I want": "पूरी राशि को किसी चीज़ पर खर्च करें जो मैं चाहता हूं",
          "Use it to pay off debt": "इसका उपयोग ऋण चुकाने के लिए करें"
      },
      online_shopping_frequency: {
          "Select an option": "एक विकल्प चुनें",
          "Never": "कभी नहीं",
          "Rarely (once or twice a year)": "शायद ही कभी (साल में एक या दो बार)",
          "Occasionally (every few months)": "कभी-कभी (हर कुछ महीनों में)",
          "Frequently (most months)": "अक्सर (अधिकांश महीनों में)",
          "Very often (almost every week)": "बहुत अक्सर (लगभग हर हफ्ते)"
      },
      income_increase_spending: {
          "Select an option": "एक विकल्प चुनें",
          "I maintain the same level of spending and save the extra": "मैं खर्च का समान स्तर बनाए रखता हूं और अतिरिक्त बचाता हूं",
          "I slightly increase my spending but mostly save the extra": "मैं अपने खर्च को थोड़ा बढ़ाता हूं लेकिन अधिकांश अतिरिक्त बचाता हूं",
          "I increase my spending in line with my new income": "मैं अपने खर्च को अपनी नई आय के अनुसार बढ़ाता हूं",
          "I significantly increase my spending and enjoy the extra income": "मैं अपने खर्च को काफी बढ़ाता हूं और अतिरिक्त आय का आनंद लेता हूं",
          "I tend to spend more than the increase in income": "मैं आय में वृद्धि से अधिक खर्च करता हूं"
      },
      errors: {
        credit_card_usage: "कृपया क्रेडिट कार्ड उपयोग के लिए एक विकल्प चुनें।",
        credit_balance_management: "कृपया क्रेडिट बैलेंस प्रबंधन के लिए एक विकल्प चुनें।",
        credit_spending_approach: "कृपया क्रेडिट खर्च दृष्टिकोण के लिए एक विकल्प चुनें।",
        credit_card_attitude: "कृपया क्रेडिट कार्ड ऋण के प्रति अपने दृष्टिकोण के लिए एक विकल्प चुनें।",
        credit_statement_review: "कृपया यह चुनें कि आप अपने क्रेडिट कार्ड स्टेटमेंट की कितनी बार समीक्षा करते हैं।",
        spending_behavior: "कृपया अपने खर्च करने के व्यवहार का वर्णन करने के लिए एक विकल्प चुनें।",
        purchase_regret_frequency: "कृपया यह चुनें कि आपको कितनी बार खरीदारी पर पछतावा होता है।",
        budget_following: "कृपया संकेत दें कि क्या आप बजट का पालन करते हैं।",
        spending_prioritization: "कृपया यह चुनें कि आप अपने खर्च को कैसे प्राथमिकता देते हैं।",
        non_essential_decisions: "कृपया यह चुनें कि आप गैर-आवश्यक खरीदारी के बारे में कैसे निर्णय लेते हैं।",
        budget_exceed_frequency: "कृपया यह चुनें कि आप कितनी बार अपने बजट को पार करते हैं।",
        windfall_usage: "कृपया यह चुनें कि आप अप्रत्याशित आय को कैसे खर्च करते हैं।",
        online_shopping_frequency: "कृपया यह चुनें कि आप गैर-आवश्यक वस्तुओं के लिए ऑनलाइन कितनी बार खरीदारी करते हैं।",
        income_increase_spending: "कृपया यह चुनें कि आपकी आय बढ़ने पर आप अपने खर्च को कैसे प्रबंधित करते हैं।"
      }
     
  },
};

export default CreditBehaviorTranslations ;