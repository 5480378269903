import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword, getAgentByEmail } from "../services/api";
import "../css/ChangePassword.css";

const ChangePassword = ({ setPasswordChangeRequired }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "AdvisorMitra.com - Change Password";

    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
    const storedAgentId = localStorage.getItem("agentId");
    if (storedAgentId) {
      setAgentId(storedAgentId);
    }
  }, []);

  // Password validation rules
  const validatePassword = (password) => {
    const regex = /^(?=[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,15}$/;
    return regex.test(password);
  };

  // Password strength calculation (from 0 to 4)
  const calculateStrength = (password) => {
    let strength = 0;
    if (password.length >= 8 && password.length <= 15) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[\W_]/.test(password)) strength++;

    setPasswordStrength(strength);
    setIsPasswordValid(validatePassword(password));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    // Validate new password
    if (!validatePassword(newPassword)) {
      setErrorMessage(
        "New password must be 8-15 characters, contain at least 1 uppercase letter, 1 number, and 1 special character."
      );
      return;
    }

    // Ensure new password is different from the old one
    if (newPassword === oldPassword) {
      setErrorMessage("New password cannot be the same as the old password.");
      return;
    }

    // Validate new password and confirm password match
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await changePassword(userId, oldPassword, newPassword);
      if (response.status === 200) {
        alert("Password updated successfully! Please log in with your new password.");
        setPasswordChangeRequired(false);
        localStorage.clear(); // Clear local storage to ensure security
        navigate("/login");
      }
    } catch (error) {
      setErrorMessage("Failed to change password. Please try again.");
    }
  };

  return (
    <div className="change-password-container">
      <div className="change-password-form">
        <h2>Change Password</h2>
        <form onSubmit={handleChangePassword}>
          <div className="form-group">
            <label htmlFor="oldPassword">Old Password:</label>
            <input
              type="password"
              id="oldPassword"
              placeholder="Enter Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                calculateStrength(e.target.value);
              }}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Password Strength Indicator */}
          <div className="password-strength">
            <div className={`strength-bar strength-${passwordStrength}`}></div>
            
          </div>

          {/* Password Requirements Display */}
          {!isPasswordValid && (
            <p className="error-message">
              Password must be 8-15 characters, contain at least 1 uppercase letter, 1 number, and 1 special character.
            </p>
          )}

          <div className="password-requirements">
            <h4>Password Requirements:</h4>
            <ul>
              <li>Minimum 8 characters, Maximum 15 characters</li>
              <li>Must start with an alphabet</li>
              <li>Must contain at least 1 uppercase letter</li>
              <li>Must contain at least 1 numeral</li>
              <li>Must contain at least 1 special character (e.g., !@#$%^&*)</li>
            </ul>
          </div>

          <button type="submit">Change Password</button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
// In this example, we have created a form component for changing the user's password. The component allows users to enter their old password, new password, and confirm the new password. It also includes password strength validation and password requirements display. The form submission triggers an API call to change the user's password. If the password change is successful, the user is redirected to the login page.
