import React, { useState, useEffect } from 'react';
import FixedAndLiquidAssetsTranslations from '../sections/FixedAndLiquidAssetsTranslations';

const SelectField = ({ name, label, options, value, handleChange, error }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options).filter(([key]) => key !== 'Select an option').map(([key, optionLabel]) => (
        <option key={key} value={key}>{optionLabel}</option>
      ))}
    </select>
    {error && <p className="error-message">{error}</p>}
  </div>
);

const FixedAndLiquidAssets = ({ formData, handleInputChange, language, validateSection }) => {
  const t = FixedAndLiquidAssetsTranslations[language] || FixedAndLiquidAssetsTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSection7 = () => {
    const newErrors = ['fixed_assets_value', 'liquid_assets_value']
      .reduce((acc, field) => {
        if (!formData[field]) acc[field] = t.errors[`${field}Required`] || `Please select an option for ${field.replace(/_/g, ' ')}.`;
        return acc;
      }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validateSection(validateSection7);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-7">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_description}</p>
      <img src="/images/section7_assets.jpg" alt="Assets" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      
      <SelectField
        name="fixed_assets_value"
        label={t.questions.fixed_assets_value}
        options={t.fixed_assets_value_options}
        value={formData.fixed_assets_value}
        handleChange={handleInputChange}
        error={errors.fixed_assets_value}
      />

      <SelectField
        name="liquid_assets_value"
        label={t.questions.liquid_assets_value}
        options={t.liquid_assets_value_options}
        value={formData.liquid_assets_value}
        handleChange={handleInputChange}
        error={errors.liquid_assets_value}
      />
    </div>
  );
};

export default FixedAndLiquidAssets;
