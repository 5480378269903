// Section 9: Insurance Coverage
const InsuranceCoverageTranslations = {
    en: {
      section_title: "Indicate your coverage for Health, Term, and Disability insurance, and identify any gaps in your insurance planning.",
      questions: {
        health_insurance: "45. Do you have personal health insurance? If Yes, what is the total coverage amount of your personal health insurance? Select Not Applicable if you DO NOT have one.",
        term_insurance: "46. Do you have term life insurance? If Yes, what is the total coverage amount of your term life insurance? Select Not Applicable if you DO NOT have one.",
        disability_insurance: "47. Do you have disability insurance? If Yes, what is the total coverage amount of your disability insurance? Select Not Applicable if you DO NOT have one.",
      },
      errors: {
        health_insurance: "Please indicate if you have personal health insurance and enter the total coverage amount, or select Not Applicable if you don't have one.",
        term_insurance: "Please indicate if you have term life insurance and enter the total coverage amount, or select Not Applicable if you don't have one.",
        disability_insurance: "Please indicate if you have disability insurance and enter the total coverage amount, or select Not Applicable if you don't have one."
      },
      
      health_insurance: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Don't Know": "Don't Know",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
        "Above ₹20,00,000": "Above ₹20,00,000",
      },
  
      term_insurance: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Don't Know": "Don't Know",
        "Less than ₹50,00,000": "Less than ₹50,00,000",
        "₹50,00,000": "₹50,00,000",
        "₹1,00,00,000": "₹1,00,00,000",
        "₹1,50,00,000": "₹1,50,00,000",
        "₹2,00,00,000": "₹2,00,00,000",
        "₹2,50,00,000": "₹2,50,00,000",
        "₹3,00,00,000": "₹3,00,00,000",
        "₹4,00,00,000": "₹4,00,00,000",
        "₹5,00,00,000": "₹5,00,00,000",
        "More than ₹5,00,00,000": "More than ₹5,00,00,000",
      },
  
      disability_insurance: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Don't Know": "Don't Know",
        "Less than ₹50,00,000": "Less than ₹50,00,000",
        "₹50,00,000": "₹50,00,000",
        "₹1,00,00,000": "₹1,00,00,000",
        "₹1,50,00,000": "₹1,50,00,000",
        "₹2,00,00,000": "₹2,00,00,000",
        "More than ₹2,00,00,000": "More than ₹2,00,00,000",
      },
    },

    hi: {
      section_title: "स्वास्थ्य, टर्म और विकलांगता बीमा के लिए अपना कवरेज बताएं, और अपनी बीमा योजना में किसी भी कमी की पहचान करें।",
      questions: {
          health_insurance: "45. क्या आपके पास व्यक्तिगत स्वास्थ्य बीमा है? यदि हां, तो आपके व्यक्तिगत स्वास्थ्य बीमा की कुल कवरेज राशि क्या है? यदि आपके पास नहीं है तो 'लागू नहीं' चुनें।",
          term_insurance: "46. क्या आपके पास टर्म लाइफ बीमा है? यदि हां, तो आपके टर्म लाइफ बीमा की कुल कवरेज राशि क्या है? यदि आपके पास नहीं है तो 'लागू नहीं' चुनें।",
          disability_insurance: "47. क्या आपके पास विकलांगता बीमा है? यदि हां, तो आपके विकलांगता बीमा की कुल कवरेज राशि क्या है? यदि आपके पास नहीं है तो 'लागू नहीं' चुनें।",
      },
      health_insurance: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Don't Know": "पता नहीं",
          "Less than ₹10,00,000": "₹10,00,000 से कम",
          "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
          "Above ₹20,00,000": "₹20,00,000 से अधिक",
      },
      term_insurance: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Don't Know": "पता नहीं",
          "Less than ₹50,00,000": "₹50,00,000 से कम",
          "₹50,00,000": "₹50,00,000",
          "₹1,00,00,000": "₹1,00,00,000",
          "₹1,50,00,000": "₹1,50,00,000",
          "₹2,00,00,000": "₹2,00,00,000",
          "₹2,50,00,000": "₹2,50,00,000",
          "₹3,00,00,000": "₹3,00,00,000",
          "₹4,00,00,000": "₹4,00,00,000",
          "₹5,00,00,000": "₹5,00,00,000",
          "More than ₹5,00,00,000": "₹5,00,00,000 से अधिक",
      },
      disability_insurance: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Don't Know": "पता नहीं",
          "Less than ₹50,00,000": "₹50,00,000 से कम",
          "₹50,00,000": "₹50,00,000",
          "₹1,00,00,000": "₹1,00,00,000",
          "₹1,50,00,000": "₹1,50,00,000",
          "₹2,00,00,000": "₹2,00,00,000",
          "More than ₹2,00,00,000": "₹2,00,00,000 से अधिक",
      },
      errors: {
        health_insurance: "कृपया बताएं कि क्या आपके पास व्यक्तिगत स्वास्थ्य बीमा है और उसका कुल कवरेज राशि दर्ज करें, या यदि नहीं है तो 'लागू नहीं' चुनें।",
        term_insurance: "कृपया बताएं कि क्या आपके पास टर्म लाइफ इंश्योरेंस है और उसका कुल कवरेज राशि दर्ज करें, या यदि नहीं है तो 'लागू नहीं' चुनें।",
        disability_insurance: "कृपया बताएं कि क्या आपके पास विकलांगता बीमा है और उसका कुल कवरेज राशि दर्ज करें, या यदि नहीं है तो 'लागू नहीं' चुनें।"
      },
      
  },
  };
  
  export default InsuranceCoverageTranslations;
  