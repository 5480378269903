import React, { useState, useEffect } from 'react';
import { contactUs } from '../services/api';
import "../css/ContactUs.css";

const ContactUs = () => {
    useEffect(() => {
        document.title = "AdvisorMitra.com - Contact Us";
    }, []);

    const [activeModal, setActiveModal] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        description: '',
        screenshot: null,
    });
    const [submissionMessage, setSubmissionMessage] = useState(null);

    const handleOpenModal = (type) => {
        setActiveModal(type);
        setFormData({ name: '', mobile: '', description: '', screenshot: null });
        setSubmissionMessage(null);
    };

    const handleCloseModal = () => {
        setActiveModal(null);
        setSubmissionMessage(null);
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value,
        });
    };

    const validateForm = () => {
        const { name, mobile, description } = formData;
        const phonePattern = /^[6-9]\d{9}$/;

        if (name.length < 4) {
            alert("Name must be at least 4 characters long.");
            return false;
        }
        if (!phonePattern.test(mobile)) {
            alert("Enter a valid 10-digit mobile number starting with 6-9.");
            return false;
        }
        if (description.length < 30) {
            alert("Brief description must be at least 30 characters long.");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e, email) => {
        e.preventDefault();
        if (!validateForm()) return;

        setSubmissionMessage("Sending message...");

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('mobile', formData.mobile);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('supportType', activeModal === 'tech' ? 'tech' : 'usage');

        if (formData.screenshot) { 
            formDataToSend.append('screenshot', formData.screenshot);
        }

        try {
            const response = await contactUs(formDataToSend);
            console.log("API Response:", response.data);
            setSubmissionMessage("Message sent successfully!");
            setFormData({ name: '', mobile: '', description: '', screenshot: null });
            setTimeout(handleCloseModal, 3000);
            alert("Your query has been submitted successfully. Our support team will reach out to you within 24 business hours. Thank You.");
        } catch (error) {
            alert('Looks like your query is not submitted successfully, please write to ' +
                'support@advisormitra.com with subject IMPORTANT, your name and mobile number, ' +
                'brief description of the issue for quick support.');
            console.error("API Error:", error);
            setSubmissionMessage("Error sending message. Please try again.");
        }
    };

    return (
        <div className="contact-us-container">
            <div className="contact-us">
                <h2>Contact Us</h2>

                <p>
                    We’re here to help! Whether you’re facing technical difficulties or need assistance with using our platform, 
                    our support team is ready to assist you. Choose the appropriate option below to get the help you need.
                </p>

                <p>
                    <strong>Technical issues?</strong> If you're experiencing system errors, login troubles, or functionality bugs, our tech support team can help.
                    <br />
                    <a href="#" onClick={() => handleOpenModal('tech')} className="tech-support-link">
                        <strong>Click Here</strong> 
                    </a>
                </p>

                <p>
                    <strong>Need help using the platform?</strong> If you have questions about features, reports, or general usage, reach out to our usage support team.
                    <br />
                    <a href="#" onClick={() => handleOpenModal('usage')} className="tech-support-link">
                        <strong>Click Here</strong> 
                    </a>
                </p>

                {/* Tech Support Modal */}
                {activeModal === 'tech' && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                            <h3>Tech Support</h3>
                            <form onSubmit={(e) => handleSubmit(e, "tech-support@advisormitra.com")}>
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

                                <label htmlFor="mobile">Mobile:</label>
                                <input type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />

                                <label htmlFor="description">Brief Description:</label>
                                <textarea id="description" name="description" value={formData.description} onChange={handleChange} required rows="5" cols="50" />

                                <label htmlFor="screenshot">Attach Screenshot (Max 1MB):</label>
                                <input type="file" id="screenshot" name="screenshot" onChange={handleChange} />

                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Usage Support Modal */}
                {activeModal === 'usage' && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                            <h3>Usage Support</h3>
                            <form onSubmit={(e) => handleSubmit(e, "support@advisormitra.com")}>
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

                                <label htmlFor="mobile">Mobile:</label>
                                <input type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />

                                <label htmlFor="description">Brief Description:</label>
                                <textarea id="description" name="description" value={formData.description} onChange={handleChange} required rows="5" cols="50" />

                                <label htmlFor="screenshot">Attach Screenshot (Max 1MB):</label>
                                <input type="file" id="screenshot" name="screenshot" onChange={handleChange} />

                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactUs;
