import React, { useState, useEffect } from 'react';
import { submitFormData } from '../services/api';
import '../css/CustomerForm.css';
import CreditBehaviorTranslations from '../sections/CreditBehaviorTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled, formData }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="" disabled>{options?.["Select an option"] || "Select an option"}</option>
      {Object.entries(options ?? {})
        .filter(([key]) => key !== 'Select an option') // Exclude "Select an option"
        .filter(([key]) => {
          // Remove "Not Applicable" if credit_card_usage is not "Not Applicable"
          if ((name === 'credit_balance_management' || name === 'credit_card_attitude' || name === 'credit_statement_review') 
              && formData?.credit_card_usage !== 'Not Applicable' 
              && key === 'Not Applicable') {
            return false;
          }
          return true;
        })
        .map(([key, optionLabel]) => (
          <option key={key} value={key}>{optionLabel}</option>
        ))}
    </select>
  </div>
);

const CreditBehavior = ({ formData, handleInputChange, language, validateSection }) => {
  const t = CreditBehaviorTranslations[language] || CreditBehaviorTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSectionCreditBehavior = () => {
    const fieldsToValidate = [
      'credit_card_usage', 'credit_balance_management', 'credit_spending_approach',
      'credit_card_attitude', 'credit_statement_review', 'spending_behavior',
      'purchase_regret_frequency', 'budget_following', 'spending_prioritization',
      'non_essential_decisions', 'budget_exceed_frequency', 'windfall_usage',
      'online_shopping_frequency', 'income_increase_spending'
    ];

    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.questions[field];
      }
      return acc;
    }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validateSection(validateSectionCreditBehavior);
  }, [validateSection, formData]);

  // Auto-update dependent fields if credit_card_usage is "Not Applicable"
  useEffect(() => {
    if (formData.credit_card_usage === "Not Applicable") {
      handleInputChange({ target: { name: 'credit_balance_management', value: 'Not Applicable' } });
      handleInputChange({ target: { name: 'credit_card_attitude', value: 'Not Applicable' } });
      handleInputChange({ target: { name: 'credit_statement_review', value: 'Not Applicable' } });
    }
  }, [formData.credit_card_usage, handleInputChange]);

  return (
    <div className="form-page" id="section-credit-behavior">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_title}</p>
      <img src="/images/spending_habits.png" alt="Credit Behavior" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      {Object.keys(t.questions).map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[field]}
            options={t[field]}
            value={formData[field]}
            handleChange={handleInputChange}
            disabled={
              (field === 'credit_balance_management' || field === 'credit_card_attitude' || field === 'credit_statement_review') 
              && formData.credit_card_usage === 'Not Applicable'
            }
            formData={formData} // Pass formData to SelectField
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default CreditBehavior;

