const FixedAndLiquidAssetsTranslations = {
    en: {
      section_description: "Provide insights into your asset holdings, including real estate, savings, investments, and other liquid assets.",
      questions: {
        fixed_assets_value: "30. What is the total value of your fixed assets (Property, Land, Car, etc.)?",
        liquid_assets_value: "31. What is the total value of your liquid assets (Cash, Bank Balances, Stocks, Mutual Funds, Gold, FDs, RDs etc.)?"
      },
      errors: {
        fixed_assets_value: "Please enter the total value of your fixed assets (Property, Land, Car, etc.).",
        liquid_assets_value: "Please enter the total value of your liquid assets (Cash, Bank Balances, Stocks, Mutual Funds, Gold, FDs, RDs, etc.)."
      },
      
      fixed_assets_value_options: {
        "Select an option": "Select an option",
        "I don't have Fixed Assets": "I don't have Fixed Assets",
        "Less than ₹10,00,000": "Less than ₹10,00,000",
        "₹10,00,000 - ₹50,00,000": "₹10,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "₹2,00,00,000 - ₹5,00,00,000": "₹2,00,00,000 - ₹5,00,00,000",
        "More than ₹5,00,00,000": "More than ₹5,00,00,000"
      },
      liquid_assets_value_options: {
        "Select an option": "Select an option",
        "I don't have Liquid Assets": "I don't have Liquid Assets",
        "Less than ₹5,00,000": "Less than ₹5,00,000",
        "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
        "₹10,00,000 - ₹50,00,000": "₹10,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000": "More than ₹1,00,00,000"
      },
    },
    hi: {
      section_description: "अपनी संपत्तियों की जानकारी दें, जिसमें अचल संपत्तियां, बचत, निवेश और अन्य तरल संपत्तियां शामिल हैं।",
      questions: {
        fixed_assets_value: "30. आपकी कुल अचल संपत्तियों (संपत्ति, भूमि, कार आदि) का कुल मूल्य कितना है?",
        liquid_assets_value: "31. आपकी कुल तरल संपत्तियों (नकद, बैंक बैलेंस, स्टॉक्स, म्यूचुअल फंड्स, सोना, एफडी, आरडी आदि) का कुल मूल्य कितना है?"
      },
      errors: {
        fixed_assets_value: "कृपया अपनी स्थिर संपत्तियों (संपत्ति, भूमि, कार आदि) का कुल मूल्य दर्ज करें।",
        liquid_assets_value: "कृपया अपनी तरल संपत्तियों (नकद, बैंक बैलेंस, स्टॉक्स, म्यूचुअल फंड्स, सोना, एफडी, आरडी आदि) का कुल मूल्य दर्ज करें।"
      },
      
      fixed_assets_value_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        "I don't have Fixed Assets": "मेरी कोई अचल संपत्ति नहीं है",
        "Less than ₹10,00,000": "₹10,00,000 से कम",
        "₹10,00,000 - ₹50,00,000": "₹10,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "₹2,00,00,000 - ₹5,00,00,000": "₹2,00,00,000 - ₹5,00,00,000",
        "More than ₹5,00,00,000": "₹5,00,00,000 से अधिक"
      },
      liquid_assets_value_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        "I don't have Liquid Assets": "मेरी कोई तरल संपत्ति नहीं है",
        "Less than ₹5,00,000": "₹5,00,000 से कम",
        "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
        "₹10,00,000 - ₹50,00,000": "₹10,00,000 - ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "More than ₹1,00,00,000":"₹1,00,00,000 से अधिक"
      },
    },
   
  };
  
  export default FixedAndLiquidAssetsTranslations;