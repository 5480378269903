import React from "react";
import { Link } from "react-router-dom";
import "../css/Footer.css";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Copyright */}
                <div className="footer-copyright">
                    &copy; {new Date().getFullYear()} Advisor Mitra. All rights reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
