import React, { useState, useEffect } from 'react';
import { getLastLogin } from '../services/api';
import "../css/LastLogin.css";
const LastLogin = ({ userId }) => {
  const [lastLogin, setLastLogin] = useState(null);

  useEffect(() => {
    const fetchLastLogin = async () => {
      try {
        const response = await getLastLogin(userId);
        setLastLogin(response.data);
      } catch (error) {
        console.error('Error fetching last login:', error);
      }
    };

    fetchLastLogin();
  }, [userId]);

  return (
    <div className="last-login-container">

      <h3>Last Login</h3>
      {lastLogin ? (
        <p>Last login time: {new Date(lastLogin).toLocaleString()}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default LastLogin;
// In this example, we have created a LastLogin component that displays the last login time of a user. The component fetches the last login time from the API using the user ID and displays it in a user-friendly format. The component also handles loading states and error handling during the API call. The LastLogin component can be used in various parts of the application to show the last login time of different users.
