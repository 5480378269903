import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api'; // Import your API function
import '../css/CustomerForm.css'; // Import your CSS file
import HouseholdIncomeTranslations from '../sections/HouseholdIncomeTranslations'; // Corrected import path

{/*Section 2: Household Income & Sources */}

const HouseholdIncome = ({ formData, handleInputChange, handleCheckboxChange, language ,validateSection}) => {
    const t = HouseholdIncomeTranslations[language] || HouseholdIncomeTranslations.en;
    const [errors, setErrors] = useState({});
    const validateSection2 = () => {
        setErrors({});
        const newErrors = {};

        if (!formData.income_level) {
            newErrors.income_level = t.errors.income_level_required || "Please select a household income range.";
        }

        if (!formData.income_sources || formData.income_sources.length === 0) {
            newErrors.income_sources = t.errors.income_sources_required || "Please select at least one income source.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
       //return true;
    };

    useEffect(() => {
        validateSection(validateSection2);
    }, [validateSection, validateSection2]); // Add validateSection and validateSection2 to dependency array

  
    return (
        <div className="form-page" id="section-2">
            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>
                {t.income.description}
            </p>
            <div id="incomeImagePlaceholder">
                <img src="/images/section2_incomesources.png" alt="Income Sources" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
            </div>
            <label htmlFor="income_level_label" id="income_level_label">
                {t.income.income_level_label}
            </label>
            <select id="income_level" name="income_level" required  value={formData.income_level} onChange={handleInputChange} >
                    <option value="">{t.income.income_level_select}</option>
                    {Object.entries(t.income.income_level_ranges).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
            </select>

            {errors.income_level && <p className="error-message">{errors.income_level}</p>}

            <label htmlFor="income_sources" id="income_sources">
                {t.income.income_sources_label}
            </label>
            <div id="primaryIncomeSources" className="checkmark-group">
                {Object.entries(t.income.income_sources_options).map(([key, value]) => (
                    <label key={key}>
                    <input
                        type="checkbox"
                        name="income_sources"
                        value={value}
                        onChange={handleCheckboxChange}
                    />
                    {value}
                    </label>
                ))}
                </div>

            {errors.income_sources && <p className="error-message">{errors.income_sources}</p>}

        </div>
    );
};

export default HouseholdIncome;