import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/HorizontalNavbar.css";

const HorizontalNavbar = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const [translateActive, setTranslateActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle menu function
  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const resetToEnglish = () => {
      const select = document.querySelector(".goog-te-combo");
      if (select) {
        select.value = "en";
        select.dispatchEvent(new Event("change"));
      }
    };

    const removeExistingGoogleTranslate = () => {
      const translateElement = document.getElementById("google_translate_element");
      if (translateElement) translateElement.innerHTML = "";

      const translateScript = document.getElementById("google-translate-script");
      if (translateScript) translateScript.remove();
    };

    const initializeGoogleTranslate = () => {
      removeExistingGoogleTranslate();
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "hi,gu,mr,ta,bn,or,te,kn,pa,ml,en",
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          "google_translate_element"
        );
        setTimeout(resetToEnglish, 1000);
      }
    };

    const addGoogleTranslateScript = () => {
      removeExistingGoogleTranslate();
      const script = document.createElement("script");
      script.id = "google-translate-script";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&v=" +
        new Date().getTime();
      script.async = true;
      document.body.appendChild(script);

      window.googleTranslateElementInit = initializeGoogleTranslate;
    };

    addGoogleTranslateScript();

    // Observer to detect Google Translate banner
    const checkTranslateBar = () => {
      const bannerFrame = document.querySelector(".goog-te-banner-frame");
      setTranslateActive(!!bannerFrame);
    };

    const observer = new MutationObserver(checkTranslateBar);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [location.pathname]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".horizontal-navbar") && menuOpen) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [menuOpen]);

  return (
    <>
      {/* Google Translate Dropdown */}
      <div id="google_translate_element" className="translate-wrapper"></div>

      <nav className={`horizontal-navbar ${translateActive ? "navbar-fixed" : ""} ${menuOpen ? "active" : ""}`}>
        {/* Hamburger Menu Icon (Only for Mobile) */}
        <div className={`menu-icon ${menuOpen ? "open" : ""}`} onClick={toggleMenu}>
        <div className={menuOpen ? "open" : ""}></div>
          <div className={menuOpen ? "open" : ""}></div>
          <div className={menuOpen ? "open" : ""}></div>
        </div>

        {/* Navbar Links */}
        <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
          {!isLoginPage && (
            <li className="login-button-container">
              <Link to="/login" className="login-button" onClick={toggleMenu}>
                Login
              </Link>
            </li>
          )}
          <li>
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          {!isLoginPage && (
            <>
              <li className="dropdown">
                <Link to="/AboutUs" className="dropbtn" onClick={toggleMenu}>
                  About Us
                </Link>
              </li>
              <li className="dropdown">
                <Link to="/Services" className="dropbtn" onClick={toggleMenu}>
                  Services Offered
                </Link>
              </li>
              <li className="dropdown">
                <Link to="/Pricing" className="dropbtn" onClick={toggleMenu}>
                  Pricing
                </Link>
              </li>
              <li className="dropdown">
                <Link to="/contact-us" className="dropbtn" onClick={toggleMenu}>
                  Contact Us
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default HorizontalNavbar;
