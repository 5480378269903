import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getAgentDetailsWithReportCount } from '../services/api';
import * as XLSX from 'xlsx';
import "../css/AgentDashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const generateColor = (index) => {
    const baseColors = [
        "#FF5733", "#33FF57", "#5733FF", "#FF33A1", "#33A1FF", "#A1FF33", "#FF8C00", "#FF4500",
        "#FFD700", "#ADFF2F", "#32CD32", "#008B8B", "#4682B4", "#4B0082", "#8B0000", "#9932CC"
    ];
    return baseColors[index % baseColors.length];
};

const getPersistentColors = (keys, storageKey) => {
    let storedColors = JSON.parse(localStorage.getItem(storageKey)) || {};
    keys.forEach((key, index) => {
        if (!storedColors[key]) {
            storedColors[key] = generateColor(index);
        }
    });
    localStorage.setItem(storageKey, JSON.stringify(storedColors));
    return storedColors;
};

const OwnerDashboard = () => {
    const [agents, setAgents] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], datasets: [{ data: [], backgroundColor: [] }] });
    const [selectedChart, setSelectedChart] = useState("Clients by City");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const chartOptions = ["Clients by City", "Clients by Role", "Clients by Organization", "Clients by Owner"];

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                console.log("fetchAgents is running"); // Add this line

                const storedUserId = localStorage.getItem("userId");
                if (!storedUserId) throw new Error("User ID not found. Please login.");
                
                const response = await getAgentDetailsWithReportCount();
                if (!response.data || response.data.length === 0) {
                    setAgents([]);
                    setChartData({ labels: [], datasets: [{ data: [], backgroundColor: [] }] });
                    return;
                }

                setAgents(response.data);
                updateChartData(response.data, selectedChart);
            } catch (err) {
                alert(err.response?.data?.message || 'An error occurred.');
            }
        };
        fetchAgents();
    }, [selectedChart]);

    const updateChartData = (data, chartType) => {
        let categoryData = {};
        let storageKey = "";
        let dataIndex = 2;

        switch (chartType) {
            case "Clients by Role": dataIndex = 4; storageKey = "roleColors"; break;
            case "Clients by Organization": dataIndex = 3; storageKey = "organizationColors"; break;
            case "Clients by Owner": dataIndex = 5; storageKey = "ownerColors"; break;
            default: storageKey = "cityColors";
        }

        data.forEach(agent => {
            const key = agent[dataIndex];
            categoryData[key] = (categoryData[key] || 0) + 1;
        });

        const sortedData = Object.entries(categoryData).sort((a, b) => b[1] - a[1]);
        const sortedLabels = sortedData.map(item => item[0]);
        const sortedValues = sortedData.map(item => item[1]);

        const colors = getPersistentColors(sortedLabels, storageKey);

        setChartData({
            labels: sortedLabels,
            datasets: [{
                data: sortedValues,
                backgroundColor: sortedLabels.map(key => colors[key])
            }]
        });
    };

    const sortAgents = (key) => {
        let direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        const sortedAgents = [...agents].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setAgents(sortedAgents);
    };

    const downloadExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet([["Name", "Email", "City", "Organization", "Role", "Referred By","Reports Generated"], 
            ...agents.map(agent => [agent[0], agent[1], agent[2], agent[3], agent[4], agent[6],agent[7]])]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Agents");
        XLSX.writeFile(wb, "Agents_List.xlsx");
    };

    return (
        <div className="agents-dashboard-container">
            <h2>Agents List</h2>
            <select className="chart-selector" style={{ fontSize: "1.2em", marginBottom: "20px" }} value={selectedChart} onChange={(e) => setSelectedChart(e.target.value)}>
                {chartOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
            <div className="chart-summary-container" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <table className="summary-table" style={{ borderCollapse: "collapse", marginRight: "20px" }}>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid black", padding: "8px" }}>{selectedChart.split(" by ")[1]}</th>
                            <th style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chartData.labels.map((label, index) => (
                            <tr key={label}>
                                <td style={{ border: "1px solid black", padding: "8px" }}>{label}</td>
                                <td style={{ border: "1px solid black", padding: "8px", textAlign: "right" }}>{chartData.datasets[0].data[index]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="chart-container" style={{ textAlign: "center" }}>
                    {chartData.labels.length > 0 && (
                        <Pie
                            data={chartData}
                            options={{
                                plugins: {
                                    legend: { display: true },
                                    datalabels: { display: false }
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="agents-table-container" style={{ position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
                <button 
                    onClick={downloadExcel} 
                    style={{ 
                        padding: "5px 12px", 
                        fontSize: "1rem", 
                        backgroundColor: "#007bff", 
                        color: "white", 
                        border: "none", 
                        borderRadius: "4px", 
                        cursor: "pointer"
                    }}
            >
                ⬇️ Download
                </button>
            </div>
                <table className="agents-table">
                    <thead>
                        <tr>
                            {["Name", "Email", "City", "Organization", "Role", "Referred By","Reports Generated"].map((header, index) => (
                                <th key={index} onClick={() => sortAgents(index)} style={{ cursor: "pointer" }}>
                                    {header} {sortConfig.key === index ? (sortConfig.direction === 'asc' ? "↑" : "↓") : ""}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {agents.map((agent, index) => (
                            <tr key={index}>
                                <td>{agent[0]}</td>
                                <td>{agent[1]}</td>
                                <td>{agent[2]}</td>
                                <td>{agent[3]}</td>
                                <td>{agent[4]}</td>
                                <td>{agent[5]}</td>
                                <td>{agent[6]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OwnerDashboard;
// In this example, we have created an OwnerDashboard component that displays a list of agents along with a chart showing the distribution of agents based on different categories. The component fetches agent data from an API and allows the user to download the agent list as an Excel file. The user can also sort the agent list based on different columns by clicking on the column headers. The chart type can be changed using a dropdown menu. The chart and agent list are updated based on the selected chart type.

