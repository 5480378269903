import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import HorizontalNavbar from './layout/HorizontalNavbar';
import Login from './components/Login';
import OwnerDashboard from './components/OwnerDashboard';
import AgentDashboard from './components/AgentDashboard';
import ChangePassword from './components/ChangePassword';
import AgentForm from './components/AgentForm';
import BulkAgentForm from './components/BulkAgentForm';
import FileDownload from './components/FileDownload';
import GenerateLink from './components/GenerateLink';
import CustomerForm from './components/CustomerForm';
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import Pricing from './pages/Pricing';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import LastLogin from './components/LastLogin';

// Custom hook to check if the current route is the form route
const useIsFormRoute = () => {
    const location = useLocation();
    return location.pathname.startsWith('/form/');
};

function AppContent() {
    const [userRole, setUserRole] = useState(localStorage.getItem('userRole')); // Initialize from localStorage
    const [userId, setUserId] = useState(localStorage.getItem('userId'));     // Initialize from localStorage
    const [passwordChangeRequired, setPasswordChangeRequired] = useState(localStorage.getItem('passwordChangeRequired') === 'true'); // Initialize from localStorage

    const handleLogout = () => {
        setUserRole(null);
        setUserId(null);
        setPasswordChangeRequired(false);
        localStorage.clear(); // Clear all localStorage items
    };

    const ProtectedRoute = ({ roles, children }) => {
        if (!userRole || !roles.includes(userRole)) { // Check if userRole is null or not in roles
            return <Navigate to="/login" />;
        }
        return children;
    };

    const isFormRoute = useIsFormRoute();

    return (
        <>
            {/* Conditionally render Navbar or HorizontalNavbar */}
            {!isFormRoute && (userRole ? <Navbar userRole={userRole} onLogout={handleLogout} /> : <HorizontalNavbar />)}

            <Routes>
                <Route
                    path="/login"
                    element={
                        <Login
                            setUserRole={setUserRole}
                            setUserId={setUserId}
                            setPasswordChangeRequired={setPasswordChangeRequired}
                        />
                    }
                />

                <Route
                    path="/"
                    element={
                        <div className="main-layout">
                            <div className="main-content">
                                <HomePage />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <div className="main-layout">
                            <div className="main-content">
                                <ContactUs />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/Pricing"
                    element={
                        <div className="main-layout">
                            <div className="main-content">
                                <Pricing />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/Services"
                    element={
                        <div className="main-layout">
                            <div className="main-content">
                                <Services />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/AboutUs"
                    element={
                        <div className="main-layout">
                            <div className="main-content">
                                <AboutUs />
                            </div>
                        </div>
                    }
                />
                <Route
                    path="/ownerdashboard"
                    element={
                        <ProtectedRoute roles={['PRIMARY', 'SECONDARY']}>
                            <OwnerDashboard userId={userId} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/agentdashboard"
                    element={
                        <ProtectedRoute roles={['AGENT']}>
                            <AgentDashboard userId={userId} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/change-password"
                    element={userRole ? <ChangePassword setPasswordChangeRequired={setPasswordChangeRequired} /> : <Navigate to="/login" />}
                />
                <Route
                    path="/AgentForm"
                    element={
                        <ProtectedRoute roles={['PRIMARY']}>
                            <AgentForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/BulkAgentForm"
                    element={
                        <ProtectedRoute roles={['PRIMARY']}>
                            <BulkAgentForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/FileDownload"
                    element={
                        <ProtectedRoute roles={['AGENT']}>
                            <FileDownload />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/GenerateLink"
                    element={
                        <ProtectedRoute roles={['AGENT']}>
                            <GenerateLink />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/last-login"
                    element={
                        <ProtectedRoute roles={['PRIMARY', 'SECONDARY', 'AGENT']}>
                            <LastLogin userId={userId} />
                        </ProtectedRoute>
                    }
                />
                {/* Add CustomerForm Route Here */}
                <Route path="/form/:token" element={<CustomerForm />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            {/* Conditionally render Footer */}
            {!isFormRoute && <Footer />}
        </>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;