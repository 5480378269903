import React, { useState } from 'react';
import { createBulkAgents } from '../services/api'; // Adjust the path as necessary
import '../css/BulkAgentForm.css'; // Import the CSS

const BulkAgentForm = () => {
  const [bulkFile, setBulkFile] = useState(null);

  // Handle bulk registration
  const handleBulkSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', bulkFile);

    // Get ownerId from local storage and append it to the form data
    const ownerId = localStorage.getItem("userId");
    data.append('ownerId', ownerId);

    try {
      await createBulkAgents(data); // API call
      alert('Agents registered successfully!');
      setBulkFile(null);
    } catch (err) {
      console.error(err);
      alert('Failed to register agents. Please try again.');
    }
  };

  // Handle bulk file input change
  const handleBulkFileChange = (e) => {
    setBulkFile(e.target.files[0]);
  };

  return (
    <div className="bulk-agent-form-container"> {/* Container for centering */}
            <div className="bulk-agent-form"> {/* Form styles */}
      <form onSubmit={handleBulkSubmit} encType="multipart/form-data">
        <label>
          Bulk Register Agents:
          <input
            type="file"
            name="bulkFile"
            onChange={handleBulkFileChange}
            accept=".xlsx, .xls"
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
    </div>
  );
};

export default BulkAgentForm;
// In this example, we have created a form component for bulk agent registration. The component allows users to upload an Excel file containing agent details for bulk registration. The form submission triggers an API call to register the agents.