import React, { useState, useEffect } from 'react';
import { generateFormLink } from '../services/api'; // Assuming your api.js is in a 'services' folder
import '../css/GenerateLink.css'; // Import the CSS file

function GenerateLink() {
  const [generatedLink, setGeneratedLink] = useState('');
  const [error, setError] = useState('');
  const [agentId, setAgentId] = useState(null);

  useEffect(() => {
    const storedAgentId = localStorage.getItem('agentId');
    console.log("GenerateLink storedAgentId:", storedAgentId);

    if (storedAgentId) {
      setAgentId(parseInt(storedAgentId, 10));
    } else {
      setError("Agent ID not found in local storage. Please log in.");
    }
  }, []);
  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent React Router from intercepting
    window.location.href = generatedLink; // Force a full page reload
  };
  const handleGenerateLink = async () => {
    setError('');
    setGeneratedLink('');

    if (!agentId) {
      setError('Agent ID not available. Please log in.');
      return;
    }

    try {
      const response = await generateFormLink(`${agentId}`);
      setGeneratedLink(response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError('Failed to generate link. Please try again.');
      }
    }
  };

  return (
    <div className="generate-link-container">
      <div className="generate-link-form">
        <h2>Generate Form Link</h2>

        {error && <p className="error-message">{error}</p>}

        {agentId !== null && (
          <div className="generate-link-group">
            <button onClick={handleGenerateLink}>Generate Link</button>
          </div>
        )}

        {generatedLink && (
          <div className="generate-link-group">
            <h3>Generated Link:</h3>
            <p>
              <a href={generatedLink} target="_blank" rel="noopener noreferrer"
                onClick={handleLinkClick} // Add the onClick handler here

              >
                {generatedLink}
              </a>
            </p>
            <p>Copy this link and send it to your customer.</p>
            <textarea
              readOnly
              value={generatedLink}
              rows={4}
              cols={50}
              onClick={(e) => e.target.select()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default GenerateLink;