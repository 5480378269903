import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api'; // Import your API function
import '../css/CustomerForm.css'; // Import your CSS file
import FamilyInvolvementTranslations from '../sections/FamilyInvolvementTranslations';

const SelectField = ({ name, label, options, value, handleChange }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options).filter(([key]) => key !== 'Select an option').map(([key, optionLabel]) => (
        <option key={key} value={key}>{optionLabel}</option>
      ))}
    </select>
  </div>
);

const FamilyInvolvement = ({ formData, handleInputChange, language, validateSection }) => {
  const t = FamilyInvolvementTranslations[language] || FamilyInvolvementTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSectionFamily = () => {
    const fieldsToValidate = ['decision_maker', 'spouse_involvement', 'children_involvement', 'discussion_frequency', 'family_influence', 'expense_sharing', 'family_savings'];

    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.errors[field] || "This field is required";
      }
      return acc;
    }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    //return true;
  };

  useEffect(() => {
    validateSection(validateSectionFamily);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-family-involvement">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_title}</p>
      <img src="/images/section11_familyinv.jpg" alt="Family" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      {Object.keys(t.questions).map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[field]}
            options={t[field]}
            value={formData[field]}
            handleChange={handleInputChange}
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default FamilyInvolvement;
