import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api'; // Import your API function
import '../css/CustomerForm.css'; // Import your CSS file
import InsuranceCoverageTranslations from '../sections/InsuranceCoverageTranslations';

const SelectField = ({ name, label, options, value, handleChange }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options)
        .filter(([key]) => key !== 'Select an option')
        .map(([key, optionLabel]) => (
          <option key={key} value={key}>{optionLabel}</option>
        ))}
    </select>
  </div>
);

const InsuranceCoverage = ({ formData, handleInputChange, language, validateSection }) => {
  const t = InsuranceCoverageTranslations[language] || InsuranceCoverageTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSectionInsurance = () => {
    const fieldsToValidate = ['health_insurance', 'term_insurance', 'disability_insurance'];
    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.questions[field];
      }
      return acc;
    }, {});

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validateSection(validateSectionInsurance);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-9">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_title}</p>
      <img src="/images/risk_insurance.png" alt="Insurance" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      {['health_insurance', 'term_insurance', 'disability_insurance'].map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[`${field}`]}
            options={t[field]}
            value={formData[field]}
            handleChange={handleInputChange}
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default InsuranceCoverage;
