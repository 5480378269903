const RetirementPlanningTranslations = {
    en: {
      section_description: "The Retirement Planning section is designed to assess your preparedness for retirement. We will gather information on your current retirement savings, expected retirement age and any plans or strategies you have in place to ensure financial security during your post-working years.",
      questions: {
        retirement_age: "26. At what age do you plan to retire?",
        estimated_corpus: "27. How much estimated corpus do you expect to have from PF, PPF, and NPS at the time of retirement?",
        estimated_retirement_need: "28. How much do you estimate you will need for retirement? (Retirement at Age 60 Years)",
        estimated_monthly_expenses: "29. Based on your current household expenses, how much do you estimate would be required per month to maintain at least your current standard of living post-retirement?"
  
      
      },
       retirement_age_options: {
        "Select an option": "Select an option",
        50: 50,
        53: 53,
        55: 55,
        58: 58,
        60: 60,
        65: 65,
      },
      estimated_corpus_options: {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "Less than ₹50,00,000": "Less than ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹1,50,00,000": "₹1,00,00,000 - ₹1,50,00,000",
        "₹1,50,00,000 - ₹2,00,00,000": "₹1,50,00,000 - ₹2,00,00,000",
        "More than ₹2,00,00,000": "More than ₹2,00,00,000",
      },
      estimated_retirement_need_options: {
        "Select an option": "Select an option",
        "Less than ₹50,00,000": "Less than ₹50,00,000",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "2,00,00,000 - ₹5,00,00,000": "₹2,00,00,000 - ₹5,00,00,000",
        "More than ₹5,00,00,000": "More than ₹5,00,00,000",
        "I expect my Children to take care of me post retirement":"I expect my Children to take care of me post retirement"

      },
      estimated_monthly_expenses_options: {
        "Select an option": "Select an option",
        "Less than ₹50,000 per month": "Less than ₹50,000 per month",
        "₹50,000 - ₹1,00,000 per month": "₹50,000 - ₹1,00,000 per month",
        "₹1,00,000 - ₹2,00,000 per month": "₹1,00,000 - ₹2,00,000 per month",
        "₹2,00,000 - ₹5,00,000 per month": "₹2,00,000 - ₹5,00,000 per month",
        "More than ₹5,00,000 per month": "More than ₹5,00,000 per month",
        "I expect my Children to take care of me post-retirement": "I expect my Children to take care of me post-retirement"
      },
      errors: {
        retirement_ageRequired: "Please select your retirement age.",
        estimated_corpusRequired: "Please select the estimated corpus at the time of retirement.",
        estimated_retirement_needRequired: "Please select your estimated retirement need.",
        estimated_monthly_expensesRequired: "Please select your estimated monthly expenses after retirement."
      },
      
    },
    hi: {
      section_description: "सेवानिवृत्ति योजना अनुभाग आपकी सेवानिवृत्ति के लिए तत्परता का मूल्यांकन करने के लिए डिज़ाइन किया गया है। हम आपकी वर्तमान सेवानिवृत्ति बचत, अपेक्षित सेवानिवृत्ति आयु और आपकी वित्तीय सुरक्षा सुनिश्चित करने के लिए बनाई गई किसी भी योजना या रणनीति के बारे में जानकारी एकत्र करेंगे।",
      questions: {
        retirement_age: "26. आप किस उम्र में सेवानिवृत्त होने की योजना बना रहे हैं?",
        estimated_corpus: "27. सेवानिवृत्ति के समय आपको पीएफ, पीपीएफ और एनपीएस से कितना अनुमानित कोष मिलने की उम्मीद है?",
        estimated_retirement_need: "28. आपको सेवानिवृत्ति के लिए कितनी राशि की आवश्यकता होगी? (सेवानिवृत्ति की आयु 60 वर्ष)",
        estimated_monthly_expenses: "29. आपके वर्तमान घरेलू खर्चों के आधार पर, आपको प्रति माह कितनी राशि की आवश्यकता होगी ताकि आप सेवानिवृत्ति के बाद कम से कम अपने वर्तमान जीवन स्तर को बनाए रख सकें?"
      },
      retirement_age_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        50: 50,
        53: 53,
        55: 55,
        58: 58,
        60: 60,
        65: 65,
      },
      estimated_corpus_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं है",
        "Less than ₹50,00,000": "₹50,00,000 से कम",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹1,50,00,000": "₹1,00,00,000 - ₹1,50,00,000",
        "₹1,50,00,000 - ₹2,00,00,000": "₹1,50,00,000 - ₹2,00,00,000",
        "More than ₹2,00,00,000": "₹2,00,00,000 से अधिक",
      },
      estimated_retirement_need_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं है",
        "Less than ₹50,00,000": "₹50,00,000 से कम",
        "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
        "₹1,00,00,000 - ₹2,00,00,000": "₹1,00,00,000 - ₹2,00,00,000",
        "2,00,00,000 - ₹5,00,00,000": "₹2,00,00,000 - ₹5,00,00,000",
        "More than ₹5,00,00,000": "₹5,00,00,000 से अधिक",
        "I expect my Children to take care of me post retirement": "मैं उम्मीद करता/करती हूं कि मेरी सेवानिवृत्ति के बाद मेरे बच्चे मेरी देखभाल करेंगे"
      },
      estimated_monthly_expenses_options: {
        "Select an option": "कृपया एक विकल्प चुनें",
        "Less than ₹50,000 per month": "प्रति माह ₹50,000 से कम",
        "₹50,000 - ₹1,00,000 per month": "प्रति माह ₹50,000 - ₹1,00,000",
        "₹1,00,000 - ₹2,00,000 per month": "प्रति माह ₹1,00,000 - ₹2,00,000",
        "₹2,00,000 - ₹5,00,000 per month": "प्रति माह ₹2,00,000 - ₹5,00,000",
        "More than ₹5,00,000 per month": "प्रति माह ₹5,00,000 से अधिक",
        "I expect my Children to take care of me post-retirement": "मैं उम्मीद करता/करती हूं कि मेरी सेवानिवृत्ति के बाद मेरे बच्चे मेरी देखभाल करेंगे"
      },
      errors: {
        retirement_ageRequired: "कृपया अपनी सेवानिवृत्ति की आयु का चयन करें।",
        estimated_corpusRequired: "कृपया सेवानिवृत्ति के समय अनुमानित कोष का चयन करें।",
        estimated_retirement_needRequired: "कृपया अपनी अनुमानित सेवानिवृत्ति आवश्यकता का चयन करें।",
        estimated_monthly_expensesRequired: "कृपया सेवानिवृत्ति के बाद के लिए मासिक खर्चों का चयन करें।",
      },
      
    },
        
  };
  
  export default RetirementPlanningTranslations;