const SavingsInvestmentsTranslations = {
  en: {
    description: "The Savings and Investment section of this questionnaire is designed to gather detailed information about your current savings and investment strategies.",
    
    questions: {
      savings_investments: "11.What percentage of your income do you typically save or invest?", // English question 11
      equity_investment: "12. What percentage of your portfolio is invested in equity (stocks, mutual funds, etc.)?", // English question 12
      fixed_income_allocation: "13. What percentage of your portfolio is invested in fixed income (bonds, fixed deposits, etc.)?", // English question 13
      num_policies: "14.Have you Invested in Endowment / Moneyback / ULIP Plans? (LIC). If yes, how many Policies do you have?", // English question 14
      premium_range: "15. How much cumulative annual premium do you pay per annum for the above policies?", // English question 15
      ppf_contribution: "16. Do you Invest in PPF? If Yes, What is your Annual PPF Contribution?", // English question 16
      nps_contribution: "17. Do you Invest in NPS? If Yes, What is your Annual NPS Contribution?", // English question 17
    },
    savings_investments_options:  {
        "Select an option": "Select an option",
        "Spend it ALL": "Spend it ALL",
        "Less than 10%": "Less than 10%",
        "10% - 20%": "10% - 20%",
        "20% - 30%": "20% - 30%",
        "30% - 50%": "30% - 50%",
        "More than 50%": "More than 50%"
      
    },
    equity_investment_options:{
      "Select an option": "Select an option",
      "I DO NOT Invest in Stocks or Mutual Funds": "I DO NOT Invest in Stocks or Mutual Funds",
      "10% - 25%": "10% - 25%",
      "25% - 50%": "25% - 50%",
      "50% - 75%": "50% - 75%",
      "More than 75%": "More than 75%"
    },

    fixed_income_allocation_options: {
      "Select an option": "Select an option",
      "I DO NOT Invest in FDs or BONDS": "I DO NOT Invest in FDs or BONDS",
      "10% - 25%": "10% - 25%",
      "25% - 50%": "25% - 50%",
      "50% - 75%": "50% - 75%",
      "More than 75%": "More than 75%"

    },
    num_policies_options: {
      "Select an option": "Select an option",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "More than 5": "More than 5",
      "I DO NOT invest in Endowment or ULIP Plans": "I DO NOT invest in Endowment or ULIP Plans"
    },

    premium_range_options:  {
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
        "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "More than ₹1,00,000": "More than ₹1,00,000"
      
    },
    ppf_contribution_options: {
      
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "₹100,000 - ₹1,50,000": "₹100,000 - ₹1,50,000"
      
    },
    nps_contribution_options: {
      
        "Select an option": "Select an option",
        "Not Applicable": "Not Applicable",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "More than ₹100,000": "More than ₹100,000"
      
    },
    errors: {
      savings_investments: "Please select a valid savings percentage.",
      equity_investment: "Please select a valid equity investment percentage.",
      fixed_income_allocation: "Please select a valid fixed income allocation percentage.",
      num_policies: "Please select a valid number of policies.",
      premium_range: "Please select a valid premium range.",
      ppf_contribution: "Please select a valid PPF contribution amount.",
      nps_contribution: "Please select a valid NPS contribution amount."
    },
  },
  
  hi: {
    description: "इस प्रश्नावली का बचत और निवेश अनुभाग आपकी वर्तमान बचत और निवेश रणनीतियों के बारे में विस्तृत जानकारी एकत्र करने के लिए डिज़ाइन किया गया है।",
    questions: {
        savings_investments: "11. आप आमतौर पर अपनी आय का कितना प्रतिशत बचाते या निवेश करते हैं?", // Hindi question 11
        equity_investment: "12. आपके पोर्टफोलियो का कितना प्रतिशत इक्विटी (स्टॉक्स, म्यूचुअल फंड्स आदि) में निवेश किया गया है?", // Hindi question 12
        fixed_income_allocation: "13. आपके पोर्टफोलियो का कितना प्रतिशत फिक्स्ड इनकम (बॉन्ड्स, फिक्स्ड डिपॉजिट्स आदि) में निवेश किया गया है?", // Hindi question 13
        num_policies: "14. क्या आपने एंडोमेंट / मनीबैक / यूलिप प्लान (LIC) में निवेश किया है? यदि हां, तो आपके पास कितनी पॉलिसियां हैं?", // Hindi question 14
        premium_range: "15. उपरोक्त पॉलिसियों के लिए आप प्रति वर्ष कितना कुल वार्षिक प्रीमियम भुगतान करते हैं?", // Hindi question 15
        ppf_contribution: "16. क्या आप पीपीएफ में निवेश करते हैं? यदि हां, तो आपका वार्षिक पीपीएफ योगदान कितना है?", // Hindi question 16
        nps_contribution: "17. क्या आप एनपीएस में निवेश करते हैं? यदि हां, तो आपका वार्षिक एनपीएस योगदान कितना है?", // Hindi question 17
    },
    savings_investments_options: {
        "Select an option": "एक विकल्प चुनें",
        "Spend it ALL": "मैं सारा खर्च करता हूँ",
        "Less than 10%": "10% से कम",
        "10% - 20%": "10% - 20%",
        "20% - 30%": "20% - 30%",
        "30% - 50%": "30% - 50%",
        "More than 50%": "50% से अधिक",
    },
    equity_investment_options: {
        "Select an option": "एक विकल्प चुनें",
        "I DO NOT Invest in Stocks or Mutual Funds": "मैं स्टॉक या म्यूचुअल फंड में निवेश नहीं करता हूँ",
        "10% - 25%": "10% - 25%",
        "25% - 50%": "25% - 50%",
        "50% - 75%": "50% - 75%",
        "More than 75%": "75% से अधिक",
    },
    fixed_income_allocation_options: {
        "Select an option": "एक विकल्प चुनें",
        "I DO NOT Invest in FDs or BONDS": "मैं एफडी या बॉन्ड में निवेश नहीं करता हूं",
        "10% - 25%": "10% - 25%",
        "25% - 50%": "25% - 50%",
        "50% - 75%": "50% - 75%",
        "More than 75%": "75% से अधिक",
    },
    num_policies_options: {
        "Select an option": "एक विकल्प चुनें",
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "More than 5": "5 से अधिक",
        "I DO NOT invest in Endowment or ULIP Plans": "मैं एंडोमेंट या यूलिप योजनाओं में निवेश नहीं करता हूँ",
    },
    premium_range_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
        "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "More than ₹1,00,000": "₹1,00,000 से अधिक",
    },
    ppf_contribution_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "₹100,000 - ₹1,50,000": "₹1,00,000 - ₹1,50,000",
    },
    nps_contribution_options: {
        "Select an option": "एक विकल्प चुनें",
        "Not Applicable": "लागू नहीं",
        "₹20,000 - ₹50,000": "₹20,000 - ₹50,000",
        "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
        "More than ₹100,000": "₹1,00,000 से अधिक",
    },
    errors: {
        savings_investments: "कृपया एक वैध बचत प्रतिशत चुनें।",
        equity_investment: "कृपया एक वैध इक्विटी निवेश प्रतिशत चुनें।",
        fixed_income_allocation: "कृपया एक वैध फिक्स्ड इनकम आवंटन प्रतिशत चुनें।",
        num_policies: "कृपया पॉलिसियों की एक वैध संख्या चुनें।",
        premium_range: "कृपया एक वैध प्रीमियम सीमा चुनें।",
        ppf_contribution: "कृपया एक वैध पीपीएफ योगदान राशि चुनें।",
        nps_contribution: "कृपया एक वैध एनपीएस योगदान राशि चुनें।",
    },
},

 
};

export default SavingsInvestmentsTranslations;