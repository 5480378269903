// FormHeader.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { submitFormData } from '../services/api'; // Import your API function
import '../css/CustomerForm.css'; // Import your CSS file
{/* Section 0: Form Header */}

function FormHeader({ step, getSectionTitle, getSectionTitleHindi, language }) {
    const translations = {
        en: {
            formTitle: "Understanding your Financial Planning Requirements",
            sectionIndicator: `Section ${step} of 14 - ${getSectionTitle()}`,
            formDescription: "This comprehensive Financial Planning Questionnaire is designed to gather detailed information about your current financial situation, spending habits, and financial goals. Your responses will help us create a tailored financial plan that aligns with your unique needs. Please answer as accurately as possible. Note: Your data is safe with us and will remain confidential.",
            
        },
        hi: {
            formTitle: "आपकी वित्तीय योजना आवश्यकताओं को समझना",
            sectionIndicator: `अनुभाग ${step} में से 14 - ${getSectionTitleHindi()}`,
            formDescription: "यह व्यापक वित्तीय योजना प्रश्नावली आपके मौजूदा वित्तीय स्थिति, खर्च की आदतों और वित्तीय लक्ष्यों के बारे में विस्तृत जानकारी एकत्र करने के लिए डिज़ाइन की गई है। आपके उत्तर हमें एक अनुकूलित वित्तीय योजना बनाने में मदद करेंगे जो आपके अद्वितीय आवश्यकताओं के अनुरूप हो। कृपया यथासंभव सटीक उत्तर दें। नोट: आपका डेटा हमारे पास सुरक्षित है और गोपनीय रहेगा।",
        },
    };

    const t = translations[language] || translations.en; // Fallback to English

    return (
        <>
            <h1 id="formTitle">{t.formTitle}</h1>
            <div id="FinPlanImagePlaceholder">
                <img src="/images/section1_finplan.png" alt="Financial Planning" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
            </div>
             <p id="formDescription" style={{ color: 'rgb(112, 16, 99)' }}>
                {t.formDescription}
            </p>
            <h2 id="sectionIndicator">{t.sectionIndicator}</h2>
        </>
    );
}

export default FormHeader;