import React, { useState, useEffect } from 'react';
import { submitFormData } from '../services/api';
import '../css/CustomerForm.css';
import ElderlyDependentsTransactions from '../sections/ElderlyDependentsTransactions';

const SelectField = ({ name, label, options, value, handleChange, disabled }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="" disabled hidden>
        Select an option
      </option>
      {Object.entries(options ?? {}).map(([key, optionLabel]) => (
        <option key={key} value={key}>
          {optionLabel}
        </option>
      ))}
    </select>
  </div>
);

const ElderlyDependents = ({ formData, handleInputChange, language, validateSection }) => {
  const t = ElderlyDependentsTransactions[language] || ElderlyDependentsTransactions.en;
  const [errors, setErrors] = useState({});
  const [areDependentsDisabled, setAreDependentsDisabled] = useState(formData.elderly_dependents === 'No Elderly Dependents');

  const validateSectionElderly = () => {
    const fieldsToValidate = ['elderly_dependents', 'health_insurance', 'independent_income', 'ailments', 'healthcare_plan'];
    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.errors?.[field] || 'This field is required';
      }
      return acc;
    }, {});
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (formData.elderly_dependents === 'No Elderly Dependents') {
      setAreDependentsDisabled(true);
      // Auto-set these fields to "Not Applicable" and disable them
      ['health_insurance', 'independent_income', 'ailments', 'healthcare_plan'].forEach(field => {
        handleInputChange({ target: { name: field, value: 'Not Applicable' } });
      });
    } else {
      setAreDependentsDisabled(false);
    }
    validateSection(validateSectionElderly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.elderly_dependents, validateSection, handleInputChange]);

  return (
    <div className="form-page" id="section-elderly-dependents">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.sectionDescription}</p>
      <img
        src="/images/section13_ed.jpg"
        alt="Elderly Dependents"
        style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }}
      />

      {Object.keys(t.questions).map((field) => {
        let fieldOptions = t[field];

        // For dependent fields, if elderly_dependents is NOT "No Elderly Dependents",
        // filter out "Not Applicable" from the options.
        if (
          ["health_insurance", "independent_income", "ailments", "healthcare_plan"].includes(field) &&
          formData.elderly_dependents !== 'No Elderly Dependents'
        ) {
          fieldOptions = Object.fromEntries(
            Object.entries(fieldOptions).filter(([key]) => key !== "Not Applicable")
          );
        }

        return (
          <div key={field} style={{ marginBottom: '10px' }}>
            <SelectField
              name={field}
              label={t.questions[field]}
              options={fieldOptions}
              value={formData[field]}
              handleChange={handleInputChange}
              disabled={areDependentsDisabled && field !== 'elderly_dependents'}
            />
            {errors[field] && <p className="error-message">{errors[field]}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default ElderlyDependents;
