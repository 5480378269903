const RiskProfileTranslations = {
    en: {
        
        section_title: "Assess your tolerance for financial risk, your investment time horizon, and how you approach high-risk investments.",
        questions: {
            risk_tolerance_label: "48. How would you describe your risk tolerance when it comes to investments?",
            portfolio_reaction_label: "49. How would you react if your investment portfolio dropped by 20% in a short period?",
            holding_period_label: "50. How long are you willing to stay invested in an underperforming asset before considering selling it?",
            new_investment_products_label: "51. How do you feel about investing in new or untested financial products (e.g., cryptocurrencies, start-ups)?",
            investment_priority_label: "52. What would you prioritize when selecting an investment?",
            high_risk_allocation_label: "53. How much of your total investment portfolio would you allocate to high-risk investments (e.g., small-cap stocks, speculative assets)?",
            liquidity_importance_label: "54. How important is the ability to quickly access your invested funds?",
        },
        errors: {
            risk_tolerance_label: "Please select how you would describe your risk tolerance when it comes to investments.",
            portfolio_reaction_label: "Please select how you would react if your investment portfolio dropped by 20% in a short period.",
            holding_period_label: "Please enter how long you are willing to stay invested in an underperforming asset before considering selling it.",
            new_investment_products_label: "Please indicate how you feel about investing in new or untested financial products.",
            investment_priority_label: "Please select what you would prioritize when selecting an investment.",
            high_risk_allocation_label: "Please enter how much of your total investment portfolio you would allocate to high-risk investments.",
            liquidity_importance_label: "Please indicate how important the ability to quickly access your invested funds is to you."
          },
          
        risk_tolerance:
        {
            "Select an option": "Select an option",
            "Very Conservative (Prefer low-risk investments like fixed deposits)": "Very Conservative (Prefer low-risk investments like fixed deposits)",
            "Conservative (Prefer balanced investments with some risk)": "Conservative (Prefer balanced investments with some risk)",
            "Moderate (Comfortable with moderate risk for better returns)": "Moderate (Comfortable with moderate risk for better returns)",
            "Aggressive (Willing to take high risks for potentially higher returns)": "Aggressive (Willing to take high risks for potentially higher returns)",
            "Very Aggressive (Focus on high-risk, high-reward investments)": "Very Aggressive (Focus on high-risk, high-reward investments)"
        },
        portfolio_reaction:
        {
            "Select an option": "Select an option",
            "Sell all investments to avoid further loss": "Sell all investments to avoid further loss",
            "Sell some investments and hold the rest": "Sell some investments and hold the rest",
            "Hold all investments and wait for recovery": "Hold all investments and wait for recovery",
            "Buy more investments to take advantage of lower prices": "Buy more investments to take advantage of lower prices"
        },
        holding_period:
        {
            "Select an option": "Select an option",
            "Less than 1 year": "Less than 1 year",
            "1-2 years": "1-2 years",
            "2-3 years": "2-3 years",
            "3-5 years": "3-5 years",
            "More than 5 years": "More than 5 years"
        },
        new_investment_products:
        {
                "Select an option": "Select an option",
                "Very Uncomfortable (I would avoid them completely)": "Very Uncomfortable (I would avoid them completely)",
                "Uncomfortable (I might invest a small amount)": "Uncomfortable (I might invest a small amount)",
                "Neutral (I would consider them as part of a diversified portfolio)": "Neutral (I would consider them as part of a diversified portfolio)",
                "Comfortable (I would allocate a significant portion)": "Comfortable (I would allocate a significant portion)",
                "Very Comfortable (I am eager to invest in them)": "Very Comfortable (I am eager to invest in them)"
                           
        },

        investment_priority:
        {
       
                "Select an option": "Select an option",
                "Capital preservation (avoiding losses)": "Capital preservation (avoiding losses)",
                "Growth potential (long-term capital appreciation)": "Growth potential (long-term capital appreciation)",
                "High returns despite higher risks": "High returns despite higher risks"
                      
        },
        high_risk_allocation:{

                "Select an option": "Select an option",
                "I don't invest in Small Caps or speculative instruments like Futures & Options Trading": "I don't invest in Small Caps or speculative instruments like Futures & Options Trading",
                "Less than 10%": "Less than 10%",
                "10% - 20%": "10% - 20%",
                "20% - 30%": "20% - 30%",
                "30% - 50%": "30% - 50%",
                "More than 50%": "More than 50%"
                         
        },
        liquidity_importance:
        {
           
                "Select an option": "Select an option",
                "Very Important (I need liquidity at all times)": "Very Important (I need liquidity at all times)",
                "Important (I prefer some liquidity)": "Important (I prefer some liquidity)",
                "Neutral (I’m okay with moderate liquidity)": "Neutral (I’m okay with moderate liquidity)",
                "Less Important (I can wait for long-term gains)": "Less Important (I can wait for long-term gains)",
                "Not Important (I’m focused on long-term investments)": "Not Important (I’m focused on long-term investments)"
                         
        },



    },

    hi: {
        section_title: "वित्तीय जोखिम के प्रति आपकी सहनशीलता, आपका निवेश समय क्षितिज और उच्च जोखिम वाले निवेशों के प्रति आपका दृष्टिकोण आकलन करें।",
        questions: {
            risk_tolerance_label: "48. निवेश के संदर्भ में आप अपनी जोखिम सहनशीलता को कैसे वर्णित करेंगे?",
            portfolio_reaction_label: "49. यदि आपका निवेश पोर्टफोलियो कम समय में 20% गिर जाए, तो आप कैसे प्रतिक्रिया देंगे?",
            holding_period_label: "50. किसी कम प्रदर्शन करने वाली संपत्ति को बेचने पर विचार करने से पहले आप उसमें कितने समय तक निवेश करने को तैयार हैं?",
            new_investment_products_label: "51. नए या अप्रमाणित वित्तीय उत्पादों (जैसे, क्रिप्टोकरेंसी, स्टार्ट-अप) में निवेश करने के बारे में आप कैसा महसूस करते हैं?",
            investment_priority_label: "52. निवेश चुनते समय आप किसे प्राथमिकता देंगे?",
            high_risk_allocation_label: "53. आप अपने कुल निवेश पोर्टफोलियो का कितना हिस्सा उच्च जोखिम वाले निवेशों (जैसे, छोटे-कैप स्टॉक्स, सट्टा संपत्तियां) में आवंटित करेंगे?",
            liquidity_importance_label: "54. आपके लिए निवेशित धन को जल्दी से एक्सेस करने की क्षमता कितनी महत्वपूर्ण है?",
        },
        errors: {
            risk_tolerance_label: "कृपया चुनें कि निवेश के मामले में आप अपनी जोखिम सहनशीलता को कैसे वर्णित करेंगे।",
            portfolio_reaction_label: "कृपया चुनें कि यदि आपका निवेश पोर्टफोलियो कम समय में 20% गिर जाए तो आपकी प्रतिक्रिया क्या होगी।",
            holding_period_label: "कृपया दर्ज करें कि आप किसी खराब प्रदर्शन करने वाली संपत्ति में कितना समय निवेशित रहने के लिए तैयार हैं, इससे पहले कि आप इसे बेचने पर विचार करें।",
            new_investment_products_label: "कृपया संकेत दें कि नए या अपरिक्षित वित्तीय उत्पादों (जैसे क्रिप्टोकरेंसी, स्टार्ट-अप्स) में निवेश करने के बारे में आपकी क्या राय है।",
            investment_priority_label: "कृपया चुनें कि निवेश का चयन करते समय आप किसे प्राथमिकता देंगे।",
            high_risk_allocation_label: "कृपया दर्ज करें कि आप अपने कुल निवेश पोर्टफोलियो का कितना हिस्सा उच्च-जोखिम वाले निवेशों (जैसे स्मॉल-कैप स्टॉक्स, सट्टा संपत्तियां) में आवंटित करना चाहेंगे।",
            liquidity_importance_label: "कृपया संकेत दें कि आपके लिए अपने निवेशित धन को जल्दी एक्सेस करने की क्षमता कितनी महत्वपूर्ण है।"
          },
          
        risk_tolerance: {
            "Select an option": "एक विकल्प चुनें",
            "Very Conservative (Prefer low-risk investments like fixed deposits)": "बहुत रूढ़िवादी (कम जोखिम वाले निवेश जैसे फिक्स्ड डिपॉजिट को प्राथमिकता दें)",
            "Conservative (Prefer balanced investments with some risk)": "रूढ़िवादी (कुछ जोखिम के साथ संतुलित निवेश को प्राथमिकता दें)",
            "Moderate (Comfortable with moderate risk for better returns)": "मध्यम (बेहतर रिटर्न के लिए मध्यम जोखिम के साथ सहज)",
            "Aggressive (Willing to take high risks for potentially higher returns)": "आक्रामक (संभावित उच्च रिटर्न के लिए उच्च जोखिम लेने को तैयार)",
            "Very Aggressive (Focus on high-risk, high-reward investments)": "बहुत आक्रामक (उच्च जोखिम, उच्च इनाम वाले निवेशों पर ध्यान केंद्रित करें)",
        },
        portfolio_reaction: {
            "Select an option": "एक विकल्प चुनें",
            "Sell all investments to avoid further loss": "आगे के नुकसान से बचने के लिए सभी निवेश बेच दें",
            "Sell some investments and hold the rest": "कुछ निवेश बेचें और बाकी को होल्ड करें",
            "Hold all investments and wait for recovery": "सभी निवेशों को होल्ड करें और रिकवरी का इंतजार करें",
            "Buy more investments to take advantage of lower prices": "कम कीमतों का फायदा उठाने के लिए और निवेश खरीदें",
        },
        holding_period: {
            "Select an option": "एक विकल्प चुनें",
            "Less than 1 year": "1 साल से कम",
            "1-2 years": "1-2 साल",
            "2-3 years": "2-3 साल",
            "3-5 years": "3-5 साल",
            "More than 5 years": "5 साल से अधिक",
        },
        new_investment_products: {
            "Select an option": "एक विकल्प चुनें",
            "Very Uncomfortable (I would avoid them completely)": "बहुत असहज (मैं उन्हें पूरी तरह से टालूंगा)",
            "Uncomfortable (I might invest a small amount)": "असहज (मैं थोड़ी राशि निवेश कर सकता हूं)",
            "Neutral (I would consider them as part of a diversified portfolio)": "तटस्थ (मैं उन्हें विविध पोर्टफोलियो के हिस्से के रूप में मानूंगा)",
            "Comfortable (I would allocate a significant portion)": "सहज (मैं एक महत्वपूर्ण हिस्सा आवंटित करूंगा)",
            "Very Comfortable (I am eager to invest in them)": "बहुत सहज (मैं उनमें निवेश करने के लिए उत्सुक हूं)",
        },
        investment_priority: {
            "Select an option": "एक विकल्प चुनें",
            "Capital preservation (avoiding losses)": "पूंजी संरक्षण (नुकसान से बचना)",
            "Growth potential (long-term capital appreciation)": "विकास क्षमता (दीर्घकालिक पूंजी प्रशंसा)",
            "High returns despite higher risks": "उच्च जोखिम के बावजूद उच्च रिटर्न",
        },
        high_risk_allocation: {
            "Select an option": "एक विकल्प चुनें",
            "I don't invest in Small Caps or speculative instruments like Futures & Options Trading": "मैं स्मॉल कैप्स या सट्टा उपकरणों (जैसे फ्यूचर्स और ऑप्शन ट्रेडिंग) में निवेश नहीं करता",
            "Less than 10%": "10% से कम",
            "10% - 20%": "10% - 20%",
            "20% - 30%": "20% - 30%",
            "30% - 50%": "30% - 50%",
            "More than 50%": "50% से अधिक",
        },
        liquidity_importance: {
            "Select an option": "एक विकल्प चुनें",
            "Very Important (I need liquidity at all times)": "बहुत महत्वपूर्ण (मुझे हर समय तरलता की आवश्यकता है)",
            "Important (I prefer some liquidity)": "महत्वपूर्ण (मैं कुछ तरलता पसंद करता हूं)",
            "Neutral (I’m okay with moderate liquidity)": "तटस्थ (मैं मध्यम तरलता के साथ ठीक हूं)",
            "Less Important (I can wait for long-term gains)": "कम महत्वपूर्ण (मैं दीर्घकालिक लाभ के लिए इंतजार कर सकता हूं)",
            "Not Important (I’m focused on long-term investments)": "महत्वपूर्ण नहीं (मैं दीर्घकालिक निवेश पर ध्यान केंद्रित करता हूं)",
        },
    },

};
export default RiskProfileTranslations;