// HouseholdIncomeTranslations.js
const HouseholdIncomeTranslations = {
    en: {
        income: { // Added income object
            description: "This section aims to capture details about your primary and secondary sources of income, including any irregular income sources.",
            income_level_label: "1. What is your current monthly household income? (Post Tax. Include spouse income if applicable)",
            income_level_select: "Select Income Range",
            income_level_ranges: {
                "Less than ₹50,000": "Less than ₹50,000",
                "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
                "₹1,00,000 - ₹1,50,000": "₹1,00,000 - ₹1,50,000",
                "₹1,50,000 - ₹2,00,000": "₹1,50,000 - ₹2,00,000",
                "₹2,00,000 - ₹3,00,000": "₹2,00,000 - ₹3,00,000",
                "₹3,00,000 - ₹4,00,000": "₹3,00,000 - ₹4,00,000",
                "₹4,00,000 - ₹5,00,000": "₹4,00,000 - ₹5,00,000",
                "More than ₹5,00,000": "More than ₹5,00,000",
            },
            
            income_sources_label: "2. What are your primary sources of income? (Select all that apply)",
            income_sources_options: {
                "Salary": "Salary",
                "Business Income": "Business Income",
                "Rental Income": "Rental Income",
                "Investment Income (Interest, Dividends etc.)": "Investment Income (Interest, Dividends etc.)",
                "Pension / Retirement Benefits": "Pension / Retirement Benefits",
              },
              
        },
        errors: {
            income_level_required: "Please select a household income range.",
            income_sources_required: "Please select at least one income source."
        },
    },
    hi: {
      income: { // Added income object
          description: "यह खंड आपकी प्राथमिक और माध्यमिक आय स्रोतों के बारे में विवरण एकत्र करता है, जिसमें कोई अनियमित आय स्रोत भी शामिल हैं।",
          income_level_label: "1. आपकी वर्तमान मासिक घरेलू आय कितनी है? (टैक्स के बाद। यदि लागू हो तो पति/पत्नी की आय शामिल करें)",
          income_level_select: "आय सीमा चुनें",
          income_level_ranges: {
              "Less than ₹50,000": "₹50,000 से कम",
              "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
              "₹1,00,000 - ₹1,50,000": "₹1,00,000 - ₹1,50,000",
              "₹1,50,000 - ₹2,00,000": "₹1,50,000 - ₹2,00,000",
              "₹2,00,000 - ₹3,00,000": "₹2,00,000 - ₹3,00,000",
              "₹3,00,000 - ₹4,00,000": "₹3,00,000 - ₹4,00,000",
              "₹4,00,000 - ₹5,00,000": "₹4,00,000 - ₹5,00,000",
              "More than ₹5,00,000": "₹5,00,000 से अधिक",
          },
          income_sources_label: "2. आपके प्राथमिक आय स्रोत क्या हैं? (सभी लागू विकल्प चुनें)",
          income_sources_options: {
              "Salary": "वेतन",
              "Business Income": "व्यवसाय आय",
              "Rental Income": "किराया आय",
              "Investment Income (Interest, Dividends etc.)": "निवेश आय (ब्याज, लाभांश आदि)",
              "Pension / Retirement Benefits": "पेंशन / सेवानिवृत्ति लाभ",
          },
      },
      errors: {
          income_level_required: "कृपया घरेलू आय सीमा चुनें।",
          income_sources_required: "कृपया कम से कम एक आय स्रोत चुनें।"
      },
  },
      
};

export default HouseholdIncomeTranslations;