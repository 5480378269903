import React, { useEffect } from "react";
import "../css/Services.css"; // Updated CSS import

const Services = () => {
  useEffect(() => {
    document.title = "AdvisorMitra.com - Services Offered"; // Set the page title
    console.log("Page title set to: AdvisorMitra.com - Services Offered");

    return () => {
      console.log("Services component unmounted");
    };
  }, []);

  return (
    <div className="services-offered">
      <div className="services-container">
        <h1>Services Offered</h1>

        <h2>Detailed FinWell Report</h2>
        <h3>How Does It Work</h3>

        <ol>
          <li><strong>Step 1: Collect Data</strong> – Easily collect necessary data from clients.</li>
          <li><strong>Step 2: Process Data</strong> – System processes data and generates insights.</li>
          <li><strong>Step 3: Generate FinWell Report</strong> – Show the client’s financial report with insights.</li>
          <li><strong>Step 4: Convert and Upsell</strong> – Use the insights to increase conversions and ticket size.</li>
        </ol>

        <h1>(Coming Soon!!)</h1>

        <ul>
          <li><b>Term Insurance Requirement Analysis</b></li>
          <li><b>Health Insurance Requirement Analysis</b></li>
          <li><b>Children’s Higher Education Planning</b></li>
          <li><b>Children’s Wedding Planning</b></li>
          <li><b>Retirement Planning</b></li>
        </ul>

        <h2><b>And Much-Much MORE!!!</b></h2>
      </div>
    </div>
  );
};

export default Services;
