const ElderlyDependentsTransactions = {
    en: {
        section_title: "Examine your use of credit, including credit card usage, personal loans, and your approach to managing debt. Delve into your spending behavior, including how you prioritize spending, handle non essential purchases, and manage unexpected windfalls.",
        questions: {
        elderly_dependents: "76. Do you have any elderly dependents, such as parents or in-laws, who rely on you for financial support?",
        health_insurance: "77. Do your elderly dependents have health insurance coverage?",
        independent_income: "78. Do your elderly dependents have any independent sources of income (e.g., pension, rental income, investments)?",
        ailments: "79. Are your elderly dependents suffering from any major ailments or chronic conditions that require ongoing medical care?",
        healthcare_plan: "80. How do you plan to cover the healthcare expenses of your elderly dependents?",
        },
        errors:{
                elderly_dependents: "Please select an option for elderly dependents.",
                health_insurance: "Please select an option for health insurance coverage for your elderly dependents.",
                independent_income: "Please select if your elderly dependents have any independent sources of income.",
                ailments: "Please select if your elderly dependents suffer from any major ailments or chronic conditions.",
                healthcare_plan: "Please select how you plan to cover the healthcare expenses of your elderly dependents."
              },
        
        
        
        elderly_dependents: {
            "Select an option": "Select an option",
            "Yes, one parent": "Yes, one parent",
            "Yes, both parents": "Yes, both parents",
            "Yes, in-laws": "Yes, in-laws",
            "Yes, parents and in-laws": "Yes, parents and in-laws",
            "Yes, other elderly relatives": "Yes, other elderly relatives",
            "No Elderly Dependents": "No Elderly Dependents"
          },
          health_insurance: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Yes, fully covered": "Yes, fully covered",
            "Yes, partially covered": "Yes, partially covered",
            "No, they do not have any health insurance": "No, they do not have any health insurance",
            "Not Sure": "Not Sure"
          },
          independent_income: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Yes, sufficient to cover their needs": "Yes, sufficient to cover their needs",
            "Yes, but not sufficient to cover all their needs": "Yes, but not sufficient to cover all their needs",
            "No, they do not have any independent income": "No, they do not have any independent income",
            "Not Sure": "Not Sure"
          },
          ailments: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Yes, a major ailment requiring frequent care (e.g., Cancer, Heart Ailments, any major organ disorders)": "Yes, a major ailment requiring frequent care (e.g., Cancer, Heart Ailments, any major organ disorders)",
            "Yes, a chronic condition requiring regular treatment (e.g., Diabetes, Hypertension, Thyroid issues, Cataract, etc.)": "Yes, a chronic condition requiring regular treatment (e.g., Diabetes, Hypertension, Thyroid issues, Cataract, etc.)",
            "No, they are generally healthy": "No, they are generally healthy"
          },
          
          healthcare_plan: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Through their health insurance": "Through their health insurance",
            "Out of pocket from my savings": "Out of pocket from my savings",
            "A combination of insurance and personal funds": "A combination of insurance and personal funds",
            "I have not yet planned for this": "I have not yet planned for this"
          },
                   
    },

     hi: {
      section_title: "अपने क्रेडिट के उपयोग की जांच करें, जिसमें क्रेडिट कार्ड का उपयोग, व्यक्तिगत ऋण और ऋण प्रबंधन का तरीका शामिल है। अपने खर्च करने के व्यवहार का विश्लेषण करें, जिसमें यह शामिल है कि आप खर्च को कैसे प्राथमिकता देते हैं, गैर-आवश्यक खरीदारी को कैसे संभालते हैं, और अप्रत्याशित आय को कैसे प्रबंधित करते हैं।",
      questions: {
          elderly_dependents: "76. क्या आपके पास कोई वृद्ध आश्रित हैं, जैसे माता-पिता या ससुराल वाले, जो आप पर वित्तीय सहायता के लिए निर्भर हैं?",
          health_insurance: "77. क्या आपके वृद्ध आश्रितों के पास स्वास्थ्य बीमा कवरेज है?",
          independent_income: "78. क्या आपके वृद्ध आश्रितों के पास कोई स्वतंत्र आय स्रोत हैं (जैसे, पेंशन, किराये की आय, निवेश)?",
          ailments: "79. क्या आपके वृद्ध आश्रित किसी गंभीर बीमारी या पुरानी स्थिति से पीड़ित हैं जिसके लिए निरंतर चिकित्सा देखभाल की आवश्यकता है?",
          healthcare_plan: "80. आप अपने वृद्ध आश्रितों के स्वास्थ्य देखभाल खर्चों को कैसे कवर करने की योजना बना रहे हैं?",
      },
      errors: {
        elderly_dependents: "कृपया अपने बुजुर्ग आश्रितों के लिए एक विकल्प चुनें।",
        health_insurance: "कृपया अपने बुजुर्ग आश्रितों के स्वास्थ्य बीमा कवरेज के लिए एक विकल्प चुनें।",
        independent_income: "कृपया चुनें कि क्या आपके बुजुर्ग आश्रितों के पास कोई स्वतंत्र आय स्रोत है।",
        ailments: "कृपया चुनें कि क्या आपके बुजुर्ग आश्रित किसी गंभीर बीमारी या पुरानी स्थिति से पीड़ित हैं।",
        healthcare_plan: "कृपया चुनें कि आप अपने बुजुर्ग आश्रितों के स्वास्थ्य देखभाल खर्च को कैसे कवर करने की योजना बना रहे हैं।"
      },
      
      elderly_dependents: {
          "Select an option": "एक विकल्प चुनें",
          "Yes, one parent": "हां, एक माता-पिता",
          "Yes, both parents": "हां, दोनों माता-पिता",
          "Yes, in-laws": "हां, ससुराल वाले",
          "Yes, parents and in-laws": "हां, माता-पिता और ससुराल वाले",
          "Yes, other elderly relatives": "हां, अन्य वृद्ध रिश्तेदार",
          "No Elderly Dependents": "कोई वृद्ध आश्रित नहीं"
      },
      health_insurance: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Yes, fully covered": "हां, पूरी तरह से कवर",
          "Yes, partially covered": "हां, आंशिक रूप से कवर",
          "No, they do not have any health insurance": "नहीं, उनके पास कोई स्वास्थ्य बीमा नहीं है",
          "Not Sure": "निश्चित नहीं"
      },
      independent_income: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Yes, sufficient to cover their needs": "हां, उनकी जरूरतों को पूरा करने के लिए पर्याप्त",
          "Yes, but not sufficient to cover all their needs": "हां, लेकिन उनकी सभी जरूरतों को पूरा करने के लिए पर्याप्त नहीं",
          "No, they do not have any independent income": "नहीं, उनके पास कोई स्वतंत्र आय नहीं है",
          "Not Sure": "निश्चित नहीं"
      },
      ailments: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Yes, a major ailment requiring frequent care (e.g., Cancer, Heart Ailments, any major organ disorders)": "हां, एक गंभीर बीमारी जिसके लिए लगातार देखभाल की आवश्यकता है (जैसे, कैंसर, हृदय रोग, किसी प्रमुख अंग विकार)",
          "Yes, a chronic condition requiring regular treatment (e.g., Diabetes, Hypertension, Thyroid issues, Cataract, etc.)": "हां, एक पुरानी स्थिति जिसके लिए नियमित उपचार की आवश्यकता है (जैसे, मधुमेह, उच्च रक्तचाप, थायराइड समस्याएं, मोतियाबिंद, आदि)",
          "No, they are generally healthy": "नहीं, वे आम तौर पर स्वस्थ हैं"
      },
      healthcare_plan: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Through their health insurance": "उनके स्वास्थ्य बीमा के माध्यम से",
          "Out of pocket from my savings": "मेरी बचत से खर्च करके",
          "A combination of insurance and personal funds": "बीमा और व्यक्तिगत धन का संयोजन",
          "I have not yet planned for this": "मैंने अभी तक इसकी योजना नहीं बनाई है"
      }
  },
};

export default ElderlyDependentsTransactions;