const HouseholdExpensesTranslations = {
    en: {
        description: "This section gathers information about your monthly household expenses, helping to understand your spending patterns.",
        questions: {
            monthly_expenses_label: "3. What is your average monthly household expenses? (Please include all mandatory payments like EMIs, taxes etc)",
            mandatory_expenses_label: "4. What percentage of your monthly household income goes towards mandatory expenses (Rent/Home Loan EMIs, Children’s Education, Car Loan EMI, etc.)?",
            discretionary_expenses_label: "5. What percentage of your monthly household income is allocated to discretionary spending (dining out, entertainment, vacations, luxury items)?",
            rent_response_label: "6. How much do you spend on rent or home loan EMI per month?",
            education_expenses_label: "7. How much do you spend on your children’s education per month?",
            car_loan_emi_label: "8. How much do you spend on car loan EMI per month?",
            emergency_fund_label: "9. Do you have an emergency fund?",
            emergency_fund_coverage_label: "10. How many months of expenses can your emergency fund cover?",
        },
        monthly_expenses_options: {
            "Select an option": "Select an option",
            "Less than ₹30,000": "Less than ₹30,000",
            "₹30,000 - ₹50,000": "₹30,000 - ₹50,000",
            "₹50,000 - ₹75,000": "₹50,000 - ₹75,000",
            "₹75,000 - ₹1,00,000": "₹75,000 - ₹1,00,000",
            "₹1,00,000 - ₹1,25,000": "₹1,00,000 - ₹1,25,000",
            "₹1,25,000 - ₹1,50,000": "₹1,25,000 - ₹1,50,000",
            "₹1,50,000 - ₹2,00,000": "₹1,50,000 - ₹2,00,000",
            "More than ₹2,00,000": "More than ₹2,00,000",
          },
          mandatory_expenses_options: {
            "Select an option": "Select an option",
            "Less than 30%": "Less than 30%",
            "30% - 40%": "30% - 40%",
            "40% - 50%": "40% - 50%",
            "50% - 60%": "50% - 60%",
            "More than 60%": "More than 60%",
          },
          discretionary_expenses_options: {
            "Select an option": "Select an option",
            "Less than 10%": "Less than 10%",
            "10% - 20%": "10% - 20%",
            "20% - 30%": "20% - 30%",
            "More than 30%": "More than 30%",
          },
          rent_response_options: {
            "Select an option": "Select an option",
            "No outstanding Loan on my House, I OWN IT": "No outstanding Loan on my House, I OWN IT",
            "I stay with my Parents": "I stay with my Parents",
            "Less than ₹10,000": "Less than ₹10,000",
            "₹10,000 - ₹25,000": "₹10,000 - ₹25,000",
            "₹25,000 - ₹50,000": "₹25,000 - ₹50,000",
            "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
            "More than ₹1,00,000": "More than ₹1,00,000",
          },
          education_expenses_options: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Less than ₹5,000": "Less than ₹5,000",
            "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
            "₹10,000 - ₹15,000": "₹10,000 - ₹15,000",
            "₹15,000 - ₹20,000": "₹15,000 - ₹20,000",
            "₹20,000 - ₹25,000": "₹20,000 - ₹25,000",
            "₹25,000 - ₹50,000": "₹25,000 - ₹50,000",
            "More than ₹50,000": "More than ₹50,000",
          },
          car_loan_emi_options: {
            "Select an option": "Select an option",
            "Not Applicable": "Not Applicable",
            "Less than ₹5,000": "Less than ₹5,000",
            "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
            "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
            "₹20,000 - ₹30,000": "₹20,000 - ₹30,000",
            "₹30,000 - ₹50,000": "₹30,000 - ₹50,000",
            "More than ₹50,000": "More than ₹50,000",
          },
          emergency_fund_options: {
            "Select an option": "Select an option",
            "Yes": "Yes",
            "No": "No",
          },
          emergency_fund_coverage_options: {
            "Select an option": "Select an option",
            "Less than 3 months": "Less than 3 months",
            "3 - 6 months": "3 - 6 months",
            "6 - 12 months": "6 - 12 months",
            "More than 12 months": "More than 12 months",
            "I DON'T have Emergency Fund": "I DON'T have Emergency Fund",
          },
        
        errors: {
            monthly_expensesRequired: "Please select an option for monthly expenses.",
            mandatory_expensesRequired: "Please select an option for mandatory expenses.",
            discretionary_expensesRequired: "Please select an option for discretionary expenses.",
            rent_responseRequired: "Please select an option for rent/home loan.",
            education_expensesRequired: "Please select an option for education expenses.",
            car_loan_emiRequired: "Please select an option for car loan EMI.",
            emergency_fund_labelRequired: "Please select whether you have an emergency fund.",
            emergency_fund_coverage_labelRequired: "Please select the coverage of your emergency fund.",
        }
    },
    
        
    hi: {
      description: "यह खंड आपके मासिक घरेलू खर्चों के बारे में जानकारी एकत्र करता है, जिससे आपके खर्च करने के पैटर्न को समझने में मदद मिलती है।",
      questions: {
          monthly_expenses_label: "3. आपका औसत मासिक घरेलू खर्च कितना है? (कृपया सभी अनिवार्य भुगतान जैसे ईएमआई, कर आदि शामिल करें)",
          mandatory_expenses_label: "4. आपकी मासिक घरेलू आय का कितना प्रतिशत अनिवार्य खर्चों (किराया/होम लोन ईएमआई, बच्चों की शिक्षा, कार लोन ईएमआई आदि) पर खर्च होता है?",
          discretionary_expenses_label: "5. आपकी मासिक घरेलू आय का कितना प्रतिशत विवेकाधीन खर्चों (बाहर खाना, मनोरंजन, छुट्टियां, लक्जरी आइटम) के लिए आवंटित किया जाता है?",
          rent_response_label: "6. आप प्रति माह किराए या होम लोन ईएमआई पर कितना खर्च करते हैं?",
          education_expenses_label: "7. आप अपने बच्चों की शिक्षा पर प्रति माह कितना खर्च करते हैं?",
          car_loan_emi_label: "8. आप कार लोन ईएमआई पर प्रति माह कितना खर्च करते हैं?",
          emergency_fund_label: "9. क्या आपके पास आपातकालीन फंड है?",
          emergency_fund_coverage_label: "10. आपका आपातकालीन फंड कितने महीनों के खर्चों को कवर कर सकता है?",
      },
      monthly_expenses_options: {
          "Select an option": "एक विकल्प चुनें",
          "Less than ₹30,000": "₹30,000 से कम",
          "₹30,000 - ₹50,000": "₹30,000 - ₹50,000",
          "₹50,000 - ₹75,000": "₹50,000 - ₹75,000",
          "₹75,000 - ₹1,00,000": "₹75,000 - ₹1,00,000",
          "₹1,00,000 - ₹1,25,000": "₹1,00,000 - ₹1,25,000",
          "₹1,25,000 - ₹1,50,000": "₹1,25,000 - ₹1,50,000",
          "₹1,50,000 - ₹2,00,000": "₹1,50,000 - ₹2,00,000",
          "More than ₹2,00,000": "₹2,00,000 से अधिक",
      },
      mandatory_expenses_options: {
          "Select an option": "एक विकल्प चुनें",
          "Less than 30%": "30% से कम",
          "30% - 40%": "30% - 40%",
          "40% - 50%": "40% - 50%",
          "50% - 60%": "50% - 60%",
          "More than 60%": "60% से अधिक",
      },
      discretionary_expenses_options: {
          "Select an option": "एक विकल्प चुनें",
          "Less than 10%": "10% से कम",
          "10% - 20%": "10% - 20%",
          "20% - 30%": "20% - 30%",
          "More than 30%": "30% से अधिक",
      },
      rent_response_options: {
          "Select an option": "एक विकल्प चुनें",
          "No outstanding Loan on my House, I OWN IT": "मेरे घर पर कोई बकाया लोन नहीं है, मैं इसका मालिक हूं",
          "I stay with my Parents": "मैं अपने माता-पिता के साथ रहता हूं",
          "Less than ₹10,000": "₹10,000 से कम",
          "₹10,000 - ₹25,000": "₹10,000 - ₹25,000",
          "₹25,000 - ₹50,000": "₹25,000 - ₹50,000",
          "₹50,000 - ₹1,00,000": "₹50,000 - ₹1,00,000",
          "More than ₹1,00,000": "₹1,00,000 से अधिक",
      },
      education_expenses_options: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Less than ₹5,000": "₹5,000 से कम",
          "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
          "₹10,000 - ₹15,000": "₹10,000 - ₹15,000",
          "₹15,000 - ₹20,000": "₹15,000 - ₹20,000",
          "₹20,000 - ₹25,000": "₹20,000 - ₹25,000",
          "₹25,000 - ₹50,000": "₹25,000 - ₹50,000",
          "More than ₹50,000": "₹50,000 से अधिक",
      },
      car_loan_emi_options: {
          "Select an option": "एक विकल्प चुनें",
          "Not Applicable": "लागू नहीं",
          "Less than ₹5,000": "₹5,000 से कम",
          "₹5,000 - ₹10,000": "₹5,000 - ₹10,000",
          "₹10,000 - ₹20,000": "₹10,000 - ₹20,000",
          "₹20,000 - ₹30,000": "₹20,000 - ₹30,000",
          "₹30,000 - ₹50,000": "₹30,000 - ₹50,000",
          "More than ₹50,000": "₹50,000 से अधिक",
      },
      emergency_fund_options: {
          "Select an option": "एक विकल्प चुनें",
          "Yes": "हां",
          "No": "नहीं",
      },
      emergency_fund_coverage_options: {
          "Select an option": "एक विकल्प चुनें",
          "Less than 3 months": "3 महीने से कम",
          "3 - 6 months": "3 - 6 महीने",
          "6 - 12 months": "6 - 12 महीने",
          "More than 12 months": "12 महीने से अधिक",
          "I DON'T have Emergency Fund": "मेरे पास आपातकालीन फंड नहीं है",
      },
      errors: {
          monthly_expensesRequired: "कृपया मासिक खर्चों के लिए एक विकल्प चुनें।",
          mandatory_expensesRequired: "कृपया अनिवार्य खर्चों के लिए एक विकल्प चुनें।",
          discretionary_expensesRequired: "कृपया विवेकाधीन खर्चों के लिए एक विकल्प चुनें।",
          rent_responseRequired: "कृपया किराए/होम लोन के लिए एक विकल्प चुनें।",
          education_expensesRequired: "कृपया शिक्षा खर्चों के लिए एक विकल्प चुनें।",
          car_loan_emiRequired: "कृपया कार लोन ईएमआई के लिए एक विकल्प चुनें।",
          emergency_fund_labelRequired: "कृपया चुनें कि क्या आपके पास आपातकालीन फंड है।",
          emergency_fund_coverage_labelRequired: "कृपया अपने आपातकालीन फंड के कवरेज का चयन करें।",
      }
  },
};

export default HouseholdExpensesTranslations;
