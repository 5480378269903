import React, { useState, useEffect } from 'react';
import '../css/CustomerForm.css';
import RiskProfileTranslations from '../sections/RiskProfileTranslations';

const SelectField = ({ name, label, options, value, handleChange }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
    >
      <option value="" disabled>{options["Select an option"]}</option>
      {Object.entries(options)
        .filter(([key]) => key !== 'Select an option')
        .map(([key, optionLabel]) => (
          <option key={key} value={key}>{optionLabel}</option>
        ))}
    </select>
  </div>
);

const RiskProfile = ({ formData, handleInputChange, language, validateSection }) => {
  const t = RiskProfileTranslations[language] || RiskProfileTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSectionRisk = () => {
    const fieldsToValidate = ['risk_tolerance', 'portfolio_reaction', 'holding_period', 'new_investment_products', 'investment_priority', 'high_risk_allocation', 'liquidity_importance'];
    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.questions[`${field}_label`] || 'This field is required';
      }
      return acc;
    }, {});

    setErrors(newErrors);
   return Object.keys(newErrors).length === 0;
   //return true;
  };

  useEffect(() => {
    validateSection(validateSectionRisk);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-10">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_title}</p>
      <div id="RiskImagePlaceholder">
        <img src="/images/section10_risk.jpg" alt="Risk" style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} />
      </div>
      {['risk_tolerance', 'portfolio_reaction', 'holding_period', 'new_investment_products', 'investment_priority', 'high_risk_allocation', 'liquidity_importance'].map((field) => (
        <div key={field} style={{ marginBottom: '10px' }}>
          <SelectField
            name={field}
            label={t.questions[`${field}_label`]}
            options={t[field]}
            value={formData[field]}
            handleChange={handleInputChange}
          />
          {errors[field] && <p className="error-message">{errors[field]}</p>}
        </div>
      ))}
    </div>
  );
};

export default RiskProfile;
