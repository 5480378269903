import React, { useState, useEffect } from 'react';
import FeedbackSuggestionsTranslations from '../sections/FeedbackSuggestionsTranslations';

const SelectField = ({ name, label, options, value, handleChange }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%' }}
      value={value || ""}
      onChange={handleChange}
    >
      <option value="" disabled>{options?.["Select an option"] || "Select an option"}</option>
      {Object.entries(options ?? {}).filter(([key]) => key !== 'Select an option').map(([key, optionLabel]) => (
        <option key={key} value={key}>{optionLabel}</option>
      ))}
    </select>
  </div>
);


const FeedbackSuggestions = ({ formData, setFormData, language, validateSection }) => {
  const t = FeedbackSuggestionsTranslations[language] || FeedbackSuggestionsTranslations.en;
  const [errors, setErrors] = useState({});

   //  Track Form Data
 useEffect(() => {
  console.log("FormData: ", formData);
}, [formData]);

//  Track Errors
useEffect(() => {
  console.log("Errors: ", errors);
}, [errors]);

  //  Enhanced Handle Change for Select and Checkbox Fields
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const updatedValues = formData[name] || [];
      if (checked) {
        updatedValues.push(value);
      } else {
        const index = updatedValues.indexOf(value);
        if (index > -1) updatedValues.splice(index, 1);
      }
      setFormData({ ...formData, [name]: updatedValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  //  Validation Function
  const validateFeedbackSection = () => {
    const requiredFields = ['q81', 'q82', 'q83', 'q86'];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        newErrors[field] = t.errors[field];
      }
    });

    console.log("New Errors in Feedback Section:", newErrors); // Log newErrors
    setErrors(newErrors);
    console.log("Errors state set to:", newErrors); //Log the error state after it's set.
    return Object.keys(newErrors).length === 0;

   
  };


  useEffect(() => {
    if (validateSection) {
      console.log("Registering validation for FeedbackSuggestions");
      validateSection(validateFeedbackSection);
    }
  }, [validateSection, formData]);

  //  Checkbox Options
  const checkboxOptions = [
    { key: "income", label: t.incomeSection },
    { key: "householdExpensesSection", label: t.householdExpensesSection },
    { key: "savingsInvestmentsSection", label: t.savingsInvestmentsSection },
    { key: "liabilities", label: t.liabilitiesSection },
    { key: "riskAppetite", label: t.riskAppetiteSection },
    { key: "familyInvolvement", label: t.familyInvolvementSection },
    { key: "other", label: t.other }
  ];

  return (
    <div className="form-page" id="section-14">
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{t.sectionDescription}</p>

      {/* Question 81 */}
      <SelectField
        name="q81"
        label={t.q81_label}
        options={t.q81_options} // Use q81_options
        value={formData.q81}
        handleChange={handleInputChange}
      />
      {errors.q81 && <p className="error-message">{errors.q81}</p>}

      {/* Question 82 */}
      <SelectField
        name="q82"
        label={t.q82_label}
        options={t.q82_options} // Use q81_options
        value={formData.q82}
        handleChange={handleInputChange}
      />
      {errors.q82 && <p className="error-message">{errors.q82}</p>}

      {/* Question 83 - Checkbox Group */}
      <div className="question">
        <label style={{ fontWeight: 'bold' }}>{t.q83_label}</label>
        <div className="checkmark-group">
          {checkboxOptions.map((item) => (
            <label key={item.key}>
              <input
                type="checkbox"
                name="q83"
                value={item.key}
                checked={formData.q83?.includes(item.key)}
                onChange={handleInputChange}
              />
              {item.label}
            </label>
          ))}
        </div>
        {errors.q83 && <p className="error-message">{errors.q83}</p>}
      </div>
      <div className="question">
        <label htmlFor="q84" style={{ fontWeight: 'bold' }}>{t.q84_label}</label>
        <input  type="text"   id="q84" name="q84"   maxLength={100}   style={{ width: '100%' }} 
          placeholder="Your answer (optional)"  />
      </div>

      <div className="question">
            <label htmlFor="q85" style={{ fontWeight: 'bold' }}   label={t.q85_label}  />
          <input  type="text" id="q85" name="q85" maxLength={100} style={{ width: '100%' }} 
          placeholder="Your answer (optional)"  />
    </div>

      {/* Question 86 */}
      <SelectField
        name="q86"
        label={t.q86_label}
        options={t.q86_options} // Use q81_options
        value={formData.q86}
        handleChange={handleInputChange}
      />
      {errors.q86 && <p className="error-message">{errors.q86}</p>}
    </div>
  );
};

export default FeedbackSuggestions;
